import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Divider,
  IconButton,
  Link,
  ScreenContainer,
  Touchable,
  YoutubePlayer,
  withTheme,
} from '@draftbit/ui';
import { BlurView } from 'expo-blur';
import { Image, ImageBackground, ScrollView, Text, View } from 'react-native';

const PodcastScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [mobileMenu, setMobileMenu] = React.useState(false);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* Desktop Menu Special 2 */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
                flexDirection: {
                  minWidth: Breakpoints.Laptop,
                  value: 'column',
                },
                height: { minWidth: Breakpoints.Laptop, value: 119.6 },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'center',
                },
                paddingBottom: { minWidth: Breakpoints.Laptop, value: 15 },
                paddingTop: { minWidth: Breakpoints.Laptop, value: 15 },
                width: {
                  minWidth: Breakpoints.Laptop,
                  value: dimensions.width,
                },
              },
              dimensions.width
            )}
          >
            {/* Header Container */}
            <>
              {Constants['user_ID'] || Constants['vendor_ID'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '55.9%' },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Secondary'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '27.06%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorSignupFormScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserSignupFormScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Sign Up'}
                    />
                    {/* Button 3 */}
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorLoginScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserLoginScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            backgroundColor: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Secondary'],
                            },
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            marginLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 10,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Login'}
                    />
                  </View>
                </View>
              )}
            </>
            {/* Header Container Signed In */}
            <>
              {!(Constants['user_ID'] || Constants['vendor_ID']) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '69.43%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 2 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Secondary'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('MarketplaceScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Marketplace'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 5 */}
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorDashboardScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          if (Constants['Admin']) {
                            navigation.navigate('AdminDashboardScreen');
                          }
                          if (Constants['Admin']) {
                            return;
                          }
                          navigation.navigate('UserDashboardScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'My Account'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '13.53%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc'] || Constants['Admin']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          navigation.navigate('UserHomeScreen');
                          setGlobalVariableValue({
                            key: 'vendor_ID',
                            value: '',
                          });
                          setGlobalVariableValue({
                            key: 'user_ID',
                            value: '',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Logout'}
                    />
                  </View>
                </View>
              )}
            </>
          </View>
        )}
      </>
      {/* Mobile Menu Opener */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Background'],
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-between',
                marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
                paddingLeft: 16,
                paddingRight: 16,
              },
              dimensions.width
            )}
          >
            <View>
              <Image
                resizeMode={'contain'}
                source={Images.BevyLogo}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ImageStyles(theme)['Image'],
                  dimensions.width
                )}
              />
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', zIndex: 1 },
                dimensions.width
              )}
            >
              {/* Icon Button 2 */}
              <>
                {mobileMenu ? null : (
                  <IconButton
                    icon={'Feather/menu'}
                    onPress={() => {
                      try {
                        setMobileMenu(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={38}
                    style={StyleSheet.applyWidth(
                      { marginRight: 5 },
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* Icon Button 3 */}
              <>
                {!mobileMenu ? null : (
                  <IconButton
                    icon={'AntDesign/closecircleo'}
                    onPress={() => {
                      try {
                        setMobileMenu(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={38}
                    style={StyleSheet.applyWidth(
                      { marginRight: 5 },
                      dimensions.width
                    )}
                  />
                )}
              </>
            </View>
          </View>
        )}
      </>
      {/* Mobile Menu Special 3 */}
      <>
        {!mobileMenu ? null : (
          <BlurView
            intensity={80}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.BlurViewStyles(theme)['Blur View'],
                { height: '100%', width: '100%', zIndex: 1 }
              ),
              dimensions.width
            )}
            tint={'light'}
          >
            <>
              {dimensions.width >= Breakpoints.Laptop ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Surface'],
                      borderBottomLeftRadius: 12,
                      borderBottomRightRadius: 12,
                      height: 500,
                      opacity: 1,
                      paddingTop: 30,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Divider'],
                        flexDirection: 'row',
                        height: 110,
                        justifyContent: 'space-evenly',
                        paddingBottom: [
                          { minWidth: Breakpoints.Tablet, value: 15 },
                          { minWidth: Breakpoints.Mobile, value: 20 },
                        ],
                        paddingLeft: 40,
                        paddingRight: 40,
                        paddingTop: [
                          { minWidth: Breakpoints.Tablet, value: 15 },
                          { minWidth: Breakpoints.Mobile, value: 20 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { justifyContent: 'center', width: '70%' },
                        dimensions.width
                      )}
                    >
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                fontFamily: 'Lato_400Regular',
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                  { minWidth: Breakpoints.Tablet, value: 20 },
                                ],
                                marginBottom: 5,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Welcome,'}
                        </Text>
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                fontFamily: 'Lato_400Regular',
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                  { minWidth: Breakpoints.Tablet, value: 20 },
                                ],
                                marginBottom: 5,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {Constants['fName']}
                        </Text>
                      </View>
                      {/* Special Buttons */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: [
                              { minWidth: Breakpoints.Laptop, value: 'row' },
                              { minWidth: Breakpoints.Mobile, value: 'row' },
                            ],
                            justifyContent: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'flex-end',
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'space-evenly',
                              },
                            ],
                            marginTop: 5,
                            minHeight: {
                              minWidth: Breakpoints.Laptop,
                              value: 45,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '27.06%',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <>
                          {!(
                            Constants['user_ID'] || Constants['vendor_ID']
                          ) ? null : (
                            <Button
                              onPress={() => {
                                try {
                                  if (Constants['VendorAcc']) {
                                    if (Constants['vendor_ID']) {
                                      navigation.navigate(
                                        'VendorDashboardScreen'
                                      );
                                    } else {
                                      navigation.navigate(
                                        'VendorSignupFormScreen'
                                      );
                                    }
                                  } else {
                                    if (Constants['user_ID']) {
                                      navigation.navigate(
                                        'UserDashboardScreen'
                                      );
                                    } else {
                                      navigation.navigate(
                                        'UserSignupFormScreen'
                                      );
                                    }
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)['Button'],
                                  {
                                    borderRadius: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 9,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 9,
                                      },
                                    ],
                                    width: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '45%',
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: '45%',
                                      },
                                    ],
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Sign Out'}
                            />
                          )}
                        </>
                        {/* Button 2 */}
                        <>
                          {Constants['user_ID'] ||
                          Constants['vendor_ID'] ? null : (
                            <Button
                              onPress={() => {
                                try {
                                  if (Constants['VendorAcc']) {
                                    if (Constants['vendor_ID']) {
                                      navigation.navigate(
                                        'VendorDashboardScreen'
                                      );
                                    } else {
                                      navigation.navigate(
                                        'VendorSignupFormScreen'
                                      );
                                    }
                                  } else {
                                    if (Constants['user_ID']) {
                                      navigation.navigate(
                                        'UserDashboardScreen'
                                      );
                                    } else {
                                      navigation.navigate(
                                        'UserSignupFormScreen'
                                      );
                                    }
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)['Button'],
                                  {
                                    borderRadius: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 9,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 9,
                                      },
                                    ],
                                    width: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '45%',
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: '45%',
                                      },
                                    ],
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Sign Up'}
                            />
                          )}
                        </>
                        {/* Button 3 */}
                        <>
                          {Constants['user_ID'] || undefined ? null : (
                            <Button
                              onPress={() => {
                                try {
                                  if (Constants['VendorAcc']) {
                                    if (Constants['vendor_ID']) {
                                      navigation.navigate(
                                        'VendorDashboardScreen'
                                      );
                                    } else {
                                      navigation.navigate('VendorLoginScreen');
                                    }
                                  } else {
                                    if (Constants['user_ID']) {
                                      navigation.navigate(
                                        'UserDashboardScreen'
                                      );
                                    } else {
                                      navigation.navigate('UserLoginScreen');
                                    }
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)['Button'],
                                  {
                                    backgroundColor: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: theme.colors['Secondary'],
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: theme.colors['Secondary'],
                                      },
                                    ],
                                    borderRadius: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 9,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 9,
                                      },
                                    ],
                                    marginLeft: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 10,
                                    },
                                    width: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '45%',
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: '45%',
                                      },
                                    ],
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Login'}
                            />
                          )}
                        </>
                      </View>
                    </View>
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      <Image
                        resizeMode={'contain'}
                        source={Images.BevyBee}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'],
                            { borderRadius: 100 }
                          ),
                          dimensions.width
                        )}
                      />
                    </View>
                  </View>
                  {/* Link Buttons 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: [
                          { minWidth: Breakpoints.BigScreen, value: 'center' },
                          { minWidth: Breakpoints.Laptop, value: 'center' },
                        ],
                        flexDirection: [
                          { minWidth: Breakpoints.BigScreen, value: 'row' },
                          { minWidth: Breakpoints.Laptop, value: 'row' },
                        ],
                        height: [
                          { minWidth: Breakpoints.BigScreen, value: 100 },
                          { minWidth: Breakpoints.Mobile, value: 300 },
                        ],
                        justifyContent: [
                          {
                            minWidth: Breakpoints.BigScreen,
                            value: 'space-evenly',
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'space-evenly',
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'space-evenly',
                          },
                        ],
                        marginTop: 30,
                        paddingLeft: 40,
                        paddingRight: 40,
                        width: [
                          { minWidth: Breakpoints.BigScreen, value: '70%' },
                          { minWidth: Breakpoints.Laptop, value: '70%' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: [
                            {
                              minWidth: Breakpoints.BigScreen,
                              value: 'center',
                            },
                            { minWidth: Breakpoints.Laptop, value: 'center' },
                          ],
                          borderBottomWidth: 0.5,
                          borderColor: [
                            {
                              minWidth: Breakpoints.BigScreen,
                              value: theme.colors['Primary'],
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: theme.colors['Light'],
                            },
                          ],
                          height: [
                            { minWidth: Breakpoints.BigScreen, value: '100%' },
                            { minWidth: Breakpoints.Laptop, value: 80 },
                            { minWidth: Breakpoints.Mobile, value: 35 },
                          ],
                          justifyContent: [
                            {
                              minWidth: Breakpoints.BigScreen,
                              value: 'center',
                            },
                            { minWidth: Breakpoints.Laptop, value: 'center' },
                          ],
                          width: [
                            { minWidth: Breakpoints.BigScreen, value: 100 },
                            { minWidth: Breakpoints.Laptop, value: 100 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {/* Link 2 */}
                      <Link
                        accessible={true}
                        allowFontScaling={true}
                        onPress={() => {
                          try {
                            if (Constants['VendorAcc']) {
                              navigation.navigate('VendorHomeScreen');
                            }
                            if (Constants['VendorAcc']) {
                              return;
                            }
                            navigation.navigate('UserHomeScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.LinkStyles(theme)['Link'],
                            {
                              color: {
                                minWidth: Breakpoints.BigScreen,
                                value: theme.colors['Primary'],
                              },
                              fontFamily: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'Oswald_400Regular',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'Oswald_400Regular',
                                },
                              ],
                              fontSize: [
                                { minWidth: Breakpoints.BigScreen, value: 16 },
                                { minWidth: Breakpoints.Mobile, value: 16 },
                              ],
                              textTransform: 'uppercase',
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Home'}
                      />
                    </View>
                    {/* View 3 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: [
                            {
                              minWidth: Breakpoints.BigScreen,
                              value: 'center',
                            },
                            { minWidth: Breakpoints.Laptop, value: 'center' },
                          ],
                          borderBottomWidth: 0.5,
                          borderColor: theme.colors['Light'],
                          height: [
                            { minWidth: Breakpoints.BigScreen, value: '100%' },
                            { minWidth: Breakpoints.Laptop, value: 80 },
                            { minWidth: Breakpoints.Mobile, value: 35 },
                          ],
                          justifyContent: [
                            {
                              minWidth: Breakpoints.BigScreen,
                              value: 'center',
                            },
                            { minWidth: Breakpoints.Laptop, value: 'center' },
                          ],
                          width: [
                            { minWidth: Breakpoints.BigScreen, value: 100 },
                            { minWidth: Breakpoints.Laptop, value: 100 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {/* Link 3 */}
                      <Link
                        accessible={true}
                        allowFontScaling={true}
                        onPress={() => {
                          try {
                            navigation.navigate('PodcastScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.LinkStyles(theme)['Link'],
                            {
                              color: {
                                minWidth: Breakpoints.BigScreen,
                                value: theme.colors['Primary'],
                              },
                              fontFamily: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'Oswald_400Regular',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'Oswald_400Regular',
                                },
                              ],
                              fontSize: [
                                { minWidth: Breakpoints.BigScreen, value: 16 },
                                { minWidth: Breakpoints.Mobile, value: 16 },
                              ],
                              textTransform: 'uppercase',
                            }
                          ),
                          dimensions.width
                        )}
                        title={'podcast'}
                      />
                    </View>
                    {/* View 6 */}
                    <>
                      {!(
                        Constants['user_ID'] || Constants['vendor_ID']
                      ) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              borderBottomWidth: 0.5,
                              borderColor: theme.colors['Light'],
                              height: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: '100%',
                                },
                                { minWidth: Breakpoints.Laptop, value: 80 },
                                { minWidth: Breakpoints.Mobile, value: 35 },
                              ],
                              justifyContent: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              width: [
                                { minWidth: Breakpoints.BigScreen, value: 100 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          {/* Link 3 */}
                          <Link
                            accessible={true}
                            allowFontScaling={true}
                            onPress={() => {
                              try {
                                navigation.navigate('MarketplaceScreen');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)['Link'],
                                {
                                  color: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: theme.colors['Primary'],
                                  },
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'Oswald_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'Oswald_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'Oswald_400Regular',
                                    },
                                  ],
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 16,
                                    },
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                  ],
                                  textTransform: 'uppercase',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Marketplace'}
                          />
                        </View>
                      )}
                    </>
                    {/* View 5 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: [
                            {
                              minWidth: Breakpoints.BigScreen,
                              value: 'center',
                            },
                            { minWidth: Breakpoints.Laptop, value: 'center' },
                          ],
                          borderBottomWidth: 0.5,
                          borderColor: theme.colors['Light'],
                          height: [
                            { minWidth: Breakpoints.BigScreen, value: '100%' },
                            { minWidth: Breakpoints.Laptop, value: 80 },
                            { minWidth: Breakpoints.Mobile, value: 35 },
                          ],
                          justifyContent: [
                            {
                              minWidth: Breakpoints.BigScreen,
                              value: 'center',
                            },
                            { minWidth: Breakpoints.Laptop, value: 'center' },
                          ],
                          width: [
                            { minWidth: Breakpoints.BigScreen, value: 100 },
                            { minWidth: Breakpoints.Laptop, value: 100 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {/* Link 4 */}
                      <Link
                        accessible={true}
                        allowFontScaling={true}
                        onPress={() => {
                          try {
                            navigation.navigate('ContactUsScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.LinkStyles(theme)['Link'],
                            {
                              color: {
                                minWidth: Breakpoints.BigScreen,
                                value: theme.colors['Primary'],
                              },
                              fontFamily: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'Oswald_400Regular',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'Oswald_400Regular',
                                },
                              ],
                              fontSize: [
                                { minWidth: Breakpoints.BigScreen, value: 16 },
                                { minWidth: Breakpoints.Mobile, value: 16 },
                              ],
                              textTransform: 'uppercase',
                            }
                          ),
                          dimensions.width
                        )}
                        title={'contact us'}
                      />
                    </View>
                    {/* View 4 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: [
                            {
                              minWidth: Breakpoints.BigScreen,
                              value: 'center',
                            },
                            { minWidth: Breakpoints.Laptop, value: 'center' },
                          ],
                          borderBottomWidth: 0.5,
                          borderColor: theme.colors['Light'],
                          height: [
                            { minWidth: Breakpoints.BigScreen, value: '100%' },
                            { minWidth: Breakpoints.Laptop, value: 80 },
                            { minWidth: Breakpoints.Mobile, value: 35 },
                          ],
                          justifyContent: [
                            {
                              minWidth: Breakpoints.BigScreen,
                              value: 'center',
                            },
                            { minWidth: Breakpoints.Laptop, value: 'center' },
                          ],
                          width: [
                            { minWidth: Breakpoints.BigScreen, value: 100 },
                            { minWidth: Breakpoints.Laptop, value: 100 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {/* Link 4 */}
                      <>
                        {!(
                          Constants['user_ID'] || Constants['vendor_ID']
                        ) ? null : (
                          <Link
                            accessible={true}
                            allowFontScaling={true}
                            onPress={() => {
                              try {
                                if (Constants['VendorAcc']) {
                                  navigation.navigate('VendorDashboardScreen');
                                }
                                if (Constants['VendorAcc']) {
                                  return;
                                }
                                if (Constants['Admin']) {
                                  navigation.navigate('AdminDashboardScreen');
                                }
                                if (Constants['Admin']) {
                                  return;
                                }
                                navigation.navigate('UserDashboardScreen');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)['Link'],
                                {
                                  color: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: theme.colors['Primary'],
                                  },
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'Oswald_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'Oswald_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'Oswald_400Regular',
                                    },
                                  ],
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 16,
                                    },
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                  ],
                                  textTransform: 'uppercase',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'My Account'}
                          />
                        )}
                      </>
                    </View>
                  </View>
                </View>
              )}
            </>
          </BlurView>
        )}
      </>
      {/* Image Background 2 */}
      <ImageBackground
        resizeMode={'cover'}
        source={Images.AdobeStock438035062}
        style={StyleSheet.applyWidth(
          GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
          dimensions.width
        )}
      >
        <ScrollView
          bounces={true}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={true}
          style={StyleSheet.applyWidth(
            { height: '100%', width: '100%' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: [
                  { minWidth: Breakpoints.Mobile, value: 'stretch' },
                  { minWidth: Breakpoints.BigScreen, value: 'center' },
                ],
                justifyContent: 'center',
                minHeight: 120,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center' },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Strong Inverse'],
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Desktop,
                        value: 'Oswald_400Regular',
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: 'Oswald_600SemiBold',
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Desktop, value: 32 },
                      { minWidth: Breakpoints.Laptop, value: 24 },
                      { minWidth: Breakpoints.Mobile, value: 24 },
                    ],
                    marginTop: [
                      { minWidth: Breakpoints.Desktop, value: 40 },
                      { minWidth: Breakpoints.BigScreen, value: 32 },
                      { minWidth: Breakpoints.Mobile, value: 30 },
                    ],
                    textTransform: 'uppercase',
                  }),
                  dimensions.width
                )}
              >
                {'Podcasts'}
              </Text>
              <Divider
                color={theme.colors['Medium']}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.DividerStyles(theme)['Divider'],
                    { height: 3, width: '30%' }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Strong Inverse'],
                    fontFamily: 'Lato_400Regular',
                    fontSize: 24,
                    marginTop: 10,
                    textTransform: 'uppercase',
                  }),
                  dimensions.width
                )}
              >
                {'The BEVY SPOT'}
              </Text>
              {/* Text 2 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Strong Inverse'],
                    fontFamily: 'Oswald_400Regular',
                    fontSize: 18,
                    marginTop: 5,
                    textTransform: 'uppercase',
                  }),
                  dimensions.width
                )}
              >
                {'YOUR ULTIMATE GUIDE TO THE SOCIAL SCENE'}
              </Text>
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth({ marginTop: 30 }, dimensions.width)}
            >
              {/* View 3 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Tablet,
                      value: 'row',
                    },
                    justifyContent: {
                      minWidth: Breakpoints.Tablet,
                      value: 'space-evenly',
                    },
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <YoutubePlayer
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.YoutubePlayerStyles(theme)[
                          'Youtube Player'
                        ],
                        { width: { minWidth: Breakpoints.Desktop, value: 350 } }
                      ),
                      dimensions.width
                    )}
                    videoId={'wVElPSEHbgU'}
                  />
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <YoutubePlayer
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.YoutubePlayerStyles(theme)[
                          'Youtube Player'
                        ],
                        { width: { minWidth: Breakpoints.Desktop, value: 350 } }
                      ),
                      dimensions.width
                    )}
                    videoId={'JuHYqZjmkc0'}
                  />
                </View>
                {/* View 3 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <YoutubePlayer
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.YoutubePlayerStyles(theme)[
                          'Youtube Player'
                        ],
                        { width: { minWidth: Breakpoints.Desktop, value: 350 } }
                      ),
                      dimensions.width
                    )}
                    videoId={'_yC2mMSgbFU'}
                  />
                </View>
              </View>
              {/* View 4 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Tablet,
                      value: 'row',
                    },
                    justifyContent: {
                      minWidth: Breakpoints.Tablet,
                      value: 'space-evenly',
                    },
                    marginTop: { minWidth: Breakpoints.Tablet, value: 20 },
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <YoutubePlayer
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.YoutubePlayerStyles(theme)[
                          'Youtube Player'
                        ],
                        { width: { minWidth: Breakpoints.Desktop, value: 350 } }
                      ),
                      dimensions.width
                    )}
                    videoId={'oJPl2JYrUMU'}
                  />
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <YoutubePlayer
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.YoutubePlayerStyles(theme)[
                          'Youtube Player'
                        ],
                        { width: { minWidth: Breakpoints.Desktop, value: 350 } }
                      ),
                      dimensions.width
                    )}
                    videoId={'1IhBdgvJLgs'}
                  />
                </View>
                {/* View 3 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <YoutubePlayer
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.YoutubePlayerStyles(theme)[
                          'Youtube Player'
                        ],
                        { width: { minWidth: Breakpoints.Desktop, value: 350 } }
                      ),
                      dimensions.width
                    )}
                    videoId={'ucKXL1WfoAY'}
                  />
                </View>
              </View>
              {/* View 5 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Tablet,
                      value: 'row',
                    },
                    justifyContent: {
                      minWidth: Breakpoints.Tablet,
                      value: 'space-evenly',
                    },
                    marginTop: { minWidth: Breakpoints.Tablet, value: 20 },
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <YoutubePlayer
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.YoutubePlayerStyles(theme)[
                          'Youtube Player'
                        ],
                        { width: { minWidth: Breakpoints.Desktop, value: 350 } }
                      ),
                      dimensions.width
                    )}
                    videoId={'scsKVE2tzqE'}
                  />
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <YoutubePlayer
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.YoutubePlayerStyles(theme)[
                          'Youtube Player'
                        ],
                        { width: { minWidth: Breakpoints.Desktop, value: 350 } }
                      ),
                      dimensions.width
                    )}
                    videoId={'wEaZQSFryzs'}
                  />
                </View>
                {/* View 3 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <YoutubePlayer
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.YoutubePlayerStyles(theme)[
                          'Youtube Player'
                        ],
                        { width: { minWidth: Breakpoints.Desktop, value: 350 } }
                      ),
                      dimensions.width
                    )}
                    videoId={'CbPcpkxCeFs'}
                  />
                </View>
              </View>
              {/* View 6 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Tablet,
                      value: 'row',
                    },
                    justifyContent: {
                      minWidth: Breakpoints.Tablet,
                      value: 'space-evenly',
                    },
                    marginTop: { minWidth: Breakpoints.Tablet, value: 20 },
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <YoutubePlayer
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.YoutubePlayerStyles(theme)[
                          'Youtube Player'
                        ],
                        { width: { minWidth: Breakpoints.Desktop, value: 350 } }
                      ),
                      dimensions.width
                    )}
                    videoId={'MFWbU4iepBE'}
                  />
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <YoutubePlayer
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.YoutubePlayerStyles(theme)[
                          'Youtube Player'
                        ],
                        { width: { minWidth: Breakpoints.Desktop, value: 350 } }
                      ),
                      dimensions.width
                    )}
                    videoId={'OImRLcO7M6U'}
                  />
                </View>
                {/* View 3 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <YoutubePlayer
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.YoutubePlayerStyles(theme)[
                          'Youtube Player'
                        ],
                        { width: { minWidth: Breakpoints.Desktop, value: 350 } }
                      ),
                      dimensions.width
                    )}
                    videoId={'Xj5oCkvJ5a0'}
                  />
                </View>
              </View>
              {/* View 7 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Tablet,
                      value: 'row',
                    },
                    justifyContent: {
                      minWidth: Breakpoints.Tablet,
                      value: 'space-evenly',
                    },
                    marginTop: { minWidth: Breakpoints.Tablet, value: 20 },
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <YoutubePlayer
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.YoutubePlayerStyles(theme)[
                          'Youtube Player'
                        ],
                        { width: { minWidth: Breakpoints.Desktop, value: 350 } }
                      ),
                      dimensions.width
                    )}
                    videoId={'UrnQQx4DJWg'}
                  />
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(PodcastScreen);
