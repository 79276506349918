import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const activeOfferCountGET = (Constants, { vendor_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/offers/active-count/${
      typeof vendor_id === 'string'
        ? vendor_id
        : JSON.stringify(vendor_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useActiveOfferCountGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['offer', args],
    () => activeOfferCountGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['offers']),
    }
  );
};

export const FetchActiveOfferCountGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  vendor_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useActiveOfferCountGET(
    { vendor_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchActiveOfferCount: refetch });
};

export const addSocialBeePOST = (
  Constants,
  { Fname, Lname, email, file, password_confirm, password_input, phone_number },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/user`, {
    body: JSON.stringify({
      Fname: Fname,
      Lname: Lname,
      email: email,
      phone_number: phone_number,
      password_input: password_input,
      password_confirm: password_confirm,
      file: file,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: 'null',
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useAddSocialBeePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => addSocialBeePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries('users');
      },
    }
  );
};

export const FetchAddSocialBeePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Fname,
  Lname,
  email,
  file,
  password_confirm,
  password_input,
  phone_number,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAddSocialBeePOST(
    {
      Fname,
      Lname,
      email,
      file,
      password_confirm,
      password_input,
      phone_number,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAddSocialBee: refetch });
};

export const addVendorPOST = (
  Constants,
  {
    Fname,
    Lname,
    business_name,
    email,
    logo,
    password_confirm,
    password_input,
    phone_number,
  },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/vendors`, {
    body: JSON.stringify({
      Fname: Fname,
      Lname: Lname,
      business_name: business_name,
      email: email,
      phone_number: phone_number,
      password_input: password_input,
      password_confirm: password_confirm,
      logo: logo,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: 'null',
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useAddVendorPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => addVendorPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('vendor', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('vendor');
        queryClient.invalidateQueries('vendors');
      },
    }
  );
};

export const FetchAddVendorPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Fname,
  Lname,
  business_name,
  email,
  logo,
  password_confirm,
  password_input,
  phone_number,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAddVendorPOST(
    {
      Fname,
      Lname,
      business_name,
      email,
      logo,
      password_confirm,
      password_input,
      phone_number,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAddVendor: refetch });
};

export const createInfluencerPOST = (
  Constants,
  { Fname, Lname, email, file, password, phone_number },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/influencer`, {
    body: JSON.stringify({
      Fname: Fname,
      Lname: Lname,
      email: email,
      phone_number: phone_number,
      password: password,
      file: file,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: 'null',
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateInfluencerPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createInfluencerPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('influencer', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('influencer');
        queryClient.invalidateQueries('influencers');
      },
    }
  );
};

export const FetchCreateInfluencerPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Fname,
  Lname,
  email,
  file,
  password,
  phone_number,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateInfluencerPOST(
    { Fname, Lname, email, file, password, phone_number },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateInfluencer: refetch });
};

export const createOfferPOST = (
  Constants,
  {
    Offer_Image,
    Price,
    Usage_cycles,
    offer_amount_percent,
    offer_amount_rate,
    offer_description,
    offer_name,
    usage_limit_per_cycle,
    vendor_id,
    vendor_locations_id,
  },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/offers`, {
    body: JSON.stringify({
      offer_name: offer_name,
      vendor_id: vendor_id,
      offer_description: offer_description,
      offer_amount_rate: offer_amount_rate,
      offer_amount_percent: offer_amount_percent,
      Usage_cycles: Usage_cycles,
      usage_limit_per_cycle: usage_limit_per_cycle,
      vendor_locations_id: vendor_locations_id,
      Price: Price,
      Offer_Image: Offer_Image,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: 'null',
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateOfferPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createOfferPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('offer', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('offer');
        queryClient.invalidateQueries('offers');
      },
    }
  );
};

export const FetchCreateOfferPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Offer_Image,
  Price,
  Usage_cycles,
  offer_amount_percent,
  offer_amount_rate,
  offer_description,
  offer_name,
  usage_limit_per_cycle,
  vendor_id,
  vendor_locations_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateOfferPOST(
    {
      Offer_Image,
      Price,
      Usage_cycles,
      offer_amount_percent,
      offer_amount_rate,
      offer_description,
      offer_name,
      usage_limit_per_cycle,
      vendor_id,
      vendor_locations_id,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateOffer: refetch });
};

export const createALocationPOST = (
  Constants,
  { Address_1, city, location_name, state, suite_or_unit, vendor_id, zip_code },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/vendor_locations`, {
    body: JSON.stringify({
      location_name: location_name,
      Address_1: Address_1,
      suite_or_unit: suite_or_unit,
      city: city,
      state: state,
      zip_code: zip_code,
      vendor_id: vendor_id,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: 'null',
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateALocationPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createALocationPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('location', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('location');
        queryClient.invalidateQueries('locations');
      },
    }
  );
};

export const FetchCreateALocationPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  Address_1,
  city,
  location_name,
  state,
  suite_or_unit,
  vendor_id,
  zip_code,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateALocationPOST(
    {
      Address_1,
      city,
      location_name,
      state,
      suite_or_unit,
      vendor_id,
      zip_code,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateALocation: refetch });
};

export const deleteOfferDELETE = (Constants, { offers_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/offers/${
      typeof offers_id === 'string'
        ? offers_id
        : JSON.stringify(offers_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteOfferDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deleteOfferDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('offer', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('offer');
        queryClient.invalidateQueries('offers');
      },
    }
  );
};

export const deleteVendorDELETE = (Constants, { vendors_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/vendors/${
      typeof vendors_id === 'string'
        ? vendors_id
        : JSON.stringify(vendors_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteVendorDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteVendorDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('vendor', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('vendor');
        queryClient.invalidateQueries('vendors');
      },
    }
  );
};

export const editLocationPATCH = (
  Constants,
  {
    Address_1,
    city,
    location_name,
    state,
    suite_or_unit,
    vendor_id,
    vendor_locations_id,
    zip_code,
  },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/vendor_locations/${
      typeof vendor_locations_id === 'string'
        ? vendor_locations_id
        : JSON.stringify(vendor_locations_id ?? '')
    }`,
    {
      body: JSON.stringify({
        vendor_locations_id: vendor_locations_id,
        location_name: location_name,
        Address_1: Address_1,
        suite_or_unit: suite_or_unit,
        city: city,
        state: state,
        zip_code: zip_code,
        vendor_id: vendor_id,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    }
  ).then(res => handleResponse(res, handlers));

export const useEditLocationPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => editLocationPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('location', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('location');
        queryClient.invalidateQueries('locations');
      },
    }
  );
};

export const editVendorInfoPATCH = (
  Constants,
  {
    Fname,
    Lname,
    Membership_plan,
    business_name,
    email,
    email_verified,
    phone_number,
    vendors_id,
  },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/vendors/${
      typeof vendors_id === 'string'
        ? vendors_id
        : JSON.stringify(vendors_id ?? '')
    }`,
    {
      body: JSON.stringify({
        vendors_id: vendors_id,
        Fname: Fname,
        Lname: Lname,
        business_name: business_name,
        email: email,
        phone_number: phone_number,
        email_verified: email_verified,
        Membership_plan: Membership_plan,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    }
  ).then(res => handleResponse(res, handlers));

export const useEditVendorInfoPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      editVendorInfoPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('vendor', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('vendor');
        queryClient.invalidateQueries('vendors');
      },
    }
  );
};

export const getAllInfluencersGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/influencer/get-all`, {
    headers: {
      Accept: 'application/json',
      Authorization: 'null',
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetAllInfluencersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['influencers', args],
    () => getAllInfluencersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAllInfluencersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAllInfluencersGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAllInfluencers: refetch });
};

export const getAllUsersGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/user`, {
    headers: {
      Accept: 'application/json',
      Authorization: 'null',
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetAllUsersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['users', args],
    () => getAllUsersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAllUsersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAllUsersGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAllUsers: refetch });
};

export const getAllVendorsGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/vendors`, {
    headers: {
      Accept: 'application/json',
      Authorization: 'null',
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetAllVendorsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['vendors', args],
    () => getAllVendorsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAllVendorsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAllVendorsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAllVendors: refetch });
};

export const getInfluencerGET = (Constants, { influencer_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/influencer/get/${
      typeof influencer_id === 'string'
        ? influencer_id
        : JSON.stringify(influencer_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetInfluencerGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['influencer', args],
    () => getInfluencerGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['influencers']),
    }
  );
};

export const FetchGetInfluencerGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  influencer_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetInfluencerGET(
    { influencer_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetInfluencer: refetch });
};

export const getInfluencerCountGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/influencer/count`, {
    headers: {
      Accept: 'application/json',
      Authorization: 'null',
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetInfluencerCountGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['influencer', args],
    () => getInfluencerCountGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['influencers']),
    }
  );
};

export const FetchGetInfluencerCountGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetInfluencerCountGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetInfluencerCount: refetch });
};

export const getLocationGET = (Constants, { vendor_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/vendor_locations/${
      typeof vendor_id === 'string'
        ? vendor_id
        : JSON.stringify(vendor_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetLocationGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['location', args],
    () => getLocationGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['locations']),
    }
  );
};

export const FetchGetLocationGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  vendor_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetLocationGET(
    { vendor_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetLocation: refetch });
};

export const getLocationsListGET = (Constants, { vendor_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/vendor_locations/list/${
      typeof vendor_id === 'string'
        ? vendor_id
        : JSON.stringify(vendor_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetLocationsListGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['locations', args],
    () => getLocationsListGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetLocationsListGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  vendor_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetLocationsListGET(
    { vendor_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetLocationsList: refetch });
};

export const getSpecificOfferGET = (Constants, { offer_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/offers/specific_offer/${
      typeof offer_id === 'string' ? offer_id : JSON.stringify(offer_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetSpecificOfferGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['offer', args],
    () => getSpecificOfferGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['offers']),
    }
  );
};

export const FetchGetSpecificOfferGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  offer_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetSpecificOfferGET(
    { offer_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetSpecificOffer: refetch });
};

export const getUserGET = (Constants, { user_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/user/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetUserGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(['user', args], () => getUserGET(Constants, args, handlers), {
    refetchInterval,
    onSuccess: () => queryClient.invalidateQueries(['users']),
  });
};

export const FetchGetUserGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUserGET(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUser: refetch });
};

export const getVendorGET = (Constants, { vendors_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/vendors/${
      typeof vendors_id === 'string'
        ? vendors_id
        : JSON.stringify(vendors_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetVendorGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['vendor', args],
    () => getVendorGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['vendors']),
    }
  );
};

export const FetchGetVendorGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  vendors_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetVendorGET(
    { vendors_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetVendor: refetch });
};

export const getVendorOffersGET = (Constants, { vendor_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/offers/vendor/${
      typeof vendor_id === 'string'
        ? vendor_id
        : JSON.stringify(vendor_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetVendorOffersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['offers', args],
    () => getVendorOffersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetVendorOffersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  vendor_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetVendorOffersGET(
    { vendor_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetVendorOffers: refetch });
};

export const getAllOffersGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/offers`, {
    headers: {
      Accept: 'application/json',
      Authorization: 'null',
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetAllOffersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['offers', args],
    () => getAllOffersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAllOffersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAllOffersGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAllOffers: refetch });
};

export const locationCountGET = (Constants, { vendor_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/vendor_locations/count/${
      typeof vendor_id === 'string'
        ? vendor_id
        : JSON.stringify(vendor_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useLocationCountGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['location', args],
    () => locationCountGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['locations']),
    }
  );
};

export const FetchLocationCountGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  vendor_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useLocationCountGET(
    { vendor_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLocationCount: refetch });
};

export const publishOfferPATCH = (
  Constants,
  { offer_id, offers_id, vendor_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/offers/publish/${
      typeof offer_id === 'string' ? offer_id : JSON.stringify(offer_id ?? '')
    }`,
    {
      body: JSON.stringify({ offers_id: offers_id, vendor_id: vendor_id }),
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    }
  ).then(res => handleResponse(res, handlers));

export const usePublishOfferPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => publishOfferPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('offer', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('offer');
        queryClient.invalidateQueries('offers');
      },
    }
  );
};

export const unpublishOfferPATCH = (
  Constants,
  { offer_id, offers_id, vendor_id },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/offers/unpublish/${
      typeof offer_id === 'string' ? offer_id : JSON.stringify(offer_id ?? '')
    }`,
    {
      body: JSON.stringify({ offers_id: offers_id, vendor_id: vendor_id }),
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    }
  ).then(res => handleResponse(res, handlers));

export const useUnpublishOfferPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      unpublishOfferPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('offer', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('offer');
        queryClient.invalidateQueries('offers');
      },
    }
  );
};

export const userCountGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/user/data/count`, {
    headers: {
      Accept: 'application/json',
      Authorization: 'null',
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useUserCountGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['user', args],
    () => userCountGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['users']),
    }
  );
};

export const FetchUserCountGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useUserCountGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUserCount: refetch });
};

export const userLoginPOST = (Constants, { email, password }, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/user/auth/login`, {
    body: JSON.stringify({ email: email, password: password }),
    headers: {
      Accept: 'application/json',
      Authorization: 'null',
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useUserLoginPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['user', args],
    () => userLoginPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['users']),
    }
  );
};

export const FetchUserLoginPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUserLoginPOST(
    { email, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUserLogin: refetch });
};

export const vendorCountGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/vendor/count`, {
    headers: {
      Accept: 'application/json',
      Authorization: 'null',
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useVendorCountGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['vendor', args],
    () => vendorCountGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['vendors']),
    }
  );
};

export const FetchVendorCountGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useVendorCountGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchVendorCount: refetch });
};

export const vendorEmailVerifyGET = (Constants, { vendor_id }, handlers = {}) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/vendor/verify_email/${
      typeof vendor_id === 'string'
        ? vendor_id
        : JSON.stringify(vendor_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useVendorEmailVerifyGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['vendor', args],
    () => vendorEmailVerifyGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['vendors']),
    }
  );
};

export const FetchVendorEmailVerifyGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  vendor_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useVendorEmailVerifyGET(
    { vendor_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchVendorEmailVerify: refetch });
};

export const vendorLoginPOST = (
  Constants,
  { email, password },
  handlers = {}
) =>
  fetch(`https://xwf7-6kvd-iy3s.n7.xano.io/api:2LAC__QO/vendor/auth/login`, {
    body: JSON.stringify({ email: email, password: password }),
    headers: {
      Accept: 'application/json',
      Authorization: 'null',
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useVendorLoginPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['vendor', args],
    () => vendorLoginPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['vendors']),
    }
  );
};

export const FetchVendorLoginPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useVendorLoginPOST(
    { email, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchVendorLogin: refetch });
};
