import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import UpdateMobileHeader128Block from '../components/UpdateMobileHeader128Block';
import UpdatedMobileMenuSignedOutVendorBlock from '../components/UpdatedMobileMenuSignedOutVendorBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Divider,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  Surface,
  Touchable,
  VideoPlayer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import { Image, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const VendorHomeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'VendorAcc',
        value: true,
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
    >
      <UpdateMobileHeader128Block />
      <>
        {!Constants['menuView'] ? null : (
          <UpdatedMobileMenuSignedOutVendorBlock />
        )}
      </>
      {/* Desktop Menu Special */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
                flexDirection: {
                  minWidth: Breakpoints.Laptop,
                  value: 'column',
                },
                height: { minWidth: Breakpoints.Laptop, value: 119.6 },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'center',
                },
                paddingBottom: { minWidth: Breakpoints.Laptop, value: 15 },
                paddingTop: { minWidth: Breakpoints.Laptop, value: 15 },
                width: {
                  minWidth: Breakpoints.Laptop,
                  value: dimensions.width,
                },
              },
              dimensions.width
            )}
          >
            {/* Header Container */}
            <>
              {Constants['user_ID'] || Constants['vendor_ID'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '55.9%' },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Secondary'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '27.06%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorSignupFormScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserSignupFormScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Sign Up'}
                    />
                    {/* Button 3 */}
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorLoginScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserLoginScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            backgroundColor: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Secondary'],
                            },
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            marginLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 10,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Login'}
                    />
                  </View>
                </View>
              )}
            </>
            {/* Header Container Signed In */}
            <>
              {!(Constants['user_ID'] || Constants['vendor_ID']) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '69.43%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Secondary'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 2 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('MarketplaceScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Marketplace'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 5 */}
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorDashboardScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          if (Constants['Admin']) {
                            navigation.navigate('AdminDashboardScreen');
                          }
                          if (Constants['Admin']) {
                            return;
                          }
                          navigation.navigate('UserDashboardScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'My Account'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '13.53%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc'] || Constants['Admin']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          navigation.navigate('UserHomeScreen');
                          setGlobalVariableValue({
                            key: 'vendor_ID',
                            value: '',
                          });
                          setGlobalVariableValue({
                            key: 'user_ID',
                            value: '',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Logout'}
                    />
                  </View>
                </View>
              )}
            </>
          </View>
        )}
      </>
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: theme.colors['Secondary'],
            height: [
              { minWidth: Breakpoints.Mobile, value: 20 },
              { minWidth: Breakpoints.Laptop, value: 50 },
            ],
            justifyContent: 'center',
            width: '100%',
          },
          dimensions.width
        )}
      ></View>
      {/* View 3 */}
      <>
        {dimensions.width >= Breakpoints.Tablet ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Background'],
                justifyContent: 'center',
                width: '100%',
              },
              dimensions.width
            )}
          >
            <>
              {Constants['vendor_ID'] ? null : (
                <Surface
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      { width: '100%' }
                    ),
                    dimensions.width
                  )}
                >
                  <Button
                    onPress={() => {
                      try {
                        navigation.navigate('VendorSignupFormScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: theme.colors['Primary'],
                          borderColor: theme.colors['Medium'],
                          borderRadius: 0,
                          borderWidth: 0.5,
                          color: theme.colors['Strong Inverse'],
                          fontFamily: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: 'Lato_700Bold',
                            },
                            { minWidth: Breakpoints.Laptop, value: 'System' },
                          ],
                          fontWeight: {
                            minWidth: Breakpoints.Laptop,
                            value: '600',
                          },
                          height: 60,
                          width: '100%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Sign Up As Vendor Now'}
                  />
                </Surface>
              )}
            </>
            {/* Surface 2 */}
            <>
              {Constants['vendor_ID'] ? null : (
                <Surface
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'],
                      { width: '100%' }
                    ),
                    dimensions.width
                  )}
                >
                  {/* Button 2 */}
                  <Button
                    onPress={() => {
                      try {
                        navigation.navigate('VendorLoginScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: theme.colors['Medium'],
                          borderColor: theme.colors['Medium'],
                          borderRadius: 0,
                          borderWidth: 0.5,
                          color: theme.colors['Strong Inverse'],
                          fontFamily: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: 'Lato_700Bold',
                            },
                            { minWidth: Breakpoints.Laptop, value: 'System' },
                          ],
                          fontWeight: {
                            minWidth: Breakpoints.Laptop,
                            value: '600',
                          },
                          height: 60,
                          width: '100%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Vendor Login'}
                  />
                </Surface>
              )}
            </>
          </View>
        )}
      </>
      <KeyboardAwareScrollView
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
        style={StyleSheet.applyWidth({ height: '100%' }, dimensions.width)}
        contentContainerStyle={StyleSheet.applyWidth(
          { justifyContent: 'flex-start' },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { backgroundColor: theme.colors['Medium'], height: '100%' },
            dimensions.width
          )}
        >
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                justifyContent: [
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                ],
                marginBottom: 5,
                marginTop: 5,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', width: '100%' },
                dimensions.width
              )}
            >
              {/* Image 2 */}
              <Image
                resizeMode={'cover'}
                source={Images.AdobeStock300144034}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    height: [
                      { minWidth: Breakpoints.Mobile, value: 450 },
                      { minWidth: Breakpoints.Laptop, value: 700 },
                    ],
                    opacity: 0.67,
                    width: '100%',
                  }),
                  dimensions.width
                )}
              />
              <Image
                resizeMode={'cover'}
                source={Images.AdobeStock438035062}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    height: [
                      { minWidth: Breakpoints.Laptop, value: '100%' },
                      { minWidth: Breakpoints.Mobile, value: 600 },
                    ],
                    opacity: [
                      { minWidth: Breakpoints.Mobile, value: 0.64 },
                      { minWidth: Breakpoints.Laptop, value: 0.46 },
                    ],
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                  }),
                  dimensions.width
                )}
              />
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    height: '90%',
                    justifyContent: 'center',
                    position: 'absolute',
                    top: '5%',
                    width: '90%',
                    zIndex: 1,
                  },
                  dimensions.width
                )}
              >
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Mobile, value: 'flex-start' },
                        { minWidth: Breakpoints.Laptop, value: 'flex-end' },
                      ],
                      height: '100%',
                      justifyContent: 'center',
                      width: '50%',
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'contain'}
                    source={Images.BevyBee1w2}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          height: '100%',
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '100%' },
                            { minWidth: Breakpoints.Laptop, value: '60%' },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  />
                </View>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Mobile, value: 'center' },
                        { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                      ],
                      justifyContent: 'center',
                      width: '50%',
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'JustAnotherHand_400Regular',
                          fontSize: [
                            { minWidth: Breakpoints.Tablet, value: 40 },
                            { minWidth: Breakpoints.Mobile, value: 30 },
                            { minWidth: Breakpoints.Laptop, value: 64 },
                            { minWidth: Breakpoints.BigScreen, value: 72 },
                          ],
                          textAlign: [
                            { minWidth: Breakpoints.Mobile, value: 'center' },
                            { minWidth: Breakpoints.Laptop, value: 'left' },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Welcome to the \nHive of Excitement!'}
                  </Text>
                  {/* Text 3 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: [
                            {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Strong Inverse'],
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: theme.colors['Strong Inverse'],
                            },
                          ],
                          fontFamily: 'Oswald_400Regular',
                          fontSize: [
                            { minWidth: Breakpoints.Desktop, value: 24 },
                            { minWidth: Breakpoints.Laptop, value: 24 },
                            { minWidth: Breakpoints.Mobile, value: 16 },
                            { minWidth: Breakpoints.Tablet, value: 16 },
                          ],
                          marginBottom: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Laptop, value: 10 },
                          ],
                          marginTop: 10,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Being a Social Bee Vendor has many benefits!'}
                  </Text>
                  <>
                    {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                      <Button
                        onPress={() => {
                          try {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorSignupFormScreen');
                            }
                            if (Constants['vendor_ID']) {
                              return;
                            }
                            navigation.navigate('VendorDashboardScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'],
                            {
                              backgroundColor: {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Secondary'],
                              },
                              borderRadius: {
                                minWidth: Breakpoints.Laptop,
                                value: 9,
                              },
                              height: {
                                minWidth: Breakpoints.Laptop,
                                value: 50,
                              },
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: 200,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Sign Up Now'}
                      />
                    )}
                  </>
                </View>
              </View>
            </View>

            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: [
                    { minWidth: Breakpoints.Laptop, value: 'center' },
                    { minWidth: Breakpoints.Mobile, value: 'center' },
                  ],
                  backgroundColor: theme.colors['Background'],
                  flexDirection: 'row',
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: '10%' },
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    padding: 16,
                    paddingLeft: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.Tablet, value: 40 },
                      { minWidth: Breakpoints.Laptop, value: 60 },
                    ],
                    paddingRight: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.Tablet, value: 40 },
                      { minWidth: Breakpoints.Laptop, value: 60 },
                    ],
                    width: [
                      { minWidth: Breakpoints.Mobile, value: '50%' },
                      { minWidth: Breakpoints.Laptop, value: '45%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors['Primary']}
                  name={'Feather/user-plus'}
                  size={60}
                />
                {/* Text 2 */}
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Secondary'],
                      fontFamily: 'Oswald_400Regular',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 14 },
                        { minWidth: Breakpoints.Tablet, value: 24 },
                        { minWidth: Breakpoints.Desktop, value: 22 },
                        { minWidth: Breakpoints.Laptop, value: 18 },
                      ],
                      marginBottom: 10,
                      marginTop: 20,
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {'Business Growth & Exposure'}
                </Text>
              </View>
              <Divider
                color={theme.colors['Secondary']}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.DividerStyles(theme)['Divider'],
                    { height: '70%', width: 2 }
                  ),
                  dimensions.width
                )}
              />
              {/* View 4 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    padding: 16,
                    paddingLeft: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.Tablet, value: 40 },
                      { minWidth: Breakpoints.Laptop, value: 60 },
                    ],
                    paddingRight: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.Tablet, value: 40 },
                      { minWidth: Breakpoints.Laptop, value: 60 },
                    ],
                    width: [
                      { minWidth: Breakpoints.Mobile, value: '50%' },
                      { minWidth: Breakpoints.Laptop, value: '45%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors['Primary']}
                  name={'MaterialCommunityIcons/account-cash'}
                  size={60}
                />
                {/* Text 2 */}
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Secondary'],
                      fontFamily: 'Oswald_400Regular',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 14 },
                        { minWidth: Breakpoints.Tablet, value: 24 },
                        { minWidth: Breakpoints.Desktop, value: 22 },
                        { minWidth: Breakpoints.Laptop, value: 18 },
                      ],
                      marginBottom: 10,
                      marginTop: 20,
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {'Boosted Revenue & Sales'}
                </Text>
              </View>
            </View>
            {/* View 5 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors['Secondary'],
                  height: [
                    { minWidth: Breakpoints.Mobile, value: 80 },
                    { minWidth: Breakpoints.Laptop, value: 100 },
                  ],
                  justifyContent: 'center',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Strong Inverse'],
                    fontFamily: 'Oswald_400Regular',
                    fontSize: 18,
                  }),
                  dimensions.width
                )}
              >
                {'How Social Bevy Works For You'}
              </Text>
              {/* Text 2 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary'],
                    fontFamily: 'Lato_400Regular',
                    fontSize: 16,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {"Let's Bee..gin"}
              </Text>
            </View>
            {/* desktop items */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* View 4 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        padding: 12,
                        paddingBottom: {
                          minWidth: Breakpoints.Laptop,
                          value: '5%',
                        },
                        paddingLeft: {
                          minWidth: Breakpoints.Laptop,
                          value: '5%',
                        },
                        paddingRight: {
                          minWidth: Breakpoints.Laptop,
                          value: '5%',
                        },
                        paddingTop: {
                          minWidth: Breakpoints.Laptop,
                          value: '5%',
                        },
                        width: '100%',
                        zIndex: 1,
                      },
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Divider'],
                          borderRadius: 12,
                          marginTop: 20,
                          padding: 16,
                          paddingLeft: 20,
                          paddingRight: 20,
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '48%' },
                            { minWidth: Breakpoints.Tablet, value: '45%' },
                            { minWidth: Breakpoints.Laptop, value: '19%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'Feather/user-plus'}
                        size={80}
                      />
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Secondary'],
                              fontFamily: 'Oswald_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Tablet, value: 30 },
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 18 },
                              ],
                              marginBottom: 10,
                              marginTop: 20,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'CREATE YOU ACCOUNT'}
                      </Text>

                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong'],
                              fontFamily: 'Lato_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Tablet, value: 22 },
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                              ],
                              textAlign: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Join the hive of excitement in just a few clicks! Provide your basic details, and start enjoying exclusive perks and a vibrant community.'
                        }
                      </Text>
                    </View>
                    {/* View 3 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Divider'],
                          borderRadius: 12,
                          marginTop: 20,
                          padding: 16,
                          paddingLeft: 20,
                          paddingRight: 20,
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '48%' },
                            { minWidth: Breakpoints.Tablet, value: '45%' },
                            { minWidth: Breakpoints.Laptop, value: '19%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'MaterialCommunityIcons/monitor-dashboard'}
                        size={80}
                      />
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Secondary'],
                              fontFamily: 'Oswald_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Tablet, value: 30 },
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 18 },
                              ],
                              marginBottom: 10,
                              marginTop: 20,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'ACCESS YOUR DASHBOARD'}
                      </Text>

                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong'],
                              fontFamily: 'Lato_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Tablet, value: 22 },
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                              ],
                              textAlign: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Access your vendor dashboard for a personalized experience. Track your offers and customer data within the Social Bevy community.'
                        }
                      </Text>
                    </View>
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Divider'],
                          borderRadius: 12,
                          marginTop: {
                            minWidth: Breakpoints.Laptop,
                            value: 20,
                          },
                          padding: 16,
                          paddingLeft: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Tablet, value: 40 },
                            { minWidth: Breakpoints.Laptop, value: 20 },
                          ],
                          paddingRight: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Tablet, value: 40 },
                            { minWidth: Breakpoints.Laptop, value: 20 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '48%' },
                            { minWidth: Breakpoints.Tablet, value: '45%' },
                            { minWidth: Breakpoints.Laptop, value: '19%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'FontAwesome/users'}
                        size={80}
                      />
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Secondary'],
                              fontFamily: 'Oswald_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Tablet, value: 30 },
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 18 },
                              ],
                              marginBottom: 10,
                              marginTop: 20,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'CONNECT WITH USERS'}
                      </Text>

                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong'],
                              fontFamily: 'Lato_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Tablet, value: 22 },
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                              ],
                              textAlign: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'As users utilize your deals, you get insight and data to help keep you connected to your target customer base.'
                        }
                      </Text>
                    </View>
                    {/* View 3 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Divider'],
                          borderRadius: 12,
                          marginTop: {
                            minWidth: Breakpoints.Laptop,
                            value: 20,
                          },
                          padding: 16,
                          paddingLeft: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Tablet, value: 40 },
                            { minWidth: Breakpoints.Laptop, value: 20 },
                          ],
                          paddingRight: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Tablet, value: 40 },
                            { minWidth: Breakpoints.Laptop, value: 20 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '48%' },
                            { minWidth: Breakpoints.Tablet, value: '45%' },
                            { minWidth: Breakpoints.Laptop, value: '19%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'Foundation/burst-sale'}
                        size={80}
                      />
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Secondary'],
                              fontFamily: 'Oswald_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Tablet, value: 30 },
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 18 },
                              ],
                              marginBottom: 10,
                              marginTop: 20,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'CREATE COUPONS & DEALS'}
                      </Text>

                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong'],
                              fontFamily: 'Lato_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Tablet, value: 22 },
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                              ],
                              textAlign: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Create deals and offers that draw in customers to specific products or services that your business offers.'
                        }
                      </Text>
                    </View>
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Divider'],
                          borderRadius: 12,
                          marginTop: {
                            minWidth: Breakpoints.Laptop,
                            value: 20,
                          },
                          padding: 16,
                          paddingLeft: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Tablet, value: 40 },
                            { minWidth: Breakpoints.Laptop, value: 20 },
                          ],
                          paddingRight: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Tablet, value: 40 },
                            { minWidth: Breakpoints.Laptop, value: 20 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '100%' },
                            { minWidth: Breakpoints.Tablet, value: '95%' },
                            { minWidth: Breakpoints.Laptop, value: '19%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'FontAwesome/line-chart'}
                        size={80}
                      />
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Secondary'],
                              fontFamily: 'Oswald_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Tablet, value: 30 },
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 18 },
                              ],
                              marginBottom: 10,
                              marginTop: 20,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'SALES & GROWTH INCREASE'}
                      </Text>

                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong'],
                              fontFamily: 'Lato_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Tablet, value: 22 },
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                              ],
                              textAlign: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Watch sales increase from exposure to the Social Bevy community as well as traffic drawn from your offers.'
                        }
                      </Text>
                    </View>
                  </View>
                  {/* View 7 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        padding: 12,
                        paddingLeft: {
                          minWidth: Breakpoints.Laptop,
                          value: '12%',
                        },
                        paddingRight: {
                          minWidth: Breakpoints.Laptop,
                          value: '12%',
                        },
                        width: '100%',
                        zIndex: 1,
                      },
                      dimensions.width
                    )}
                  />
                  <Image
                    resizeMode={'cover'}
                    source={Images.AdobeStock622378708}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          height: '80%',
                          position: 'absolute',
                          top: 0,
                          width: '100%',
                        }
                      ),
                      dimensions.width
                    )}
                  />
                </View>
              )}
            </>
            {/* mobile items 2 */}
            <>
              {dimensions.width >= Breakpoints.Laptop ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* View 4 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        padding: 12,
                        paddingLeft: {
                          minWidth: Breakpoints.Laptop,
                          value: '12%',
                        },
                        paddingRight: {
                          minWidth: Breakpoints.Laptop,
                          value: '12%',
                        },
                        width: '100%',
                        zIndex: 1,
                      },
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Divider'],
                          borderRadius: 12,
                          marginTop: 20,
                          padding: 16,
                          paddingLeft: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Tablet, value: 40 },
                            { minWidth: Breakpoints.Laptop, value: 60 },
                          ],
                          paddingRight: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Tablet, value: 40 },
                            { minWidth: Breakpoints.Laptop, value: 60 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '48%' },
                            { minWidth: Breakpoints.Tablet, value: '45%' },
                            { minWidth: Breakpoints.Laptop, value: '40%' },
                            { minWidth: Breakpoints.Desktop, value: '30%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'Feather/user-plus'}
                        size={80}
                      />
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Secondary'],
                              fontFamily: 'Oswald_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 18 },
                                { minWidth: Breakpoints.Tablet, value: 18 },
                              ],
                              marginBottom: 10,
                              marginTop: 20,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'CREATE YOU ACCOUNT'}
                      </Text>

                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong'],
                              fontFamily: 'Lato_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                                { minWidth: Breakpoints.Tablet, value: 14 },
                              ],
                              textAlign: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Join the hive of excitement in just a few clicks! Provide your basic details, and start enjoying exclusive perks and a vibrant community.'
                        }
                      </Text>
                    </View>
                    {/* View 3 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Divider'],
                          borderRadius: 12,
                          marginTop: 20,
                          padding: 16,
                          paddingLeft: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Tablet, value: 40 },
                            { minWidth: Breakpoints.Laptop, value: 60 },
                          ],
                          paddingRight: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Tablet, value: 40 },
                            { minWidth: Breakpoints.Laptop, value: 60 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '48%' },
                            { minWidth: Breakpoints.Tablet, value: '45%' },
                            { minWidth: Breakpoints.Desktop, value: '30%' },
                            { minWidth: Breakpoints.Laptop, value: '40%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'MaterialCommunityIcons/monitor-dashboard'}
                        size={80}
                      />
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Secondary'],
                              fontFamily: 'Oswald_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 18 },
                                { minWidth: Breakpoints.Tablet, value: 18 },
                              ],
                              marginBottom: 10,
                              marginTop: 20,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'ACCESS YOUR DASHBOARD'}
                      </Text>

                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong'],
                              fontFamily: 'Lato_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                                { minWidth: Breakpoints.Tablet, value: 14 },
                              ],
                              textAlign: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Access your vendor dashboard for a personalized experience. Track your offers and customer data within the Social Bevy community.'
                        }
                      </Text>
                    </View>
                  </View>
                  {/* View 6 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        padding: 12,
                        paddingLeft: {
                          minWidth: Breakpoints.Laptop,
                          value: '12%',
                        },
                        paddingRight: {
                          minWidth: Breakpoints.Laptop,
                          value: '12%',
                        },
                        width: '100%',
                        zIndex: [
                          { minWidth: Breakpoints.BigScreen, value: 4 },
                          { minWidth: Breakpoints.Mobile, value: 1 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Divider'],
                          borderRadius: 12,
                          padding: 16,
                          paddingLeft: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Tablet, value: 40 },
                            { minWidth: Breakpoints.Laptop, value: 60 },
                          ],
                          paddingRight: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Tablet, value: 40 },
                            { minWidth: Breakpoints.Laptop, value: 60 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '48%' },
                            { minWidth: Breakpoints.Tablet, value: '45%' },
                            { minWidth: Breakpoints.Desktop, value: '30%' },
                            { minWidth: Breakpoints.Laptop, value: '40%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'FontAwesome/users'}
                        size={80}
                      />
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Secondary'],
                              fontFamily: 'Oswald_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 18 },
                                { minWidth: Breakpoints.Tablet, value: 18 },
                              ],
                              marginBottom: 10,
                              marginTop: 20,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'CONNECT WITH USERS'}
                      </Text>

                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong'],
                              fontFamily: 'Lato_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                                { minWidth: Breakpoints.Tablet, value: 14 },
                              ],
                              textAlign: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'As users utilize your deals, you get insight and data to help keep you connected to your target customer base.'
                        }
                      </Text>
                    </View>
                    {/* View 3 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Divider'],
                          borderRadius: 12,
                          padding: 16,
                          paddingLeft: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Tablet, value: 40 },
                            { minWidth: Breakpoints.Laptop, value: 60 },
                          ],
                          paddingRight: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Tablet, value: 40 },
                            { minWidth: Breakpoints.Laptop, value: 60 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '48%' },
                            { minWidth: Breakpoints.Tablet, value: '45%' },
                            { minWidth: Breakpoints.Laptop, value: '40%' },
                            { minWidth: Breakpoints.Desktop, value: '30%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'Foundation/burst-sale'}
                        size={80}
                      />
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Secondary'],
                              fontFamily: 'Oswald_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                                { minWidth: Breakpoints.Tablet, value: 18 },
                              ],
                              marginBottom: 10,
                              marginTop: 20,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'CREATE COUPONS & DEALS'}
                      </Text>

                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong'],
                              fontFamily: 'Lato_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Laptop, value: 18 },
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Tablet, value: 14 },
                              ],
                              textAlign: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Create deals and offers that draw in customers to specific products or services that your business offers.'
                        }
                      </Text>
                    </View>
                  </View>
                  {/* View 7 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        padding: 12,
                        paddingLeft: {
                          minWidth: Breakpoints.Laptop,
                          value: '12%',
                        },
                        paddingRight: {
                          minWidth: Breakpoints.Laptop,
                          value: '12%',
                        },
                        width: '100%',
                        zIndex: 1,
                      },
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Divider'],
                          borderRadius: 12,
                          marginBottom: {
                            minWidth: Breakpoints.Tablet,
                            value: 20,
                          },
                          padding: 16,
                          paddingLeft: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Tablet, value: 40 },
                            { minWidth: Breakpoints.Laptop, value: 60 },
                          ],
                          paddingRight: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Tablet, value: 40 },
                            { minWidth: Breakpoints.Laptop, value: 60 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '100%' },
                            { minWidth: Breakpoints.Tablet, value: '95%' },
                            { minWidth: Breakpoints.Laptop, value: '87%' },
                            { minWidth: Breakpoints.Desktop, value: '40%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors['Primary']}
                        name={'FontAwesome/line-chart'}
                        size={80}
                      />
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Secondary'],
                              fontFamily: 'Oswald_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 18 },
                                { minWidth: Breakpoints.Tablet, value: 18 },
                              ],
                              marginBottom: 10,
                              marginTop: 20,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'SALES & GROWTH INCREASE'}
                      </Text>

                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Strong'],
                              fontFamily: 'Lato_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                                { minWidth: Breakpoints.Tablet, value: 14 },
                              ],
                              textAlign: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Watch sales increase from exposure to the Social Bevy community as well as traffic drawn from your offers.'
                        }
                      </Text>
                    </View>
                  </View>
                  <Image
                    resizeMode={'cover'}
                    source={Images.AdobeStock622378708Preview}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          height: '80%',
                          position: 'absolute',
                          top: 0,
                          width: '100%',
                        }
                      ),
                      dimensions.width
                    )}
                  />
                </View>
              )}
            </>
            {/* View 8 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors['Secondary'],
                  borderBottomWidth: 2,
                  borderColor: theme.colors['Primary'],
                  borderTopWidth: 2,
                  flexDirection: 'row',
                  height: [
                    { minWidth: Breakpoints.Mobile, value: 100 },
                    { minWidth: Breakpoints.Laptop, value: 140 },
                  ],
                  justifyContent: 'center',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Strong Inverse'],
                    fontFamily: 'Oswald_400Regular',
                    fontSize: 18,
                  }),
                  dimensions.width
                )}
              >
                {'Our Users,'}
              </Text>
              {/* Text 2 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary'],
                    fontFamily: 'Oswald_400Regular',
                    fontSize: 18,
                  }),
                  dimensions.width
                )}
              >
                {' Your Customers'}
              </Text>
            </View>
            {/* View 9 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors['Background'],
                  height: [
                    { minWidth: Breakpoints.Mobile, value: 150 },
                    { minWidth: Breakpoints.Laptop, value: 350 },
                  ],
                  justifyContent: 'center',
                  paddingLeft: 40,
                  paddingRight: 40,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { width: '100%', zIndex: 1 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Strong Inverse'],
                      fontFamily: 'Oswald_600SemiBold',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 16 },
                        { minWidth: Breakpoints.Laptop, value: 14 },
                      ],
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {
                    'We currently have many users across the greater Houston area.'
                  }
                </Text>
              </View>

              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    height: 80,
                    justifyContent: 'center',
                    zIndex: 1,
                  },
                  dimensions.width
                )}
              >
                {/* Text 2 */}
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Strong Inverse'],
                      fontFamily: 'Lato_400Regular',
                      fontSize: 14,
                    }),
                    dimensions.width
                  )}
                >
                  {'Count:'}
                </Text>
                {/* Text 2 */}
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Strong Inverse'],
                      fontFamily: 'Lato_700Bold',
                      fontSize: 30,
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {'183'}
                </Text>
              </View>
              <Image
                resizeMode={'cover'}
                source={Images.Bg04}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                  }),
                  dimensions.width
                )}
              />
            </View>
          </View>
          {/* Footer App */}
          <>
            {dimensions.width >= Breakpoints.Laptop ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Secondary'],
                    flexDirection: 'row',
                    height: 80,
                    justifyContent: 'space-between',
                    marginTop: 20,
                    paddingLeft: 16,
                    paddingRight: 16,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <View>
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        { color: theme.colors['Strong Inverse'], fontSize: 12 }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Copyright © Social Bevy 2023'}
                  </Text>
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      justifyContent: 'space-evenly',
                      width: '45%',
                    },
                    dimensions.width
                  )}
                >
                  <Link
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.LinkStyles(theme)['Link'],
                        { fontSize: 12 }
                      ),
                      dimensions.width
                    )}
                    title={'EULA'}
                  />
                  {/* Link 2 */}
                  <Link
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.LinkStyles(theme)['Link'],
                        { fontSize: 12 }
                      ),
                      dimensions.width
                    )}
                    title={'Privacy Policy'}
                  />
                </View>
              </View>
            )}
          </>
        </View>
        {/* Footer Site */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors['Secondary'],
                  flexDirection: 'row',
                  height: 80,
                  justifyContent: 'space-between',
                  paddingLeft: 16,
                  paddingRight: 16,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <View>
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Strong Inverse'],
                      fontSize: 12,
                    }),
                    dimensions.width
                  )}
                >
                  {'Copyright © Social Bevy 2023'}
                </Text>
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    width: '45%',
                  },
                  dimensions.width
                )}
              >
                <Link
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                      fontSize: 12,
                    }),
                    dimensions.width
                  )}
                  title={'EULA'}
                />
                {/* Link 2 */}
                <Link
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                      fontSize: 12,
                    }),
                    dimensions.width
                  )}
                  title={'Privacy Policy'}
                />
              </View>
            </View>
          )}
        </>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(VendorHomeScreen);
