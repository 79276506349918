import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SocialBevyApi from '../apis/SocialBevyApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  DatePicker,
  Icon,
  IconButton,
  Link,
  NumberInput,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import * as Linking from 'expo-linking';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const ManageBusinessLocationScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [address2Value, setAddress2Value] = React.useState('');
  const [addressValue, setAddressValue] = React.useState('');
  const [cityValue, setCityValue] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [locationID, setLocationID] = React.useState('');
  const [locationNameValue, setLocationNameValue] = React.useState('');
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const [postalCodeValue, setPostalCodeValue] = React.useState('');
  const [stateValue, setStateValue] = React.useState('');
  const socialBevyEditLocationPATCH = SocialBevyApi.useEditLocationPATCH();

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
    >
      {/* Desktop Menu Special 2 */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
                flexDirection: {
                  minWidth: Breakpoints.Laptop,
                  value: 'column',
                },
                height: { minWidth: Breakpoints.Laptop, value: 119.6 },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'center',
                },
                paddingBottom: { minWidth: Breakpoints.Laptop, value: 15 },
                paddingTop: { minWidth: Breakpoints.Laptop, value: 15 },
                width: {
                  minWidth: Breakpoints.Laptop,
                  value: dimensions.width,
                },
              },
              dimensions.width
            )}
          >
            {/* Header Container */}
            <>
              {Constants['user_ID'] || Constants['vendor_ID'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '55.9%' },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '27.06%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorSignupFormScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserSignupFormScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Sign Up'}
                    />
                    {/* Button 3 */}
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorLoginScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserLoginScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            backgroundColor: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Secondary'],
                            },
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            marginLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 10,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Login'}
                    />
                  </View>
                </View>
              )}
            </>
            {/* Header Container Signed In */}
            <>
              {!(Constants['user_ID'] || Constants['vendor_ID']) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '69.43%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 2 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('MarketplaceScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Marketplace'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 5 */}
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorDashboardScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          if (Constants['Admin']) {
                            navigation.navigate('AdminDashboardScreen');
                          }
                          if (Constants['Admin']) {
                            return;
                          }
                          navigation.navigate('UserDashboardScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Secondary'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'My Account'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '13.53%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc'] || Constants['Admin']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          navigation.navigate('UserHomeScreen');
                          setGlobalVariableValue({
                            key: 'vendor_ID',
                            value: '',
                          });
                          setGlobalVariableValue({
                            key: 'user_ID',
                            value: '',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Logout'}
                    />
                  </View>
                </View>
              )}
            </>
          </View>
        )}
      </>
      {/* Mobile Menu Opener */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Background'],
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-between',
                marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
                paddingLeft: 16,
                paddingRight: 16,
              },
              dimensions.width
            )}
          >
            <View>
              <Image
                resizeMode={'contain'}
                source={Images.BevyLogo}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ImageStyles(theme)['Image'],
                  dimensions.width
                )}
              />
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', zIndex: 1 },
                dimensions.width
              )}
            >
              {/* Icon Button 2 */}
              <>
                {mobileMenu ? null : (
                  <IconButton
                    icon={'Feather/menu'}
                    onPress={() => {
                      try {
                        setMobileMenu(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={38}
                    style={StyleSheet.applyWidth(
                      { marginRight: 5 },
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* Icon Button 3 */}
              <>
                {!mobileMenu ? null : (
                  <IconButton
                    icon={'AntDesign/closecircleo'}
                    onPress={() => {
                      try {
                        setMobileMenu(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={38}
                    style={StyleSheet.applyWidth(
                      { marginRight: 5 },
                      dimensions.width
                    )}
                  />
                )}
              </>
            </View>
          </View>
        )}
      </>
      <KeyboardAwareScrollView
        contentContainerStyle={StyleSheet.applyWidth(
          {
            backgroundColor: [
              {
                minWidth: Breakpoints.Laptop,
                value: theme.colors['Secondary'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: theme.colors['Secondary'],
              },
            ],
            flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
          },
          dimensions.width
        )}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
      >
        <ImageBackground
          resizeMode={'cover'}
          source={Images.AdobeStock438035062}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
              {
                flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                height: { minWidth: Breakpoints.Laptop, value: '100%' },
                padding: { minWidth: Breakpoints.Laptop, value: '5%' },
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              }
            ),
            dimensions.width
          )}
        >
          {/* Mobile Menu Special 3 */}
          <>
            {!mobileMenu ? null : (
              <BlurView
                intensity={80}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.BlurViewStyles(theme)['Blur View'],
                    { height: '100%', width: '100%', zIndex: 1 }
                  ),
                  dimensions.width
                )}
                tint={'light'}
              >
                <>
                  {dimensions.width >= Breakpoints.Laptop ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Surface'],
                          borderBottomLeftRadius: 12,
                          borderBottomRightRadius: 12,
                          height: 500,
                          opacity: 1,
                          paddingTop: 30,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Divider'],
                            flexDirection: 'row',
                            height: 110,
                            justifyContent: 'space-evenly',
                            paddingBottom: [
                              { minWidth: Breakpoints.Tablet, value: 15 },
                              { minWidth: Breakpoints.Mobile, value: 20 },
                            ],
                            paddingLeft: 40,
                            paddingRight: 40,
                            paddingTop: [
                              { minWidth: Breakpoints.Tablet, value: 15 },
                              { minWidth: Breakpoints.Mobile, value: 20 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { justifyContent: 'center', width: '70%' },
                            dimensions.width
                          )}
                        >
                          {/* View 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              allowFontScaling={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    fontFamily: 'Lato_400Regular',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 20,
                                      },
                                    ],
                                    marginBottom: 5,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Welcome,'}
                            </Text>
                            {/* Text 2 */}
                            <Text
                              accessible={true}
                              allowFontScaling={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    fontFamily: 'Lato_400Regular',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 20,
                                      },
                                    ],
                                    marginBottom: 5,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {Constants['fName']}
                            </Text>
                          </View>
                          {/* Special Buttons */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'row',
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'row',
                                  },
                                ],
                                justifyContent: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'flex-end',
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'space-evenly',
                                  },
                                ],
                                marginTop: 5,
                                minHeight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 45,
                                },
                                width: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '27.06%',
                                },
                              },
                              dimensions.width
                            )}
                          >
                            <>
                              {!(
                                Constants['user_ID'] || Constants['vendor_ID']
                              ) ? null : (
                                <Button
                                  onPress={() => {
                                    try {
                                      if (Constants['VendorAcc']) {
                                        if (Constants['vendor_ID']) {
                                          navigation.navigate(
                                            'VendorDashboardScreen'
                                          );
                                        } else {
                                          navigation.navigate(
                                            'VendorSignupFormScreen'
                                          );
                                        }
                                      } else {
                                        if (Constants['user_ID']) {
                                          navigation.navigate(
                                            'UserDashboardScreen'
                                          );
                                        } else {
                                          navigation.navigate(
                                            'UserSignupFormScreen'
                                          );
                                        }
                                      }
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ButtonStyles(theme)[
                                        'Button'
                                      ],
                                      {
                                        borderRadius: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 9,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 9,
                                          },
                                        ],
                                        width: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '45%',
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '45%',
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                  title={'Sign Out'}
                                />
                              )}
                            </>
                            {/* Button 2 */}
                            <>
                              {Constants['user_ID'] ||
                              Constants['vendor_ID'] ? null : (
                                <Button
                                  onPress={() => {
                                    try {
                                      if (Constants['VendorAcc']) {
                                        if (Constants['vendor_ID']) {
                                          navigation.navigate(
                                            'VendorDashboardScreen'
                                          );
                                        } else {
                                          navigation.navigate(
                                            'VendorSignupFormScreen'
                                          );
                                        }
                                      } else {
                                        if (Constants['user_ID']) {
                                          navigation.navigate(
                                            'UserDashboardScreen'
                                          );
                                        } else {
                                          navigation.navigate(
                                            'UserSignupFormScreen'
                                          );
                                        }
                                      }
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ButtonStyles(theme)[
                                        'Button'
                                      ],
                                      {
                                        borderRadius: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 9,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 9,
                                          },
                                        ],
                                        width: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '45%',
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '45%',
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                  title={'Sign Up'}
                                />
                              )}
                            </>
                            {/* Button 3 */}
                            <>
                              {Constants['user_ID'] || undefined ? null : (
                                <Button
                                  onPress={() => {
                                    try {
                                      if (Constants['VendorAcc']) {
                                        if (Constants['vendor_ID']) {
                                          navigation.navigate(
                                            'VendorDashboardScreen'
                                          );
                                        } else {
                                          navigation.navigate(
                                            'VendorLoginScreen'
                                          );
                                        }
                                      } else {
                                        if (Constants['user_ID']) {
                                          navigation.navigate(
                                            'UserDashboardScreen'
                                          );
                                        } else {
                                          navigation.navigate(
                                            'UserLoginScreen'
                                          );
                                        }
                                      }
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ButtonStyles(theme)[
                                        'Button'
                                      ],
                                      {
                                        backgroundColor: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: theme.colors['Secondary'],
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: theme.colors['Secondary'],
                                          },
                                        ],
                                        borderRadius: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 9,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 9,
                                          },
                                        ],
                                        marginLeft: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 10,
                                        },
                                        width: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '45%',
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '45%',
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                  title={'Login'}
                                />
                              )}
                            </>
                          </View>
                        </View>
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          <Image
                            resizeMode={'contain'}
                            source={Images.BevyBee}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ImageStyles(theme)['Image'],
                                { borderRadius: 100 }
                              ),
                              dimensions.width
                            )}
                          />
                        </View>
                      </View>
                      {/* Link Buttons 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: [
                              {
                                minWidth: Breakpoints.BigScreen,
                                value: 'center',
                              },
                              { minWidth: Breakpoints.Laptop, value: 'center' },
                            ],
                            flexDirection: [
                              { minWidth: Breakpoints.BigScreen, value: 'row' },
                              { minWidth: Breakpoints.Laptop, value: 'row' },
                            ],
                            height: [
                              { minWidth: Breakpoints.BigScreen, value: 100 },
                              { minWidth: Breakpoints.Mobile, value: 300 },
                            ],
                            justifyContent: [
                              {
                                minWidth: Breakpoints.BigScreen,
                                value: 'space-evenly',
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'space-evenly',
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'space-evenly',
                              },
                            ],
                            marginTop: 30,
                            paddingLeft: 40,
                            paddingRight: 40,
                            width: [
                              { minWidth: Breakpoints.BigScreen, value: '70%' },
                              { minWidth: Breakpoints.Laptop, value: '70%' },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              borderBottomWidth: 0.5,
                              borderColor: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: theme.colors['Primary'],
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: theme.colors['Light'],
                                },
                              ],
                              height: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: '100%',
                                },
                                { minWidth: Breakpoints.Laptop, value: 80 },
                                { minWidth: Breakpoints.Mobile, value: 35 },
                              ],
                              justifyContent: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              width: [
                                { minWidth: Breakpoints.BigScreen, value: 100 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          {/* Link 2 */}
                          <Link
                            accessible={true}
                            allowFontScaling={true}
                            onPress={() => {
                              try {
                                if (Constants['VendorAcc']) {
                                  navigation.navigate('VendorHomeScreen');
                                }
                                if (Constants['VendorAcc']) {
                                  return;
                                }
                                navigation.navigate('UserHomeScreen');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)['Link'],
                                {
                                  color: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: theme.colors['Primary'],
                                  },
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'Oswald_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'Oswald_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'Oswald_400Regular',
                                    },
                                  ],
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 16,
                                    },
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                  ],
                                  textTransform: 'uppercase',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Home'}
                          />
                        </View>
                        {/* View 3 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              borderBottomWidth: 0.5,
                              borderColor: theme.colors['Light'],
                              height: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: '100%',
                                },
                                { minWidth: Breakpoints.Laptop, value: 80 },
                                { minWidth: Breakpoints.Mobile, value: 35 },
                              ],
                              justifyContent: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              width: [
                                { minWidth: Breakpoints.BigScreen, value: 100 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          {/* Link 3 */}
                          <Link
                            accessible={true}
                            allowFontScaling={true}
                            onPress={() => {
                              try {
                                navigation.navigate('PodcastScreen');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)['Link'],
                                {
                                  color: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: theme.colors['Primary'],
                                  },
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'Oswald_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'Oswald_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'Oswald_400Regular',
                                    },
                                  ],
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 16,
                                    },
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                  ],
                                  textTransform: 'uppercase',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'podcast'}
                          />
                        </View>
                        {/* View 6 */}
                        <>
                          {!(
                            Constants['user_ID'] || Constants['vendor_ID']
                          ) ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'center',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                  ],
                                  borderBottomWidth: 0.5,
                                  borderColor: theme.colors['Light'],
                                  height: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: '100%',
                                    },
                                    { minWidth: Breakpoints.Laptop, value: 80 },
                                    { minWidth: Breakpoints.Mobile, value: 35 },
                                  ],
                                  justifyContent: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'center',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                  ],
                                  width: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 100,
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 100,
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {/* Link 3 */}
                              <Link
                                accessible={true}
                                allowFontScaling={true}
                                onPress={() => {
                                  try {
                                    navigation.navigate('MarketplaceScreen');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)['Link'],
                                    {
                                      color: {
                                        minWidth: Breakpoints.BigScreen,
                                        value: theme.colors['Primary'],
                                      },
                                      fontFamily: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 'Oswald_400Regular',
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'Oswald_400Regular',
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 'Oswald_400Regular',
                                        },
                                      ],
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 16,
                                        },
                                      ],
                                      textTransform: 'uppercase',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'Marketplace'}
                              />
                            </View>
                          )}
                        </>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              borderBottomWidth: 0.5,
                              borderColor: theme.colors['Light'],
                              height: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: '100%',
                                },
                                { minWidth: Breakpoints.Laptop, value: 80 },
                                { minWidth: Breakpoints.Mobile, value: 35 },
                              ],
                              justifyContent: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              width: [
                                { minWidth: Breakpoints.BigScreen, value: 100 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          {/* Link 4 */}
                          <Link
                            accessible={true}
                            allowFontScaling={true}
                            onPress={() => {
                              try {
                                navigation.navigate('ContactUsScreen');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)['Link'],
                                {
                                  color: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: theme.colors['Primary'],
                                  },
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'Oswald_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'Oswald_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'Oswald_400Regular',
                                    },
                                  ],
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 16,
                                    },
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                  ],
                                  textTransform: 'uppercase',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'contact us'}
                          />
                        </View>
                        {/* View 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              borderBottomWidth: 0.5,
                              borderColor: theme.colors['Light'],
                              height: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: '100%',
                                },
                                { minWidth: Breakpoints.Laptop, value: 80 },
                                { minWidth: Breakpoints.Mobile, value: 35 },
                              ],
                              justifyContent: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              width: [
                                { minWidth: Breakpoints.BigScreen, value: 100 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          {/* Link 4 */}
                          <>
                            {!(
                              Constants['user_ID'] || Constants['vendor_ID']
                            ) ? null : (
                              <Link
                                accessible={true}
                                allowFontScaling={true}
                                onPress={() => {
                                  try {
                                    if (Constants['VendorAcc']) {
                                      navigation.navigate(
                                        'VendorDashboardScreen'
                                      );
                                    }
                                    if (Constants['VendorAcc']) {
                                      return;
                                    }
                                    if (Constants['Admin']) {
                                      navigation.navigate(
                                        'AdminDashboardScreen'
                                      );
                                    }
                                    if (Constants['Admin']) {
                                      return;
                                    }
                                    navigation.navigate('UserDashboardScreen');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)['Link'],
                                    {
                                      color: {
                                        minWidth: Breakpoints.BigScreen,
                                        value: theme.colors['Primary'],
                                      },
                                      fontFamily: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 'Oswald_400Regular',
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'Oswald_400Regular',
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 'Oswald_400Regular',
                                        },
                                      ],
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 16,
                                        },
                                      ],
                                      textTransform: 'uppercase',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'My Account'}
                              />
                            )}
                          </>
                        </View>
                      </View>
                    </View>
                  )}
                </>
              </BlurView>
            )}
          </>
          {/* Mobile Header 2 */}
          <>
            {dimensions.width >= Breakpoints.Laptop ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Secondary'],
                    flexDirection: 'row',
                    height: 100,
                    justifyContent: 'space-around',
                    marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
                    paddingLeft: 16,
                    paddingRight: 16,
                  },
                  dimensions.width
                )}
              >
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', zIndex: 1 },
                    dimensions.width
                  )}
                >
                  {/* Icon Button 3 */}
                  <IconButton
                    icon={'AntDesign/leftcircle'}
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={32}
                    style={StyleSheet.applyWidth(
                      { marginRight: 5 },
                      dimensions.width
                    )}
                  />
                </View>

                <View>
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Lato_900Black',
                          fontSize: 20,
                          marginRight: 20,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Vendor Location Manager'}
                  </Text>
                </View>
              </View>
            )}
          </>
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <Surface
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'],
                    {
                      borderBottomLeftRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: 12,
                      },
                      borderTopLeftRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: 12,
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'column',
                      },
                      marginTop: { minWidth: Breakpoints.Laptop, value: -50 },
                      padding: { minWidth: Breakpoints.BigScreen, value: 20 },
                      width: { minWidth: Breakpoints.Laptop, value: '50%' },
                    }
                  ),
                  dimensions.width
                )}
              >
                <ImageBackground
                  resizeMode={'cover'}
                  source={Images.AdobeStock300144034Preview1}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageBackgroundStyles(theme)[
                        'Image Background'
                      ],
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        padding: { minWidth: Breakpoints.Laptop, value: '5%' },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: 'rgba(0, 0, 0, 0.58)',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      },
                      dimensions.width
                    )}
                  >
                    <View>
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Strong Inverse'],
                              },
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'Oswald_400Regular',
                              },
                              fontSize: {
                                minWidth: Breakpoints.Laptop,
                                value: 24,
                              },
                              textAlign: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Social Bee Vendor Location Setup'}
                      </Text>
                    </View>
                  </View>
                </ImageBackground>
              </Surface>
            )}
          </>
          {/* Surface 2 */}
          <Surface
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.SurfaceStyles(theme)['Surface'], {
                borderBottomRightRadius: {
                  minWidth: Breakpoints.Laptop,
                  value: 12,
                },
                borderTopRightRadius: {
                  minWidth: Breakpoints.Laptop,
                  value: 12,
                },
                marginTop: { minWidth: Breakpoints.Laptop, value: -50 },
                padding: { minWidth: Breakpoints.BigScreen, value: 20 },
                width: { minWidth: Breakpoints.Laptop, value: '50%' },
              }),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  padding: { minWidth: Breakpoints.Laptop, value: '5%' },
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: '20%' },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: '20%' },
                },
                dimensions.width
              )}
            >
              {/* Name 8 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    height: 200,
                    marginLeft: 20,
                    marginRight: 20,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                <View>
                  {/* Text 2 */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Primary'],
                        fontFamily: 'Lato_700Bold',
                        fontSize: 18,
                        marginTop: 20,
                        paddingTop: 5,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {'Manage This Location'}
                  </Text>
                </View>

                <SocialBevyApi.FetchGetLocationGET
                  handlers={{
                    onData: fetchData => {
                      try {
                        setLocationID(fetchData?.id);
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  vendor_id={props.route?.params?.Current_id ?? ''}
                >
                  {({ loading, error, data, refetchGetLocation }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <Surface
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'],
                            { borderRadius: 9, marginTop: 10, padding: 15 }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* Text 6 */}
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Lato_700Bold', fontSize: 16 }
                            ),
                            dimensions.width
                          )}
                        >
                          {fetchData?.location_name}
                        </Text>
                        {/* Text 4 */}
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { fontFamily: 'Lato_700Bold', marginTop: 10 }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Address'}
                        </Text>
                        {/* Text 3 */}
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              { marginTop: 5 }
                            ),
                            dimensions.width
                          )}
                        >
                          {fetchData?.Address_1}
                          {', '}
                          {fetchData?.suite_or_unit}
                        </Text>
                        {/* Text 5 */}
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Text'],
                            dimensions.width
                          )}
                        >
                          {fetchData?.city}
                          {', '}
                          {fetchData?.state} {fetchData?.zip_code}
                        </Text>
                      </Surface>
                    );
                  }}
                </SocialBevyApi.FetchGetLocationGET>
              </View>
              {/* Name */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: 48,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* Location Name */}
                <TextInput
                  editable={true}
                  onChangeText={newLocationNameValue => {
                    try {
                      setGlobalVariableValue({
                        key: 'Name',
                        value: newLocationNameValue,
                      });
                      setLocationNameValue(newLocationNameValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'ex.Midtown Location'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 0.5,
                      color: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Secondary'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Secondary'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'Lato_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'Lato_400Regular',
                        },
                      ],
                      fontSize: 15,
                      height: 48,
                      paddingBottom: 8,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={locationNameValue}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', top: -10 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Location Name'}
                  </Text>
                </View>
              </View>
              {/* Name 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: 48,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* Address */}
                <TextInput
                  autoComplete={'address-line1'}
                  editable={true}
                  onChangeText={newAddressValue => {
                    try {
                      setGlobalVariableValue({
                        key: 'Name',
                        value: newAddressValue,
                      });
                      setAddressValue(newAddressValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Address'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 0.5,
                      color: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Secondary'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Secondary'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'Lato_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'Lato_400Regular',
                        },
                      ],
                      fontSize: 15,
                      height: 48,
                      paddingBottom: 8,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={addressValue}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', top: -10 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Address'}
                  </Text>
                </View>
              </View>
              {/* Name 3 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: 48,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* Address 2 */}
                <TextInput
                  autoComplete={'address-line2'}
                  editable={true}
                  onChangeText={newAddress2Value => {
                    try {
                      setGlobalVariableValue({
                        key: 'Name',
                        value: newAddress2Value,
                      });
                      setAddress2Value(newAddress2Value);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Apt, Suite, Flr'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 0.5,
                      color: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Secondary'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Secondary'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'Lato_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'Lato_400Regular',
                        },
                      ],
                      fontSize: 15,
                      height: 48,
                      paddingBottom: 8,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={address2Value}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', top: -10 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Address 2'}
                  </Text>
                </View>
              </View>
              {/* Name 9 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: 48,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* City */}
                <TextInput
                  editable={true}
                  onChangeText={newCityValue => {
                    try {
                      setGlobalVariableValue({
                        key: 'Name',
                        value: newCityValue,
                      });
                      setCityValue(newCityValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'City'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 0.5,
                      color: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Secondary'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Secondary'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'Lato_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'Lato_400Regular',
                        },
                      ],
                      fontSize: 15,
                      height: 48,
                      paddingBottom: 8,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={cityValue}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', top: -10 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'City'}
                  </Text>
                </View>
              </View>
              {/* Name 10 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: 48,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* State */}
                <TextInput
                  editable={true}
                  onChangeText={newStateValue => {
                    try {
                      setGlobalVariableValue({
                        key: 'Name',
                        value: newStateValue,
                      });
                      setStateValue(newStateValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'State'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 0.5,
                      color: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Secondary'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Secondary'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'Lato_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'Lato_400Regular',
                        },
                      ],
                      fontSize: 15,
                      height: 48,
                      paddingBottom: 8,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={stateValue}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', top: -10 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'State'}
                  </Text>
                </View>
              </View>
              {/* Name 7 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: 48,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* postalCode */}
                <NumberInput
                  allowFontScaling={true}
                  autoComplete={'postal-code'}
                  changeTextDelay={500}
                  onChangeText={newPostalCodeValue => {
                    try {
                      setPostalCodeValue(newPostalCodeValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter your zip code'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.NumberInputStyles(theme)['Number Input'],
                      {
                        color: theme.colors['Secondary'],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 15,
                        height: 48,
                        paddingLeft: 20,
                        paddingRight: 20,
                      }
                    ),
                    dimensions.width
                  )}
                  value={postalCodeValue}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', top: -10 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Zip Code'}
                  </Text>
                </View>
              </View>

              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Error'],
                    fontFamily: 'Lato_400Regular',
                    marginTop: 10,
                    paddingLeft: 30,
                    paddingRight: 30,
                    textDecorationLine: 'underline',
                  }),
                  dimensions.width
                )}
              >
                {errorMsg}
              </Text>
              {/* Continue */}
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      const locationEditResult = (
                        await socialBevyEditLocationPATCH.mutateAsync({
                          Address_1: addressValue,
                          city: cityValue,
                          location_name: locationNameValue,
                          state: stateValue,
                          suite_or_unit: address2Value,
                          vendor_id: Constants['vendor_ID'],
                          vendor_locations_id: locationID,
                          zip_code: postalCodeValue,
                        })
                      )?.json;
                      const errorResult = locationEditResult?.message;
                      setErrorMsg(errorResult);
                      if (errorResult) {
                        return;
                      }
                      navigation.navigate('VendorDashboardScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: theme.colors['Primary'],
                      },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Primary'],
                      },
                    ],
                    borderRadius: 12,
                    fontFamily: 'Lato_700Bold',
                    fontSize: 17,
                    height: [
                      { minWidth: Breakpoints.Mobile, value: 48 },
                      { minWidth: Breakpoints.Laptop, value: 60 },
                    ],
                    marginBottom: 20,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: [
                      { minWidth: Breakpoints.Laptop, value: 20 },
                      { minWidth: Breakpoints.Mobile, value: 20 },
                    ],
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Update Location'}
              />
            </View>
          </Surface>
        </ImageBackground>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(ManageBusinessLocationScreen);
