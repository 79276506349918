import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SocialBevyApi from '../apis/SocialBevyApi.js';
import UpdateMobileHeader128Block from '../components/UpdateMobileHeader128Block';
import UpdatedMobileMenuUserSignedInBlock from '../components/UpdatedMobileMenuUserSignedInBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  Divider,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const ManageAccountUserScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Custom Color'] },
        dimensions.width
      )}
    >
      {/* Desktop Menu Special 2 */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
                flexDirection: {
                  minWidth: Breakpoints.Laptop,
                  value: 'column',
                },
                height: { minWidth: Breakpoints.Laptop, value: 119.6 },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'center',
                },
                paddingBottom: { minWidth: Breakpoints.Laptop, value: 15 },
                paddingTop: { minWidth: Breakpoints.Laptop, value: 15 },
                width: {
                  minWidth: Breakpoints.Laptop,
                  value: dimensions.width,
                },
              },
              dimensions.width
            )}
          >
            {/* Header Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                  height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'space-between',
                  },
                  width: { minWidth: Breakpoints.Laptop, value: '74%' },
                },
                dimensions.width
              )}
            >
              {/* Logo */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    width: { minWidth: Breakpoints.Laptop, value: '16.93%' },
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'contain'}
                  source={Images.BevyLogo}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '120%' },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                />
              </View>
              {/* Menu */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    height: { minWidth: Breakpoints.Laptop, value: '80%' },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'space-evenly',
                    },
                    width: { minWidth: Breakpoints.Laptop, value: '55.9%' },
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      if (Constants['VendorAcc']) {
                        navigation.navigate('VendorHomeScreen');
                      }
                      if (Constants['VendorAcc']) {
                        return;
                      }
                      navigation.navigate('UserHomeScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: {
                        minWidth: Breakpoints.Laptop,
                        value: 3,
                      },
                      borderColor: {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Strong Inverse'],
                      },
                      height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Primary'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Oswald_400Regular',
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            textTransform: {
                              minWidth: Breakpoints.Laptop,
                              value: 'uppercase',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Home'}
                    </Text>
                  </View>
                </Touchable>
                {/* Touchable 4 */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('PodcastScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: {
                        minWidth: Breakpoints.Laptop,
                        value: 3,
                      },
                      borderColor: {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Strong Inverse'],
                      },
                      height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Primary'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Oswald_400Regular',
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            textTransform: {
                              minWidth: Breakpoints.Laptop,
                              value: 'uppercase',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Podcasts'}
                    </Text>
                  </View>
                </Touchable>
                {/* Touchable 3 */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('ContactUsScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: {
                        minWidth: Breakpoints.Laptop,
                        value: 3,
                      },
                      borderColor: {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Strong Inverse'],
                      },
                      height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Primary'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Oswald_400Regular',
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            textTransform: {
                              minWidth: Breakpoints.Laptop,
                              value: 'uppercase',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Contact Us'}
                    </Text>
                  </View>
                </Touchable>
              </View>
              {/* Buttons or Icons */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-end',
                    },
                    minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                    width: { minWidth: Breakpoints.Laptop, value: '27.06%' },
                  },
                  dimensions.width
                )}
              >
                <Button
                  onPress={() => {
                    try {
                      if (Constants['VendorAcc']) {
                        if (Constants['vendor_ID']) {
                          navigation.navigate('VendorDashboardScreen');
                        } else {
                          navigation.navigate('VendorSignupFormScreen');
                        }
                      } else {
                        if (Constants['user_ID']) {
                          navigation.navigate('UserDashboardScreen');
                        } else {
                          navigation.navigate('UserSignupFormScreen');
                        }
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'],
                      {
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 9,
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '45%' },
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Sign Up'}
                />
                {/* Button 3 */}
                <Button
                  onPress={() => {
                    try {
                      if (Constants['VendorAcc']) {
                        if (Constants['vendor_ID']) {
                          navigation.navigate('VendorDashboardScreen');
                        } else {
                          navigation.navigate('VendorLoginScreen');
                        }
                      } else {
                        if (Constants['user_ID']) {
                          navigation.navigate('UserDashboardScreen');
                        } else {
                          navigation.navigate('UserLoginScreen');
                        }
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'],
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Secondary'],
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 9,
                        },
                        marginLeft: { minWidth: Breakpoints.Laptop, value: 10 },
                        width: { minWidth: Breakpoints.Laptop, value: '45%' },
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Login'}
                />
              </View>
            </View>
            {/* Header Container Signed In */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                  height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'space-between',
                  },
                  width: { minWidth: Breakpoints.Laptop, value: '74%' },
                },
                dimensions.width
              )}
            >
              {/* Logo */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    width: { minWidth: Breakpoints.Laptop, value: '16.93%' },
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'contain'}
                  source={Images.BevyLogo}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '120%' },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                />
              </View>
              {/* Menu */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    height: { minWidth: Breakpoints.Laptop, value: '80%' },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'space-evenly',
                    },
                    width: { minWidth: Breakpoints.Laptop, value: '69.43%' },
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      if (Constants['VendorAcc']) {
                        navigation.navigate('VendorHomeScreen');
                      }
                      if (Constants['VendorAcc']) {
                        return;
                      }
                      navigation.navigate('UserHomeScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: {
                        minWidth: Breakpoints.Laptop,
                        value: 3,
                      },
                      borderColor: {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Strong Inverse'],
                      },
                      height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Primary'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Oswald_400Regular',
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            textTransform: {
                              minWidth: Breakpoints.Laptop,
                              value: 'uppercase',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Home'}
                    </Text>
                  </View>
                </Touchable>
                {/* Touchable 2 */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('PodcastScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: {
                        minWidth: Breakpoints.Laptop,
                        value: 3,
                      },
                      borderColor: {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Strong Inverse'],
                      },
                      height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Primary'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Oswald_400Regular',
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            textTransform: {
                              minWidth: Breakpoints.Laptop,
                              value: 'uppercase',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Podcasts'}
                    </Text>
                  </View>
                </Touchable>
                {/* Touchable 4 */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('MarketplaceScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: {
                        minWidth: Breakpoints.Laptop,
                        value: 3,
                      },
                      borderColor: {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Strong Inverse'],
                      },
                      height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Primary'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Oswald_400Regular',
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            textTransform: {
                              minWidth: Breakpoints.Laptop,
                              value: 'uppercase',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Marketplace'}
                    </Text>
                  </View>
                </Touchable>
                {/* Touchable 3 */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('ContactUsScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: {
                        minWidth: Breakpoints.Laptop,
                        value: 3,
                      },
                      borderColor: {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Strong Inverse'],
                      },
                      height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Primary'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Oswald_400Regular',
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            textTransform: {
                              minWidth: Breakpoints.Laptop,
                              value: 'uppercase',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'contact Us'}
                    </Text>
                  </View>
                </Touchable>
                {/* Touchable 5 */}
                <Touchable
                  onPress={() => {
                    try {
                      if (Constants['VendorAcc']) {
                        navigation.navigate('VendorDashboardScreen');
                      }
                      if (Constants['VendorAcc']) {
                        return;
                      }
                      if (Constants['Admin']) {
                        navigation.navigate('AdminDashboardScreen');
                      }
                      if (Constants['Admin']) {
                        return;
                      }
                      navigation.navigate('UserDashboardScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: {
                        minWidth: Breakpoints.Laptop,
                        value: 3,
                      },
                      borderColor: {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Secondary'],
                      },
                      height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Primary'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Oswald_400Regular',
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            textTransform: {
                              minWidth: Breakpoints.Laptop,
                              value: 'uppercase',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'My Account'}
                    </Text>
                  </View>
                </Touchable>
              </View>
              {/* Buttons or Icons */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-end',
                    },
                    minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                    width: { minWidth: Breakpoints.Laptop, value: '13.53%' },
                  },
                  dimensions.width
                )}
              >
                <Button
                  onPress={() => {
                    try {
                      if (Constants['VendorAcc'] || Constants['Admin']) {
                        navigation.navigate('VendorHomeScreen');
                      }
                      navigation.navigate('UserHomeScreen');
                      setGlobalVariableValue({
                        key: 'vendor_ID',
                        value: '',
                      });
                      setGlobalVariableValue({
                        key: 'user_ID',
                        value: '',
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'],
                      {
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 9,
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Logout'}
                />
              </View>
            </View>
          </View>
        )}
      </>
      <UpdateMobileHeader128Block />
      <KeyboardAwareScrollView
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
      >
        <>
          {!Constants['menuView'] ? null : (
            <UpdatedMobileMenuUserSignedInBlock />
          )}
        </>
        {/* Header */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: [
                {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors['Secondary'],
                },
                {
                  minWidth: Breakpoints.Mobile,
                  value: theme.colors['Secondary'],
                },
              ],
              flexDirection: 'row',
              height: 60,
              justifyContent: 'center',
              paddingLeft: 16,
              paddingRight: 16,
            },
            dimensions.width
          )}
        >
          {/* Back Click */}
          <>
            {dimensions.width >= Breakpoints.Laptop ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 48,
                    justifyContent: 'center',
                    width: 48,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      navigation.goBack();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Icon
                    color={theme.colors['Primary']}
                    name={'AntDesign/leftcircle'}
                    size={32}
                  />
                </Touchable>
              </View>
            )}
          </>
          {/* Screen Heading */}
          <Text
            style={StyleSheet.applyWidth(
              {
                color: [
                  {
                    minWidth: Breakpoints.Mobile,
                    value: theme.colors['Strong Inverse'],
                  },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors['Strong Inverse'],
                  },
                ],
                fontFamily: 'Lato_900Black',
                fontSize: 18,
              },
              dimensions.width
            )}
          >
            {'Manage User Account'}
          </Text>
        </View>
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: { minWidth: Breakpoints.Laptop, value: 'center' } },
            dimensions.width
          )}
        >
          <SocialBevyApi.FetchGetUserGET
            user_id={props.route?.params?.current_ID ?? ''}
          >
            {({ loading, error, data, refetchGetUser }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Secondary'],
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Secondary'],
                        },
                      ],
                      borderRadius: { minWidth: Breakpoints.Laptop, value: 12 },
                      height: { minWidth: Breakpoints.Desktop, value: 400 },
                      justifyContent: {
                        minWidth: Breakpoints.Desktop,
                        value: 'center',
                      },
                      marginTop: [
                        { minWidth: Breakpoints.Mobile, value: 20 },
                        { minWidth: Breakpoints.BigScreen, value: 100 },
                      ],
                      padding: 20,
                      paddingBottom: {
                        minWidth: Breakpoints.Laptop,
                        value: 30,
                      },
                      paddingTop: { minWidth: Breakpoints.Laptop, value: 30 },
                      width: { minWidth: Breakpoints.Laptop, value: '60%' },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Strong Inverse'],
                          },
                        ],
                        fontFamily: 'Lato_900Black',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 20 },
                          { minWidth: Breakpoints.Desktop, value: 28 },
                        ],
                        marginTop: 16,
                        opacity: 1,
                      },
                      dimensions.width
                    )}
                  >
                    {fetchData?.Fname} {fetchData?.Lname}
                  </Text>

                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'Oswald_700Bold',
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'Lato_400Regular',
                          },
                        ],
                        fontSize: [
                          { minWidth: Breakpoints.Desktop, value: 16 },
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Laptop, value: 18 },
                        ],
                        marginTop: 6,
                        opacity: [
                          { minWidth: Breakpoints.Mobile, value: 1 },
                          { minWidth: Breakpoints.Laptop, value: 1 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {'Bevy Points: 0'}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Strong Inverse'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                        ],
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'Lato_700Bold',
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'Lato_400Regular',
                          },
                        ],
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Desktop, value: 14 },
                        ],
                        marginTop: 6,
                        opacity: [
                          { minWidth: Breakpoints.Mobile, value: 1 },
                          { minWidth: Breakpoints.Laptop, value: 1 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {'Member Since: '}
                    {fetchData?.created_at}
                  </Text>
                </View>
              );
            }}
          </SocialBevyApi.FetchGetUserGET>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                minHeight: 50,
                width: { minWidth: Breakpoints.Laptop, value: '80%' },
              },
              dimensions.width
            )}
          >
            {/* View 4 */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', justifyContent: 'center' },
                dimensions.width
              )}
            >
              <Button
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'],
                    { borderRadius: 12, margin: 15, width: 200 }
                  ),
                  dimensions.width
                )}
                title={'Return to Dashboard'}
              />
            </View>
            {/* View 5 */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', justifyContent: 'center' },
                dimensions.width
              )}
            >
              <Button
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'],
                    { borderRadius: 12, margin: 15, width: 200 }
                  ),
                  dimensions.width
                )}
                title={'Manage Profile Details'}
              />
            </View>
            {/* View 6 */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', justifyContent: 'center' },
                dimensions.width
              )}
            >
              <Button
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'],
                    {
                      backgroundColor: theme.colors['Secondary'],
                      borderRadius: 12,
                      margin: 15,
                      width: 200,
                    }
                  ),
                  dimensions.width
                )}
                title={'Delete Account'}
              />
            </View>
          </View>
          {/* View 3 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Secondary'],
                flexDirection: 'row',
                justifyContent: 'center',
                minHeight: 50,
                width: { minWidth: Breakpoints.Laptop, value: '80%' },
              },
              dimensions.width
            )}
          >
            {/* View 4 */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', justifyContent: 'center' },
                dimensions.width
              )}
            >
              <SocialBevyApi.FetchLocationCountGET
                vendor_id={props.route?.params?.current_ID ?? ''}
              >
                {({ loading, error, data, refetchLocationCount }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Lato_400Regular',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Purchases: 0'}
                    </Text>
                  );
                }}
              </SocialBevyApi.FetchLocationCountGET>
            </View>
          </View>
          {/* Actions */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: [
                  { minWidth: Breakpoints.Mobile, value: 'column' },
                  { minWidth: Breakpoints.Laptop, value: 'row' },
                ],
                justifyContent: [
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                  { minWidth: Breakpoints.Laptop, value: 'space-evenly' },
                ],
                marginTop: 20,
                paddingLeft: 20,
                paddingRight: 20,
                width: { minWidth: Breakpoints.Laptop, value: '80%' },
              },
              dimensions.width
            )}
          ></View>
        </View>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(ManageAccountUserScreen);
