import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { IconButton, withTheme } from '@draftbit/ui';
import { Image, View } from 'react-native';

const UpdateMobileHeader128Block = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          backgroundColor: theme.colors['Background'],
          borderBottomWidth: 0.5,
          flexDirection: 'row',
          height: 100,
          justifyContent: 'space-between',
          marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
          paddingLeft: 16,
          paddingRight: 16,
        },
        dimensions.width
      )}
    >
      <View>
        <Image
          resizeMode={'contain'}
          source={Images.BevyLogo}
          style={StyleSheet.applyWidth(
            GlobalStyles.ImageStyles(theme)['Image'],
            dimensions.width
          )}
        />
      </View>
      {/* View 2 */}
      <View
        style={StyleSheet.applyWidth(
          { flexDirection: 'row', zIndex: 1 },
          dimensions.width
        )}
      >
        {/* Icon Button 2 */}
        <>
          {Constants['menuView'] ? null : (
            <IconButton
              icon={'Feather/menu'}
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'menuView',
                    value: true,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={38}
              style={StyleSheet.applyWidth(
                { marginRight: 5 },
                dimensions.width
              )}
            />
          )}
        </>
        {/* Icon Button 3 */}
        <>
          {!Constants['menuView'] ? null : (
            <IconButton
              icon={'AntDesign/closecircleo'}
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'menuView',
                    value: false,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={38}
              style={StyleSheet.applyWidth(
                { marginRight: 5 },
                dimensions.width
              )}
            />
          )}
        </>
      </View>
    </View>
  );
};

export default withTheme(UpdateMobileHeader128Block);
