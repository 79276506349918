export default {
  BevyLogo: require('../assets/images/BevyLogo.png'),
  AdobeStock139060989Preview: require('../assets/images/AdobeStock139060989Preview.jpeg'),
  BevyBee: require('../assets/images/BevyBee.png'),
  QueenBeeB: require('../assets/images/QueenBeeB.png'),
  AdobeStock303448308Preview1: require('../assets/images/AdobeStock303448308Preview(1).jpeg'),
  AdobeStock601595550Preview2: require('../assets/images/AdobeStock601595550Preview2.jpg'),
  AdobeStock174831910Preview: require('../assets/images/AdobeStock174831910Preview.jpeg'),
  AdobeStock174831910Previewe: require('../assets/images/AdobeStock174831910Previewe.jpg'),
  AdobeStock628674753Preview1: require('../assets/images/AdobeStock628674753Preview(1).jpeg'),
  AdobeStock351549414Preview: require('../assets/images/AdobeStock351549414Preview.jpeg'),
  AdobeStock351549414Previewe: require('../assets/images/AdobeStock351549414Previewe.jpg'),
  AdobeStock309015651Preview: require('../assets/images/AdobeStock309015651Preview.jpeg'),
  AdobeStock236909464Preview: require('../assets/images/AdobeStock236909464Preview.jpeg'),
  AdobeStock438035062Preview: require('../assets/images/AdobeStock438035062Preview.jpeg'),
  Sky: require('../assets/images/Sky.jpg'),
  BevyWhite: require('../assets/images/BevyWhite.png'),
  BevyRedwhite: require('../assets/images/BevyRedwhite.png'),
  D3aee0ddA5c04816Af8dE8311680987e: require('../assets/images/D3aee0ddA5c04816Af8dE8311680987e.jpeg'),
  Hero6: require('../assets/images/Hero6.jpg'),
  AdobeStock636164018Preview: require('../assets/images/AdobeStock636164018Preview.jpeg'),
  Hero2: require('../assets/images/Hero2.jpg'),
  AdobeStock622378708Preview: require('../assets/images/AdobeStock622378708Preview.jpeg'),
  Image: require('../assets/images/Image.png'),
  Camera: require('../assets/images/Camera.png'),
  Done: require('../assets/images/Done.png'),
  Location: require('../assets/images/Location.png'),
  Payoneer: require('../assets/images/Payoneer.png'),
  TransferWise: require('../assets/images/TransferWise.png'),
  Cash: require('../assets/images/Cash.png'),
  Gps: require('../assets/images/Gps.png'),
  DatingIconColor: require('../assets/images/DatingIconColor.png'),
  AdobeStock626626201Preview: require('../assets/images/AdobeStock626626201Preview.jpeg'),
  AdobeStock627315392Preview: require('../assets/images/AdobeStock627315392Preview.jpeg'),
  AdobeStock429147814: require('../assets/images/AdobeStock429147814.jpeg'),
  MedDelivered: require('../assets/images/MedDelivered.png'),
  UserImage: require('../assets/images/UserImage.png'),
  BxVideoRecording1: require('../assets/images/BxVideoRecording1.png'),
  BxsHot1: require('../assets/images/BxsHot1.png'),
  BxsBowlRice1: require('../assets/images/BxsBowlRice1.png'),
  BxDumbbell: require('../assets/images/BxDumbbell.png'),
  AdobeStock300144034Preview1: require('../assets/images/AdobeStock300144034Preview(1).jpeg'),
  AdobeStock438035062: require('../assets/images/AdobeStock438035062.jpeg'),
  AdobeStock345059232: require('../assets/images/AdobeStock345059232.jpeg'),
  Bg04: require('../assets/images/Bg04.jpg'),
  BevyBee1w2: require('../assets/images/BevyBee(1)w2.png'),
  AdobeStock300144034: require('../assets/images/AdobeStock300144034.jpeg'),
  AdobeStock622378708: require('../assets/images/AdobeStock622378708.jpeg'),
  AdobeStock626626201: require('../assets/images/AdobeStock626626201.jpeg'),
  Channels4Banner: require('../assets/images/Channels4Banner.jpg'),
};
