/**
 * Learn more about deep linking with React Navigation.
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from "expo-linking";
import { Platform } from "react-native";
function renderLinkingPrefix() {
  try {
    return Linking.createURL("/");
  } catch (e) {
    return "draftbit://";
  }
}

const prefix = renderLinkingPrefix();
const linking = {
  prefixes: [prefix],
  conf: {
    AdminDashboardScreen:"/AdminDashboard/:escape?",
    AdminInfluencerDetailsScreen:"/AdminInfluencerDetails/:escape?",
    AdminInfluencerSignupFormScreen:"/AdminInfluencerSignupForm/:escape?",
    AdminOfferManagementScreen:"/AdminOfferManagement/:escape?",
    AdminUserDetailsScreen:"/AdminUserDetails/:escape?",
    AdminUserSignupFormScreen:"/AdminUserSignup Form/:escape?",
    AdminVendorDetailsScreen:"/AdminVendorDetails/:escape?",
    AdminVendorSignupScreen:"/AdminVendorSignup/:escape?",
    ContactUsScreen:"ContactUs/:escape?",
    CreateBusinessLocationScreen:"CreateBusinessLocation/:escape?",
    CreateanOfferScreen:"CreateanOffer/:escape?",
    EmailVerificationInfluencerScreen:"EmailVerificationInfluencer/:escape?",
    EmailVerificationUserScreen:"EmailVerificationUser/:escape?",
    EmailVerificationVendorScreen:"EmailVerificationVendor/:escape?",
    InfluencerDashboardScreen:"InfluencerDashboard/:escape?",
    InfluencerLoginScreen:"InfluencerLogin/:escape?",
    InfluencerSignupFormScreen:"InfluencerSignupForm/:escape?",
    ManageAccountAdminScreen:"ManageAccountAdmin/:escape?",
    ManageAccountInfluencerScreen:"ManageAccountInfluencer/:escape?",
    ManageAccountUserScreen:"ManageAccountUser/:escape?",
    ManageAccountVendorScreen:"ManageAccountVendor/:escape?",
    ManageBusinessLocationScreen:"ManageBusinessLocation/:escape?",
    MarketplaceScreen:"Marketplace/:escape?",
    MarketplaceItemDetailsScreen:"MarketplaceItemDetails/:escape?",
    PaywallScreen:"Paywall/:escape?",
    PodcastScreen:"Podcast/:escape?",
    UserDashboardScreen:"UserDashboard/:escape?",
    UserHomeScreen:"UserHome/:escape?",
    UserLoginScreen:"UserLogin/:escape?",
    UserSignuFormScreen:"UserSignupForm/:escape?",
    VendorDashboardScreen:"VendorDashboard/:escape?",
    VendorHomeScreen:"VendorHome/:escape?",
    VendorLoginScreen:"VendorLogin/:escape?",
    VendorOfferDetailsScreen:"VendorOffer Details/:escape?",
    VendorOffersScreen:"VendorOffers/:escape?",
    VendorSignupFormScreen:"VendorSignupForm/:escape?",
    }
  }

export default linking;
