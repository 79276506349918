import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Surface, withTheme } from '@draftbit/ui';
import { Image, Text, View } from 'react-native';

const VendorInfoSurfaceBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <Surface
      style={StyleSheet.applyWidth(
        StyleSheet.compose(GlobalStyles.SurfaceStyles(theme)['Surface'], {
          alignItems: { minWidth: Breakpoints.Desktop, value: 'center' },
          backgroundColor: theme.colors['Primary'],
          borderRadius: 9,
          justifyContent: { minWidth: Breakpoints.Desktop, value: 'center' },
          marginBottom: 25,
          marginTop: 25,
          padding: 5,
          width: [
            { minWidth: Breakpoints.Mobile, value: '80%' },
            { minWidth: Breakpoints.Laptop, value: '60%' },
            { minWidth: Breakpoints.Tablet, value: '50%' },
            { minWidth: Breakpoints.Desktop, value: '30%' },
          ],
        }),
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: [
              { minWidth: Breakpoints.Mobile, value: 'center' },
              { minWidth: Breakpoints.Laptop, value: 'center' },
            ],
            backgroundColor: theme.colors['Background'],
            borderRadius: 9,
            height: { minWidth: Breakpoints.Desktop, value: 300 },
            justifyContent: [
              { minWidth: Breakpoints.Laptop, value: 'center' },
              { minWidth: Breakpoints.Desktop, value: 'center' },
            ],
            padding: 25,
            paddingBottom: { minWidth: Breakpoints.Laptop, value: 30 },
            paddingTop: { minWidth: Breakpoints.Laptop, value: 30 },
            width: { minWidth: Breakpoints.Laptop, value: '100%' },
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              marginBottom: 20,
              marginTop: 20,
              width: '100%',
            },
            dimensions.width
          )}
        >
          <Image
            resizeMode={'contain'}
            source={{ uri: `${props.logo ?? ''}` }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                height: 50,
                width: '70%',
              }),
              dimensions.width
            )}
          />
        </View>

        <Text
          style={StyleSheet.applyWidth(
            {
              color: [
                {
                  minWidth: Breakpoints.Mobile,
                  value: theme.colors['Secondary'],
                },
                {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors['Secondary'],
                },
              ],
              fontFamily: 'Lato_900Black',
              fontSize: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Desktop, value: 22 },
              ],
              opacity: 1,
            },
            dimensions.width
          )}
        >
          {props.vendorName ?? ''}
        </Text>

        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Secondary'],
              fontFamily: [
                { minWidth: Breakpoints.Laptop, value: 'Lato_700Bold' },
                { minWidth: Breakpoints.Mobile, value: 'Lato_400Regular' },
              ],
              fontSize: [
                { minWidth: Breakpoints.Desktop, value: 16 },
                { minWidth: Breakpoints.Mobile, value: 16 },
              ],
              marginTop: 10,
              opacity: [
                { minWidth: Breakpoints.Mobile, value: 1 },
                { minWidth: Breakpoints.Laptop, value: 1 },
              ],
              textAlign: 'center',
            },
            dimensions.width
          )}
        >
          {'Member Status: '}
          {props.status ?? ''}
        </Text>
        {/* Text 2 */}
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Medium'],
              fontFamily: [
                { minWidth: Breakpoints.Laptop, value: 'Lato_700Bold' },
                { minWidth: Breakpoints.Mobile, value: 'Lato_300Light' },
              ],
              fontSize: [
                { minWidth: Breakpoints.Desktop, value: 14 },
                { minWidth: Breakpoints.Mobile, value: 14 },
              ],
              marginBottom: 10,
              opacity: [
                { minWidth: Breakpoints.Mobile, value: 1 },
                { minWidth: Breakpoints.Laptop, value: 1 },
              ],
            },
            dimensions.width
          )}
        >
          {'Member Since: '}
          {props.date ?? ''}
        </Text>
      </View>
    </Surface>
  );
};

export default withTheme(VendorInfoSurfaceBlock);
