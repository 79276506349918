import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SocialBevyApi from '../apis/SocialBevyApi.js';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Divider, Touchable, withTheme } from '@draftbit/ui';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const VendorActiveStatusBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          backgroundColor: theme.colors['Secondary'],
          borderRadius: 9,
          flexDirection: 'row',
          justifyContent: 'center',
          minHeight: 50,
          paddingBottom: 10,
          paddingTop: 10,
          width: [
            { minWidth: Breakpoints.Mobile, value: '85%' },
            { minWidth: Breakpoints.Desktop, value: '50%' },
            { minWidth: Breakpoints.Laptop, value: '80%' },
          ],
        },
        dimensions.width
      )}
    >
      <Touchable
        onPress={() => {
          try {
            navigation.navigate('ManageBusinessLocationScreen');
          } catch (err) {
            console.error(err);
          }
        }}
      >
        {/* View 4 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '10%',
            },
            dimensions.width
          )}
        >
          <SocialBevyApi.FetchLocationCountGET vendor_id={15}>
            {({ loading, error, data, refetchLocationCount }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Strong Inverse'],
                      fontFamily: 'Lato_400Regular',
                      fontSize: 16,
                      textAlign: 'center',
                    }),
                    dimensions.width
                  )}
                >
                  {'Active Locations \n'}
                  {fetchData?.count}
                </Text>
              );
            }}
          </SocialBevyApi.FetchLocationCountGET>
        </View>
      </Touchable>
      <Divider
        color={theme.colors['Primary']}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.DividerStyles(theme)['Divider'], {
            minHeight: 40,
            width: 1,
          }),
          dimensions.width
        )}
      />
      {/* Touchable 2 */}
      <Touchable
        onPress={() => {
          try {
            navigation.navigate('VendorOffersScreen');
          } catch (err) {
            console.error(err);
          }
        }}
      >
        {/* View 3 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '10%',
            },
            dimensions.width
          )}
        >
          <SocialBevyApi.FetchActiveOfferCountGET vendor_id={15}>
            {({ loading, error, data, refetchActiveOfferCount }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Lato_400Regular',
                          fontSize: 16,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Active Offers\n'}
                    {fetchData?.active_offers}
                  </Text>
                </>
              );
            }}
          </SocialBevyApi.FetchActiveOfferCountGET>
        </View>
      </Touchable>
    </View>
  );
};

export default withTheme(VendorActiveStatusBlock);
