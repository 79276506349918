import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SocialBevyApi from '../apis/SocialBevyApi.js';
import UpdateMobileHeader128Block from '../components/UpdateMobileHeader128Block';
import UpdatedMobileMenuAdminSignedInBlock from '../components/UpdatedMobileMenuAdminSignedInBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  Divider,
  Icon,
  IconButton,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const AdminDashboardScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [allAdmin_view, setAllAdmin_view] = React.useState(false);
  const [allInfluencer_view, setAllInfluencer_view] = React.useState(false);
  const [allUser_view, setAllUser_view] = React.useState(false);
  const [allVendor_view, setAllVendor_view] = React.useState(false);
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'Admin',
        value: true,
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Custom Color'] },
        dimensions.width
      )}
    >
      {/* Desktop Menu Special 2 */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
                flexDirection: {
                  minWidth: Breakpoints.Laptop,
                  value: 'column',
                },
                height: { minWidth: Breakpoints.Laptop, value: 119.6 },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'center',
                },
                paddingBottom: { minWidth: Breakpoints.Laptop, value: 15 },
                paddingTop: { minWidth: Breakpoints.Laptop, value: 15 },
                width: {
                  minWidth: Breakpoints.Laptop,
                  value: dimensions.width,
                },
              },
              dimensions.width
            )}
          >
            {/* Header Container */}
            <>
              {Constants['user_ID'] || Constants['vendor_ID'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '55.9%' },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '27.06%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorSignupFormScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserSignupFormScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Sign Up'}
                    />
                    {/* Button 3 */}
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorLoginScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserLoginScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            backgroundColor: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Secondary'],
                            },
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            marginLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 10,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Login'}
                    />
                  </View>
                </View>
              )}
            </>
            {/* Header Container Signed In */}
            <>
              {!(Constants['user_ID'] || Constants['vendor_ID']) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '69.43%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 2 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('MarketplaceScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Marketplace'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 5 */}
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorDashboardScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          if (Constants['Admin']) {
                            navigation.navigate('AdminDashboardScreen');
                          }
                          if (Constants['Admin']) {
                            return;
                          }
                          navigation.navigate('UserDashboardScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Secondary'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'My Account'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '13.53%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          navigation.navigate('VendorHomeScreen');
                          setGlobalVariableValue({
                            key: 'vendor_ID',
                            value: '',
                          });
                          setGlobalVariableValue({
                            key: 'user_ID',
                            value: '',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Logout'}
                    />
                  </View>
                </View>
              )}
            </>
          </View>
        )}
      </>
      <UpdateMobileHeader128Block />
      <>
        {!Constants['menuView'] ? null : (
          <UpdatedMobileMenuAdminSignedInBlock />
        )}
      </>
      <ScrollView
        bounces={true}
        showsVerticalScrollIndicator={true}
        style={StyleSheet.applyWidth(
          { width: { minWidth: Breakpoints.Desktop, value: '100%' } },
          dimensions.width
        )}
      >
        {/* Header */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: [
                {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors['Secondary'],
                },
                {
                  minWidth: Breakpoints.Mobile,
                  value: theme.colors['Secondary'],
                },
              ],
              flexDirection: 'row',
              height: 48,
              justifyContent: 'center',
              paddingLeft: 16,
              paddingRight: 16,
            },
            dimensions.width
          )}
        >
          {/* Screen Heading */}
          <Text
            style={StyleSheet.applyWidth(
              {
                color: [
                  {
                    minWidth: Breakpoints.Mobile,
                    value: theme.colors['Strong Inverse'],
                  },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors['Strong Inverse'],
                  },
                ],
                fontFamily: 'Lato_900Black',
                fontSize: 18,
              },
              dimensions.width
            )}
          >
            {'Admin Dashboard'}
          </Text>
        </View>
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
              width: [
                { minWidth: Breakpoints.Desktop, value: '100%' },
                { minWidth: Breakpoints.Laptop, value: '100%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', padding: 20 },
              dimensions.width
            )}
          >
            <Surface
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.SurfaceStyles(theme)['Surface'],
                  { backgroundColor: theme.colors['Light'], borderRadius: 9 }
                ),
                dimensions.width
              )}
            >
              <Image
                resizeMode={'contain'}
                source={Images.BevyRedwhite}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderRadius: 9,
                    height: 150,
                    width: 150,
                  }),
                  dimensions.width
                )}
              />
            </Surface>

            <Text
              style={StyleSheet.applyWidth(
                {
                  color: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: theme.colors['Secondary'],
                    },
                    {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Secondary'],
                    },
                  ],
                  fontFamily: 'Lato_900Black',
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Desktop, value: 22 },
                  ],
                  marginTop: 16,
                  opacity: 1,
                },
                dimensions.width
              )}
            >
              {'Social Bevy Admin User'}
            </Text>

            <SocialBevyApi.FetchGetVendorGET
              vendors_id={Constants['vendor_ID']}
            >
              {({ loading, error, data, refetchGetVendor }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    {/* Text 2 */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: theme.colors['Secondary'],
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Secondary'],
                            },
                          ],
                          fontFamily: 'Lato_700Bold',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 20 },
                            { minWidth: Breakpoints.Desktop, value: 22 },
                          ],
                          marginTop: 16,
                          opacity: 1,
                        },
                        dimensions.width
                      )}
                    >
                      {fetchData?.Fname} {fetchData?.Lname}
                    </Text>
                  </>
                );
              }}
            </SocialBevyApi.FetchGetVendorGET>
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Secondary'],
                  fontFamily: [
                    { minWidth: Breakpoints.Laptop, value: 'Lato_700Bold' },
                    { minWidth: Breakpoints.Mobile, value: 'Lato_300Light' },
                  ],
                  fontSize: [
                    { minWidth: Breakpoints.Desktop, value: 16 },
                    { minWidth: Breakpoints.Mobile, value: 18 },
                  ],
                  marginTop: 6,
                  opacity: [
                    { minWidth: Breakpoints.Mobile, value: 1 },
                    { minWidth: Breakpoints.Laptop, value: 1 },
                  ],
                },
                dimensions.width
              )}
            >
              {'Member Status: Admin'}
            </Text>
          </View>

          <Surface
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.SurfaceStyles(theme)['Surface'], {
                alignItems: 'center',
                backgroundColor: theme.colors['Divider'],
                borderRadius: 9,
                justifyContent: 'center',
                marginBottom: 20,
                marginLeft: 25,
                marginRight: 25,
                padding: 10,
              }),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors['Divider'],
                  borderRadius: { minWidth: Breakpoints.Laptop, value: 12 },
                  justifyContent: {
                    minWidth: Breakpoints.Desktop,
                    value: 'center',
                  },
                  marginTop: { minWidth: Breakpoints.BigScreen, value: 100 },
                  paddingTop: { minWidth: Breakpoints.Laptop, value: 30 },
                  width: [
                    { minWidth: Breakpoints.Laptop, value: '70%' },
                    { minWidth: Breakpoints.Mobile, value: '90%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Stats */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: [
                      { minWidth: Breakpoints.Mobile, value: 'column' },
                      { minWidth: Breakpoints.Laptop, value: 'row' },
                    ],
                    justifyContent: 'space-around',
                    margin: 20,
                    paddingBottom: { minWidth: Breakpoints.Laptop, value: 20 },
                    paddingLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
                    paddingRight: {
                      minWidth: Breakpoints.Laptop,
                      value: '10%',
                    },
                    paddingTop: { minWidth: Breakpoints.Laptop, value: 20 },
                    width: [
                      { minWidth: Breakpoints.Laptop, value: '100%' },
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* View 4 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Mobile, value: 'center' },
                        { minWidth: Breakpoints.Laptop, value: 'center' },
                      ],
                      borderRadius: 8,
                      flexDirection: [
                        { minWidth: Breakpoints.Mobile, value: 'row' },
                        { minWidth: Breakpoints.Laptop, value: 'column' },
                      ],
                      justifyContent: 'space-between',
                      marginBottom: 5,
                      marginTop: 5,
                      paddingLeft: '10%',
                      paddingRight: '10%',
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '85%' },
                        { minWidth: Breakpoints.Laptop, value: '18%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Strong Inverse'],
                        borderRadius: 8,
                        height: 50,
                        justifyContent: 'center',
                        width: 50,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Icon 3 */}
                    <Icon
                      color={theme.colors['Primary']}
                      name={'FontAwesome/user-circle-o'}
                      size={24}
                    />
                  </View>

                  <SocialBevyApi.FetchUserCountGET>
                    {({ loading, error, data, refetchUserCount }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'flex-start',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              justifyContent: 'center',
                              marginRight: [
                                { minWidth: Breakpoints.Mobile, value: 26 },
                                { minWidth: Breakpoints.Laptop, value: 0 },
                              ],
                              width: [
                                { minWidth: Breakpoints.Laptop, value: 120 },
                                { minWidth: Breakpoints.Mobile, value: '85%' },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Secondary'],
                                fontFamily: 'Lato_400Regular',
                                fontSize: [
                                  { minWidth: Breakpoints.Laptop, value: 14 },
                                  { minWidth: Breakpoints.Mobile, value: 14 },
                                ],
                                marginLeft: 6,
                                opacity: [
                                  { minWidth: Breakpoints.Laptop, value: 1 },
                                  { minWidth: Breakpoints.Mobile, value: 1 },
                                ],
                                textAlign: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              },
                              dimensions.width
                            )}
                          >
                            {'Active Users'}
                          </Text>

                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Secondary'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 21,
                                marginLeft: 6,
                                marginTop: 4,
                              },
                              dimensions.width
                            )}
                          >
                            {fetchData?.userCount}
                          </Text>
                        </View>
                      );
                    }}
                  </SocialBevyApi.FetchUserCountGET>
                </View>
                {/* View 5 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Mobile, value: 'center' },
                        { minWidth: Breakpoints.Laptop, value: 'center' },
                      ],
                      borderRadius: 8,
                      flexDirection: [
                        { minWidth: Breakpoints.Mobile, value: 'row' },
                        { minWidth: Breakpoints.Laptop, value: 'column' },
                      ],
                      justifyContent: 'space-between',
                      marginBottom: 5,
                      marginTop: 5,
                      paddingLeft: '10%',
                      paddingRight: '10%',
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '85%' },
                        { minWidth: Breakpoints.Laptop, value: '18%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Strong Inverse'],
                        borderRadius: 8,
                        height: 50,
                        justifyContent: 'center',
                        width: 50,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Icon 3 */}
                    <Icon
                      color={theme.colors['Primary']}
                      name={'Ionicons/ios-business'}
                      size={24}
                    />
                  </View>

                  <SocialBevyApi.FetchVendorCountGET>
                    {({ loading, error, data, refetchVendorCount }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'flex-start',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              justifyContent: 'center',
                              marginRight: [
                                { minWidth: Breakpoints.Mobile, value: 26 },
                                { minWidth: Breakpoints.Laptop, value: 0 },
                              ],
                              width: [
                                { minWidth: Breakpoints.Laptop, value: 120 },
                                { minWidth: Breakpoints.Mobile, value: '85%' },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Secondary'],
                                fontFamily: 'Lato_400Regular',
                                fontSize: [
                                  { minWidth: Breakpoints.Laptop, value: 14 },
                                  { minWidth: Breakpoints.Mobile, value: 14 },
                                ],
                                marginLeft: 6,
                                opacity: [
                                  { minWidth: Breakpoints.Laptop, value: 1 },
                                  { minWidth: Breakpoints.Mobile, value: 1 },
                                ],
                                textAlign: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              },
                              dimensions.width
                            )}
                          >
                            {'Active Vendors'}
                          </Text>

                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Secondary'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 21,
                                marginLeft: 6,
                                marginTop: 4,
                              },
                              dimensions.width
                            )}
                          >
                            {fetchData?.vendorCount}
                          </Text>
                        </View>
                      );
                    }}
                  </SocialBevyApi.FetchVendorCountGET>
                </View>
                {/* View 6 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Mobile, value: 'center' },
                        { minWidth: Breakpoints.Laptop, value: 'center' },
                      ],
                      borderRadius: 8,
                      flexDirection: [
                        { minWidth: Breakpoints.Mobile, value: 'row' },
                        { minWidth: Breakpoints.Laptop, value: 'column' },
                      ],
                      justifyContent: 'space-between',
                      marginBottom: 5,
                      marginTop: 5,
                      paddingLeft: '10%',
                      paddingRight: '10%',
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '85%' },
                        { minWidth: Breakpoints.Laptop, value: '18%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Strong Inverse'],
                        borderRadius: 8,
                        height: 50,
                        justifyContent: 'center',
                        width: 50,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Icon 3 */}
                    <Icon
                      color={theme.colors['Primary']}
                      name={'MaterialIcons/star'}
                      size={24}
                    />
                  </View>

                  <SocialBevyApi.FetchGetInfluencerCountGET>
                    {({ loading, error, data, refetchGetInfluencerCount }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'flex-start',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              justifyContent: 'center',
                              marginRight: [
                                { minWidth: Breakpoints.Mobile, value: 26 },
                                { minWidth: Breakpoints.Laptop, value: 0 },
                              ],
                              width: [
                                { minWidth: Breakpoints.Laptop, value: 120 },
                                { minWidth: Breakpoints.Mobile, value: '85%' },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Secondary'],
                                fontFamily: 'Lato_400Regular',
                                fontSize: [
                                  { minWidth: Breakpoints.Laptop, value: 14 },
                                  { minWidth: Breakpoints.Mobile, value: 14 },
                                ],
                                marginLeft: 6,
                                opacity: [
                                  { minWidth: Breakpoints.Laptop, value: 1 },
                                  { minWidth: Breakpoints.Mobile, value: 1 },
                                ],
                                textAlign: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              },
                              dimensions.width
                            )}
                          >
                            {'Active Influencers'}
                          </Text>

                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Secondary'],
                                fontFamily: 'Inter_500Medium',
                                fontSize: 21,
                                marginLeft: 6,
                                marginTop: 4,
                              },
                              dimensions.width
                            )}
                          >
                            {fetchData?.influencer_count}
                          </Text>
                        </View>
                      );
                    }}
                  </SocialBevyApi.FetchGetInfluencerCountGET>
                </View>
              </View>
            </View>
          </Surface>
          {/* View 3 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'flex-end',
                backgroundColor: theme.colors['Secondary'],
                flexDirection: 'row',
                justifyContent: 'space-around',
                minHeight: 50,
                width: { minWidth: Breakpoints.Laptop, value: '80%' },
              },
              dimensions.width
            )}
          >
            {/* View 4 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 5,
                  marginTop: 10,
                  width: '45%',
                },
                dimensions.width
              )}
            >
              <>
                {allUser_view ? null : (
                  <Button
                    onPress={() => {
                      try {
                        if (allVendor_view === false) {
                          setAllUser_view(true);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: 8,
                          },
                          width: '100%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'View Users'}
                  />
                )}
              </>
              <>
                {!allUser_view ? null : (
                  <IconButton
                    icon={'AntDesign/closecircle'}
                    onPress={() => {
                      try {
                        setAllUser_view(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={32}
                  />
                )}
              </>
            </View>
            {/* View 3 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 5,
                  marginTop: 10,
                  width: '45%',
                },
                dimensions.width
              )}
            >
              <>
                {allVendor_view ? null : (
                  <Button
                    onPress={() => {
                      try {
                        if (allUser_view === false) {
                          setAllVendor_view(true);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: 8,
                          },
                          width: '100%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'View Vendors'}
                  />
                )}
              </>
              {/* Icon Button 2 */}
              <>
                {!allVendor_view ? null : (
                  <IconButton
                    icon={'AntDesign/closecircle'}
                    onPress={() => {
                      try {
                        setAllVendor_view(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={32}
                  />
                )}
              </>
            </View>
          </View>
          {/* View 4 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'flex-start',
                backgroundColor: theme.colors['Secondary'],
                flexDirection: 'row',
                justifyContent: 'space-around',
                minHeight: 50,
                width: { minWidth: Breakpoints.Laptop, value: '80%' },
              },
              dimensions.width
            )}
          >
            {/* View 4 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 10,
                  marginTop: 5,
                  width: '45%',
                },
                dimensions.width
              )}
            >
              <>
                {allInfluencer_view ? null : (
                  <Button
                    onPress={() => {
                      try {
                        if (
                          ((allVendor_view === false && allUser_view) ===
                            false && allAdmin_view) === false
                        ) {
                          setAllInfluencer_view(true);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: 8,
                          },
                          width: '100%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'View Influencers'}
                  />
                )}
              </>
              <>
                {!allInfluencer_view ? null : (
                  <IconButton
                    icon={'AntDesign/closecircle'}
                    onPress={() => {
                      try {
                        setAllInfluencer_view(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={32}
                  />
                )}
              </>
            </View>
            {/* View 3 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 10,
                  marginTop: 5,
                  width: '45%',
                },
                dimensions.width
              )}
            >
              <>
                {allAdmin_view ? null : (
                  <Button
                    onPress={() => {
                      try {
                        if (allVendor_view === false) {
                          setAllAdmin_view(true);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: 8,
                          },
                          width: '100%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'View Admins'}
                  />
                )}
              </>
              {/* Icon Button 2 */}
              <>
                {!allAdmin_view ? null : (
                  <IconButton
                    icon={'AntDesign/closecircle'}
                    onPress={() => {
                      try {
                        setAllAdmin_view(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={32}
                  />
                )}
              </>
            </View>
          </View>

          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            {/* All Vendors */}
            <>
              {!allVendor_view ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['Divider'],
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginTop: 10,
                      minHeight: 50,
                      width: { minWidth: Breakpoints.Laptop, value: '80%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Tablet and Above */}
                  <>
                    {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                      <SocialBevyApi.FetchGetAllVendorsGET>
                        {({ loading, error, data, refetchGetAllVendors }) => {
                          const tabletAndAboveData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <FlatList
                              data={tabletAndAboveData?.vendors}
                              keyExtractor={listData =>
                                listData?.id ||
                                listData?.uuid ||
                                JSON.stringify(listData)
                              }
                              listKey={'pyDJqURg'}
                              numColumns={2}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'AdminVendorDetailsScreen',
                                          { vendorID_Pass: listData?.id }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      {
                                        width: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '100%',
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: '50%',
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Surface 2 */}
                                      <Surface
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.SurfaceStyles(theme)[
                                              'Surface'
                                            ],
                                            {
                                              borderRadius: 9,
                                              marginBottom: 5,
                                              width: '95%',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {/* View 4 */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              height: 140,
                                              justifyContent: 'center',
                                              marginBottom: {
                                                minWidth: Breakpoints.Tablet,
                                                value: 5,
                                              },
                                              marginTop: {
                                                minWidth: Breakpoints.Tablet,
                                                value: 5,
                                              },
                                              width: '90%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 'center',
                                                },
                                                marginRight: 20,
                                                width: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: '30%',
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Image
                                              resizeMode={'contain'}
                                              source={{
                                                uri: `${listData?.Logo?.url}`,
                                              }}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.ImageStyles(
                                                    theme
                                                  )['Image'],
                                                  { height: 110, width: 110 }
                                                ),
                                                dimensions.width
                                              )}
                                            />
                                          </View>
                                          {/* View 2 */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                justifyContent: 'center',
                                                width: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: '50%',
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Oswald_400Regular',
                                                    fontSize: 16,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.Fname}{' '}
                                              {listData?.Lname}{' '}
                                            </Text>
                                            {/* Text 3 */}
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    color:
                                                      theme.colors['Primary'],
                                                    fontFamily: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value:
                                                          'Lato_400Regular',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Tablet,
                                                        value: 'Lato_700Bold',
                                                      },
                                                    ],
                                                    marginTop: 10,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.business_name}
                                            </Text>
                                            {/* Text 4 */}
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Lato_400Regular',
                                                    marginTop: {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 10,
                                                    },
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'Plan Type: '}
                                              {listData?.Membership_plan}
                                            </Text>
                                            {/* Text 6 */}
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Lato_400Regular',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'Status: Active'}
                                            </Text>
                                          </View>
                                        </View>
                                      </Surface>
                                    </View>
                                  </Touchable>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                              style={StyleSheet.applyWidth(
                                {
                                  width: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '100%',
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: '100%',
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            />
                          );
                        }}
                      </SocialBevyApi.FetchGetAllVendorsGET>
                    )}
                  </>
                  {/* Mobile */}
                  <>
                    {dimensions.width >= Breakpoints.Tablet ? null : (
                      <SocialBevyApi.FetchGetAllVendorsGET>
                        {({ loading, error, data, refetchGetAllVendors }) => {
                          const mobileData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <FlatList
                              data={mobileData?.vendors}
                              keyExtractor={listData =>
                                listData?.id ||
                                listData?.uuid ||
                                JSON.stringify(listData)
                              }
                              listKey={'biHoLJsB'}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'AdminVendorDetailsScreen',
                                          { vendorID_Pass: listData?.id }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      {
                                        width: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '100%',
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: '50%',
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Surface 2 */}
                                      <Surface
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.SurfaceStyles(theme)[
                                              'Surface'
                                            ],
                                            {
                                              borderRadius: 9,
                                              marginBottom: 5,
                                              width: '95%',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {/* View 4 */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              height: 140,
                                              justifyContent: 'center',
                                              marginBottom: {
                                                minWidth: Breakpoints.Tablet,
                                                value: 5,
                                              },
                                              marginTop: {
                                                minWidth: Breakpoints.Tablet,
                                                value: 5,
                                              },
                                              width: '90%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 'center',
                                                },
                                                marginRight: 20,
                                                width: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: '30%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: '30%',
                                                  },
                                                ],
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Image
                                              resizeMode={'contain'}
                                              source={{
                                                uri: `${listData?.Logo?.url}`,
                                              }}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.ImageStyles(
                                                    theme
                                                  )['Image'],
                                                  {
                                                    borderColor:
                                                      theme.colors['Divider'],
                                                    borderRadius: 9,
                                                    borderWidth: 0.5,
                                                    height: 110,
                                                    width: 110,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            />
                                          </View>
                                          {/* View 2 */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                justifyContent: 'center',
                                                width: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: '50%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: '50%',
                                                  },
                                                ],
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Oswald_400Regular',
                                                    fontSize: 16,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.Fname}{' '}
                                              {listData?.Lname}{' '}
                                            </Text>
                                            {/* Text 3 */}
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    color:
                                                      theme.colors['Primary'],
                                                    fontFamily: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Tablet,
                                                        value: 'Lato_700Bold',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: 'Lato_700Bold',
                                                      },
                                                    ],
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.business_name}
                                            </Text>
                                            {/* Text 4 */}
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Lato_400Regular',
                                                    marginTop: {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 10,
                                                    },
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'Plan Type: '}
                                              {listData?.Membership_plan}
                                            </Text>
                                            {/* Text 6 */}
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Lato_400Regular',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'Status: Active'}
                                            </Text>
                                          </View>
                                        </View>
                                      </Surface>
                                    </View>
                                  </Touchable>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                              style={StyleSheet.applyWidth(
                                {
                                  width: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '100%',
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: '100%',
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            />
                          );
                        }}
                      </SocialBevyApi.FetchGetAllVendorsGET>
                    )}
                  </>
                </View>
              )}
            </>
            {/* All Users */}
            <>
              {!allUser_view ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginTop: 10,
                      minHeight: [
                        { minWidth: Breakpoints.Mobile, value: 50 },
                        { minWidth: Breakpoints.Laptop, value: 80 },
                      ],
                      width: { minWidth: Breakpoints.Laptop, value: '80%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Tablet and Above */}
                  <>
                    {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                      <SocialBevyApi.FetchGetAllUsersGET>
                        {({ loading, error, data, refetchGetAllUsers }) => {
                          const tabletAndAboveData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <FlatList
                              data={tabletAndAboveData?.user}
                              keyExtractor={listData =>
                                listData?.id ||
                                listData?.uuid ||
                                JSON.stringify(listData)
                              }
                              listKey={'na7W20aD'}
                              numColumns={2}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'AdminUserDetailsScreen',
                                          { userID_Pass: listData?.id }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      {
                                        width: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '100%',
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: '50%',
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Surface 2 */}
                                      <Surface
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.SurfaceStyles(theme)[
                                              'Surface'
                                            ],
                                            {
                                              borderRadius: 9,
                                              marginBottom: 5,
                                              width: '95%',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {/* View 4 */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              height: 140,
                                              justifyContent: 'center',
                                              marginBottom: {
                                                minWidth: Breakpoints.Tablet,
                                                value: 5,
                                              },
                                              marginTop: {
                                                minWidth: Breakpoints.Tablet,
                                                value: 5,
                                              },
                                              width: '90%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 'center',
                                                },
                                                marginRight: 20,
                                                width: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: '30%',
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Image
                                              resizeMode={'contain'}
                                              source={{
                                                uri: `${listData?.image?.url}`,
                                              }}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.ImageStyles(
                                                    theme
                                                  )['Image'],
                                                  {
                                                    borderColor:
                                                      theme.colors['Divider'],
                                                    borderRadius: 9,
                                                    borderWidth: 0.5,
                                                    height: 110,
                                                    width: 110,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            />
                                          </View>
                                          {/* View 2 */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                justifyContent: 'center',
                                                width: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: '50%',
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Oswald_400Regular',
                                                    fontSize: 16,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.Fname}{' '}
                                              {listData?.Lname}{' '}
                                            </Text>
                                            {/* Text 3 */}
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    color:
                                                      theme.colors['Primary'],
                                                    fontFamily: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value:
                                                          'Lato_400Regular',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Tablet,
                                                        value: 'Lato_700Bold',
                                                      },
                                                    ],
                                                    marginTop: 10,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.email}
                                            </Text>
                                            {/* Text 6 */}
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Lato_400Regular',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'Status: Active'}
                                            </Text>
                                          </View>
                                        </View>
                                      </Surface>
                                    </View>
                                  </Touchable>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                              style={StyleSheet.applyWidth(
                                {
                                  width: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '100%',
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: '100%',
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            />
                          );
                        }}
                      </SocialBevyApi.FetchGetAllUsersGET>
                    )}
                  </>
                  {/* Mobile */}
                  <>
                    {dimensions.width >= Breakpoints.Tablet ? null : (
                      <SocialBevyApi.FetchGetAllUsersGET>
                        {({ loading, error, data, refetchGetAllUsers }) => {
                          const mobileData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <FlatList
                              data={mobileData?.user}
                              keyExtractor={listData =>
                                listData?.id ||
                                listData?.uuid ||
                                JSON.stringify(listData)
                              }
                              listKey={'1q2db9Fg'}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'AdminUserDetailsScreen',
                                          { userID_Pass: listData?.id }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      {
                                        width: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '100%',
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: '50%',
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Surface
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.SurfaceStyles(theme)[
                                              'Surface'
                                            ],
                                            {
                                              borderRadius: 9,
                                              marginBottom: 5,
                                              width: '95%',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {/* View 4 */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              height: 140,
                                              justifyContent: 'center',
                                              marginBottom: {
                                                minWidth: Breakpoints.Tablet,
                                                value: 5,
                                              },
                                              marginTop: {
                                                minWidth: Breakpoints.Tablet,
                                                value: 5,
                                              },
                                              width: '100%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 'center',
                                                },
                                                marginRight: 20,
                                                width: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: '30%',
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Image
                                              resizeMode={'contain'}
                                              source={{
                                                uri: `${listData?.image?.url}`,
                                              }}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.ImageStyles(
                                                    theme
                                                  )['Image'],
                                                  {
                                                    borderColor:
                                                      theme.colors['Divider'],
                                                    borderRadius: 9,
                                                    borderWidth: 0.5,
                                                    height: 110,
                                                    width: 110,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            />
                                          </View>
                                          {/* View 2 */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                justifyContent: 'center',
                                                width: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: '50%',
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Oswald_400Regular',
                                                    fontSize: 16,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.Fname}{' '}
                                              {listData?.Lname}{' '}
                                            </Text>
                                            {/* Text 3 */}
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    color:
                                                      theme.colors['Primary'],
                                                    fontFamily: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value:
                                                          'Lato_400Regular',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Tablet,
                                                        value: 'Lato_700Bold',
                                                      },
                                                    ],
                                                    marginTop: 10,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.email}
                                            </Text>
                                            {/* Text 6 */}
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Lato_400Regular',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'Status: Active'}
                                            </Text>
                                          </View>
                                        </View>
                                      </Surface>
                                    </View>
                                  </Touchable>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                              style={StyleSheet.applyWidth(
                                {
                                  width: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '100%',
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: '100%',
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            />
                          );
                        }}
                      </SocialBevyApi.FetchGetAllUsersGET>
                    )}
                  </>
                </View>
              )}
            </>
            {/* All Influencers */}
            <>
              {!allInfluencer_view ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginTop: 10,
                      minHeight: [
                        { minWidth: Breakpoints.Mobile, value: 50 },
                        { minWidth: Breakpoints.Laptop, value: 80 },
                      ],
                      width: { minWidth: Breakpoints.Laptop, value: '80%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Tablet and Above */}
                  <>
                    {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                      <SocialBevyApi.FetchGetAllInfluencersGET>
                        {({
                          loading,
                          error,
                          data,
                          refetchGetAllInfluencers,
                        }) => {
                          const tabletAndAboveData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <FlatList
                              data={tabletAndAboveData}
                              keyExtractor={listData =>
                                listData?.id ||
                                listData?.uuid ||
                                JSON.stringify(listData)
                              }
                              listKey={'8FjzbTJC'}
                              numColumns={2}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'AdminInfluencerDetailsScreen',
                                          { influID_Pass: listData?.id }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      {
                                        width: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '100%',
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: '50%',
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Surface 2 */}
                                      <Surface
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.SurfaceStyles(theme)[
                                              'Surface'
                                            ],
                                            {
                                              borderRadius: 9,
                                              marginBottom: 5,
                                              width: '95%',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {/* View 4 */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              height: 140,
                                              justifyContent: 'center',
                                              marginBottom: {
                                                minWidth: Breakpoints.Tablet,
                                                value: 5,
                                              },
                                              marginTop: {
                                                minWidth: Breakpoints.Tablet,
                                                value: 5,
                                              },
                                              width: '90%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 'center',
                                                },
                                                marginRight: 20,
                                                width: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: '30%',
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Image
                                              resizeMode={'contain'}
                                              source={{
                                                uri: `${listData?.image?.url}`,
                                              }}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.ImageStyles(
                                                    theme
                                                  )['Image'],
                                                  {
                                                    borderColor:
                                                      theme.colors['Divider'],
                                                    borderRadius: 9,
                                                    borderWidth: 0.5,
                                                    height: 110,
                                                    width: 110,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            />
                                          </View>
                                          {/* View 2 */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                justifyContent: 'center',
                                                width: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: '50%',
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Oswald_400Regular',
                                                    fontSize: 16,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.Fname}{' '}
                                              {listData?.Lname}{' '}
                                            </Text>
                                            {/* Text 3 */}
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    color:
                                                      theme.colors['Primary'],
                                                    fontFamily: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value:
                                                          'Lato_400Regular',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Tablet,
                                                        value: 'Lato_700Bold',
                                                      },
                                                    ],
                                                    marginTop: 10,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.email}
                                            </Text>
                                            {/* Text 6 */}
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Lato_400Regular',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'Status: Active'}
                                            </Text>
                                          </View>
                                        </View>
                                      </Surface>
                                    </View>
                                  </Touchable>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                              style={StyleSheet.applyWidth(
                                {
                                  width: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '100%',
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: '100%',
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            />
                          );
                        }}
                      </SocialBevyApi.FetchGetAllInfluencersGET>
                    )}
                  </>
                  {/* Mobile */}
                  <>
                    {dimensions.width >= Breakpoints.Tablet ? null : (
                      <SocialBevyApi.FetchGetAllInfluencersGET>
                        {({
                          loading,
                          error,
                          data,
                          refetchGetAllInfluencers,
                        }) => {
                          const mobileData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <FlatList
                              data={mobileData}
                              keyExtractor={listData =>
                                listData?.id ||
                                listData?.uuid ||
                                JSON.stringify(listData)
                              }
                              listKey={'66pYKEKO'}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'AdminInfluencerDetailsScreen',
                                          { influID_Pass: listData?.id }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      {
                                        width: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '100%',
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: '50%',
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Surface
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.SurfaceStyles(theme)[
                                              'Surface'
                                            ],
                                            {
                                              borderRadius: 9,
                                              marginBottom: 5,
                                              width: '95%',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {/* View 4 */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              height: 140,
                                              justifyContent: 'center',
                                              marginBottom: {
                                                minWidth: Breakpoints.Tablet,
                                                value: 5,
                                              },
                                              marginTop: {
                                                minWidth: Breakpoints.Tablet,
                                                value: 5,
                                              },
                                              width: '100%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 'center',
                                                },
                                                marginRight: 20,
                                                width: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: '30%',
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Image
                                              resizeMode={'contain'}
                                              source={{
                                                uri: `${listData?.image?.url}`,
                                              }}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.ImageStyles(
                                                    theme
                                                  )['Image'],
                                                  {
                                                    borderColor:
                                                      theme.colors['Divider'],
                                                    borderRadius: 9,
                                                    borderWidth: 0.5,
                                                    height: 110,
                                                    width: 110,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            />
                                          </View>
                                          {/* View 2 */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                justifyContent: 'center',
                                                width: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: '50%',
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Oswald_400Regular',
                                                    fontSize: 16,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.Fname}{' '}
                                              {listData?.Lname}{' '}
                                            </Text>
                                            {/* Text 3 */}
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    color:
                                                      theme.colors['Primary'],
                                                    fontFamily: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value:
                                                          'Lato_400Regular',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Tablet,
                                                        value: 'Lato_700Bold',
                                                      },
                                                    ],
                                                    marginTop: 10,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.email}
                                            </Text>
                                            {/* Text 6 */}
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily:
                                                      'Lato_400Regular',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'Status: Active'}
                                            </Text>
                                          </View>
                                        </View>
                                      </Surface>
                                    </View>
                                  </Touchable>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                              style={StyleSheet.applyWidth(
                                {
                                  width: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '100%',
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: '100%',
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            />
                          );
                        }}
                      </SocialBevyApi.FetchGetAllInfluencersGET>
                    )}
                  </>
                </View>
              )}
            </>
          </View>
          {/* Actions */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: [
                  { minWidth: Breakpoints.Mobile, value: 'column' },
                  { minWidth: Breakpoints.Laptop, value: 'row' },
                ],
                justifyContent: [
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                  { minWidth: Breakpoints.Laptop, value: 'space-evenly' },
                ],
                marginBottom: 25,
                marginTop: 20,
                paddingLeft: 20,
                paddingRight: 20,
                width: { minWidth: Breakpoints.Laptop, value: '80%' },
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('AdminUserSignupFormScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
            >
              <Surface
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'],
                    {
                      alignItems: 'center',
                      alignSelf: 'center',
                      backgroundColor: theme.colors['Primary'],
                      borderRadius: 9,
                      height: 50,
                      marginBottom: 10,
                      marginTop: 10,
                      width: '90%',
                    }
                  ),
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      height: '100%',
                      justifyContent: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors['Strong Inverse']}
                    name={'SimpleLineIcons/user-follow'}
                    size={20}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Strong Inverse'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 13,
                        marginLeft: 6,
                      },
                      dimensions.width
                    )}
                  >
                    {'Create a User'}
                  </Text>
                </View>
              </Surface>
            </Touchable>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('AdminVendorSignupScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
            >
              <Surface
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'],
                    {
                      alignItems: 'center',
                      alignSelf: 'center',
                      backgroundColor: theme.colors['Primary'],
                      borderRadius: 9,
                      height: 50,
                      marginBottom: 10,
                      marginTop: 10,
                      width: '90%',
                    }
                  ),
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      height: '100%',
                      justifyContent: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors['Strong Inverse']}
                    name={'Ionicons/md-business'}
                    size={20}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Strong Inverse'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 13,
                        marginLeft: 6,
                      },
                      dimensions.width
                    )}
                  >
                    {'Create A Vendor'}
                  </Text>
                </View>
              </Surface>
            </Touchable>
            {/* Touchable 3 */}
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('AdminInfluencerSignupFormScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
            >
              <Surface
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'],
                    {
                      alignItems: 'center',
                      alignSelf: 'center',
                      backgroundColor: theme.colors['Primary'],
                      borderRadius: 9,
                      height: 50,
                      marginBottom: 10,
                      marginTop: 10,
                      width: '90%',
                    }
                  ),
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      height: '100%',
                      justifyContent: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors['Strong Inverse']}
                    name={'MaterialIcons/star'}
                    size={20}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Strong Inverse'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 13,
                        marginLeft: 6,
                      },
                      dimensions.width
                    )}
                  >
                    {'Create Influencer'}
                  </Text>
                </View>
              </Surface>
            </Touchable>
            {/* Touchable 4 */}
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('ManageAccountAdminScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
            >
              <Surface
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'],
                    {
                      alignItems: 'center',
                      alignSelf: 'center',
                      backgroundColor: theme.colors['Secondary'],
                      borderRadius: 9,
                      height: 50,
                      marginBottom: 10,
                      marginTop: 10,
                      width: '90%',
                    }
                  ),
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      height: '100%',
                      justifyContent: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors['Strong Inverse']}
                    name={'FontAwesome/gears'}
                    size={20}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Strong Inverse'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 13,
                        marginLeft: 6,
                      },
                      dimensions.width
                    )}
                  >
                    {'Manage My Account'}
                  </Text>
                </View>
              </Surface>
            </Touchable>
          </View>
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

export default withTheme(AdminDashboardScreen);
