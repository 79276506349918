import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SocialBevyApi from '../apis/SocialBevyApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const MarketplaceItemDetailsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [membership, setMembership] = React.useState(false);
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const [offer_id, setOffer_id] = React.useState('');
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const socialBevyPublishOfferPATCH = SocialBevyApi.usePublishOfferPATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Custom Color'] },
        dimensions.width
      )}
    >
      {/* Desktop Menu Special 2 */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
                flexDirection: {
                  minWidth: Breakpoints.Laptop,
                  value: 'column',
                },
                height: { minWidth: Breakpoints.Laptop, value: 119.6 },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'center',
                },
                paddingBottom: { minWidth: Breakpoints.Laptop, value: 15 },
                paddingTop: { minWidth: Breakpoints.Laptop, value: 15 },
                width: {
                  minWidth: Breakpoints.Laptop,
                  value: dimensions.width,
                },
              },
              dimensions.width
            )}
          >
            {/* Header Container */}
            <>
              {Constants['user_ID'] || Constants['vendor_ID'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '55.9%' },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '27.06%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorSignupFormScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserSignupFormScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Sign Up'}
                    />
                    {/* Button 3 */}
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorLoginScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserLoginScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            backgroundColor: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Secondary'],
                            },
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            marginLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 10,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Login'}
                    />
                  </View>
                </View>
              )}
            </>
            {/* Header Container Signed In */}
            <>
              {!(Constants['user_ID'] || Constants['vendor_ID']) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '69.43%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 2 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('MarketplaceScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Marketplace'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 5 */}
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorDashboardScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          if (Constants['Admin']) {
                            navigation.navigate('AdminDashboardScreen');
                          }
                          if (Constants['Admin']) {
                            return;
                          }
                          navigation.navigate('UserDashboardScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Secondary'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'My Account'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '13.53%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc'] || Constants['Admin']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          navigation.navigate('UserHomeScreen');
                          setGlobalVariableValue({
                            key: 'vendor_ID',
                            value: '',
                          });
                          setGlobalVariableValue({
                            key: 'user_ID',
                            value: '',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Logout'}
                    />
                  </View>
                </View>
              )}
            </>
          </View>
        )}
      </>
      {/* Mobile Menu Opener */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Background'],
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-between',
                marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
                paddingLeft: 16,
                paddingRight: 16,
              },
              dimensions.width
            )}
          >
            <View>
              <Image
                resizeMode={'contain'}
                source={Images.BevyLogo}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ImageStyles(theme)['Image'],
                  dimensions.width
                )}
              />
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', zIndex: 1 },
                dimensions.width
              )}
            >
              {/* Icon Button 2 */}
              <>
                {mobileMenu ? null : (
                  <IconButton
                    icon={'Feather/menu'}
                    onPress={() => {
                      try {
                        setMobileMenu(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={38}
                    style={StyleSheet.applyWidth(
                      { marginRight: 5 },
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* Icon Button 3 */}
              <>
                {!mobileMenu ? null : (
                  <IconButton
                    icon={'AntDesign/closecircleo'}
                    onPress={() => {
                      try {
                        setMobileMenu(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={38}
                    style={StyleSheet.applyWidth(
                      { marginRight: 5 },
                      dimensions.width
                    )}
                  />
                )}
              </>
            </View>
          </View>
        )}
      </>
      <SocialBevyApi.FetchGetSpecificOfferGET
        offer_id={props.route?.params?.offerID ?? 17}
      >
        {({ loading, error, data, refetchGetSpecificOffer }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <ScrollView bounces={true} showsVerticalScrollIndicator={false}>
              {/* Mobile Menu Special 3 */}
              <>
                {!mobileMenu ? null : (
                  <BlurView
                    intensity={80}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.BlurViewStyles(theme)['Blur View'],
                        { height: '100%', width: '100%', zIndex: 1 }
                      ),
                      dimensions.width
                    )}
                    tint={'light'}
                  >
                    <>
                      {dimensions.width >= Breakpoints.Laptop ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Surface'],
                              borderBottomLeftRadius: 12,
                              borderBottomRightRadius: 12,
                              height: 500,
                              opacity: 1,
                              paddingTop: 30,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Divider'],
                                flexDirection: 'row',
                                height: 110,
                                justifyContent: 'space-evenly',
                                paddingBottom: [
                                  { minWidth: Breakpoints.Tablet, value: 15 },
                                  { minWidth: Breakpoints.Mobile, value: 20 },
                                ],
                                paddingLeft: 40,
                                paddingRight: 40,
                                paddingTop: [
                                  { minWidth: Breakpoints.Tablet, value: 15 },
                                  { minWidth: Breakpoints.Mobile, value: 20 },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { justifyContent: 'center', width: '70%' },
                                dimensions.width
                              )}
                            >
                              {/* View 2 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexDirection: 'row' },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  allowFontScaling={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        fontFamily: 'Lato_400Regular',
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 20,
                                          },
                                        ],
                                        marginBottom: 5,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Welcome,'}
                                </Text>
                                {/* Text 2 */}
                                <Text
                                  accessible={true}
                                  allowFontScaling={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        fontFamily: 'Lato_400Regular',
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 20,
                                          },
                                        ],
                                        marginBottom: 5,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {Constants['fName']}
                                </Text>
                              </View>
                              {/* Special Buttons */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    flexDirection: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 'row',
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 'row',
                                      },
                                    ],
                                    justifyContent: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 'flex-end',
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 'space-evenly',
                                      },
                                    ],
                                    marginTop: 5,
                                    minHeight: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 45,
                                    },
                                    width: {
                                      minWidth: Breakpoints.Laptop,
                                      value: '27.06%',
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                <>
                                  {!(
                                    Constants['user_ID'] ||
                                    Constants['vendor_ID']
                                  ) ? null : (
                                    <Button
                                      onPress={() => {
                                        try {
                                          if (Constants['VendorAcc']) {
                                            if (Constants['vendor_ID']) {
                                              navigation.navigate(
                                                'VendorDashboardScreen'
                                              );
                                            } else {
                                              navigation.navigate(
                                                'VendorSignupFormScreen'
                                              );
                                            }
                                          } else {
                                            if (Constants['user_ID']) {
                                              navigation.navigate(
                                                'UserDashboardScreen'
                                              );
                                            } else {
                                              navigation.navigate(
                                                'UserSignupFormScreen'
                                              );
                                            }
                                          }
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ButtonStyles(theme)[
                                            'Button'
                                          ],
                                          {
                                            borderRadius: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 9,
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 9,
                                              },
                                            ],
                                            width: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: '45%',
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: '45%',
                                              },
                                            ],
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                      title={'Sign Out'}
                                    />
                                  )}
                                </>
                                {/* Button 2 */}
                                <>
                                  {Constants['user_ID'] ||
                                  Constants['vendor_ID'] ? null : (
                                    <Button
                                      onPress={() => {
                                        try {
                                          if (Constants['VendorAcc']) {
                                            if (Constants['vendor_ID']) {
                                              navigation.navigate(
                                                'VendorDashboardScreen'
                                              );
                                            } else {
                                              navigation.navigate(
                                                'VendorSignupFormScreen'
                                              );
                                            }
                                          } else {
                                            if (Constants['user_ID']) {
                                              navigation.navigate(
                                                'UserDashboardScreen'
                                              );
                                            } else {
                                              navigation.navigate(
                                                'UserSignupFormScreen'
                                              );
                                            }
                                          }
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ButtonStyles(theme)[
                                            'Button'
                                          ],
                                          {
                                            borderRadius: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 9,
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 9,
                                              },
                                            ],
                                            width: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: '45%',
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: '45%',
                                              },
                                            ],
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                      title={'Sign Up'}
                                    />
                                  )}
                                </>
                                {/* Button 3 */}
                                <>
                                  {Constants['user_ID'] || undefined ? null : (
                                    <Button
                                      onPress={() => {
                                        try {
                                          if (Constants['VendorAcc']) {
                                            if (Constants['vendor_ID']) {
                                              navigation.navigate(
                                                'VendorDashboardScreen'
                                              );
                                            } else {
                                              navigation.navigate(
                                                'VendorLoginScreen'
                                              );
                                            }
                                          } else {
                                            if (Constants['user_ID']) {
                                              navigation.navigate(
                                                'UserDashboardScreen'
                                              );
                                            } else {
                                              navigation.navigate(
                                                'UserLoginScreen'
                                              );
                                            }
                                          }
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ButtonStyles(theme)[
                                            'Button'
                                          ],
                                          {
                                            backgroundColor: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value:
                                                  theme.colors['Secondary'],
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value:
                                                  theme.colors['Secondary'],
                                              },
                                            ],
                                            borderRadius: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 9,
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 9,
                                              },
                                            ],
                                            marginLeft: {
                                              minWidth: Breakpoints.Laptop,
                                              value: 10,
                                            },
                                            width: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: '45%',
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: '45%',
                                              },
                                            ],
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                      title={'Login'}
                                    />
                                  )}
                                </>
                              </View>
                            </View>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                { justifyContent: 'center' },
                                dimensions.width
                              )}
                            >
                              <Image
                                resizeMode={'contain'}
                                source={Images.BevyBee}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image'],
                                    { borderRadius: 100 }
                                  ),
                                  dimensions.width
                                )}
                              />
                            </View>
                          </View>
                          {/* Link Buttons 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: [
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 'center',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'center',
                                  },
                                ],
                                flexDirection: [
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 'row',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'row',
                                  },
                                ],
                                height: [
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 100,
                                  },
                                  { minWidth: Breakpoints.Mobile, value: 300 },
                                ],
                                justifyContent: [
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 'space-evenly',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'space-evenly',
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'space-evenly',
                                  },
                                ],
                                marginTop: 30,
                                paddingLeft: 40,
                                paddingRight: 40,
                                width: [
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: '70%',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: '70%',
                                  },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'center',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                  ],
                                  borderBottomWidth: 0.5,
                                  borderColor: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: theme.colors['Primary'],
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: theme.colors['Light'],
                                    },
                                  ],
                                  height: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: '100%',
                                    },
                                    { minWidth: Breakpoints.Laptop, value: 80 },
                                    { minWidth: Breakpoints.Mobile, value: 35 },
                                  ],
                                  justifyContent: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'center',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                  ],
                                  width: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 100,
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 100,
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {/* Link 2 */}
                              <Link
                                accessible={true}
                                allowFontScaling={true}
                                onPress={() => {
                                  try {
                                    if (Constants['VendorAcc']) {
                                      navigation.navigate('VendorHomeScreen');
                                    }
                                    if (Constants['VendorAcc']) {
                                      return;
                                    }
                                    navigation.navigate('UserHomeScreen');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)['Link'],
                                    {
                                      color: {
                                        minWidth: Breakpoints.BigScreen,
                                        value: theme.colors['Primary'],
                                      },
                                      fontFamily: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 'Oswald_400Regular',
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'Oswald_400Regular',
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 'Oswald_400Regular',
                                        },
                                      ],
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 16,
                                        },
                                      ],
                                      textTransform: 'uppercase',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'Home'}
                              />
                            </View>
                            {/* View 3 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'center',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                  ],
                                  borderBottomWidth: 0.5,
                                  borderColor: theme.colors['Light'],
                                  height: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: '100%',
                                    },
                                    { minWidth: Breakpoints.Laptop, value: 80 },
                                    { minWidth: Breakpoints.Mobile, value: 35 },
                                  ],
                                  justifyContent: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'center',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                  ],
                                  width: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 100,
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 100,
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {/* Link 3 */}
                              <Link
                                accessible={true}
                                allowFontScaling={true}
                                onPress={() => {
                                  try {
                                    navigation.navigate('PodcastScreen');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)['Link'],
                                    {
                                      color: {
                                        minWidth: Breakpoints.BigScreen,
                                        value: theme.colors['Primary'],
                                      },
                                      fontFamily: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 'Oswald_400Regular',
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'Oswald_400Regular',
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 'Oswald_400Regular',
                                        },
                                      ],
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 16,
                                        },
                                      ],
                                      textTransform: 'uppercase',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'podcast'}
                              />
                            </View>
                            {/* View 6 */}
                            <>
                              {!(
                                Constants['user_ID'] || Constants['vendor_ID']
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 'center',
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'center',
                                        },
                                      ],
                                      borderBottomWidth: 0.5,
                                      borderColor: theme.colors['Light'],
                                      height: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: '100%',
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 80,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 35,
                                        },
                                      ],
                                      justifyContent: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 'center',
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'center',
                                        },
                                      ],
                                      width: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 100,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 100,
                                        },
                                      ],
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Link 3 */}
                                  <Link
                                    accessible={true}
                                    allowFontScaling={true}
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'MarketplaceScreen'
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.LinkStyles(theme)['Link'],
                                        {
                                          color: {
                                            minWidth: Breakpoints.BigScreen,
                                            value: theme.colors['Primary'],
                                          },
                                          fontFamily: [
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 'Oswald_400Regular',
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 'Oswald_400Regular',
                                            },
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 'Oswald_400Regular',
                                            },
                                          ],
                                          fontSize: [
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 16,
                                            },
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 16,
                                            },
                                          ],
                                          textTransform: 'uppercase',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    title={'Marketplace'}
                                  />
                                </View>
                              )}
                            </>
                            {/* View 5 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'center',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                  ],
                                  borderBottomWidth: 0.5,
                                  borderColor: theme.colors['Light'],
                                  height: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: '100%',
                                    },
                                    { minWidth: Breakpoints.Laptop, value: 80 },
                                    { minWidth: Breakpoints.Mobile, value: 35 },
                                  ],
                                  justifyContent: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'center',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                  ],
                                  width: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 100,
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 100,
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {/* Link 4 */}
                              <Link
                                accessible={true}
                                allowFontScaling={true}
                                onPress={() => {
                                  try {
                                    navigation.navigate('ContactUsScreen');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)['Link'],
                                    {
                                      color: {
                                        minWidth: Breakpoints.BigScreen,
                                        value: theme.colors['Primary'],
                                      },
                                      fontFamily: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 'Oswald_400Regular',
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'Oswald_400Regular',
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 'Oswald_400Regular',
                                        },
                                      ],
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 16,
                                        },
                                      ],
                                      textTransform: 'uppercase',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'contact us'}
                              />
                            </View>
                            {/* View 4 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'center',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                  ],
                                  borderBottomWidth: 0.5,
                                  borderColor: theme.colors['Light'],
                                  height: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: '100%',
                                    },
                                    { minWidth: Breakpoints.Laptop, value: 80 },
                                    { minWidth: Breakpoints.Mobile, value: 35 },
                                  ],
                                  justifyContent: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'center',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                  ],
                                  width: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 100,
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 100,
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {/* Link 4 */}
                              <>
                                {!(
                                  Constants['user_ID'] || Constants['vendor_ID']
                                ) ? null : (
                                  <Link
                                    accessible={true}
                                    allowFontScaling={true}
                                    onPress={() => {
                                      try {
                                        if (Constants['VendorAcc']) {
                                          navigation.navigate(
                                            'VendorDashboardScreen'
                                          );
                                        }
                                        if (Constants['VendorAcc']) {
                                          return;
                                        }
                                        if (Constants['Admin']) {
                                          navigation.navigate(
                                            'AdminDashboardScreen'
                                          );
                                        }
                                        if (Constants['Admin']) {
                                          return;
                                        }
                                        navigation.navigate(
                                          'UserDashboardScreen'
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.LinkStyles(theme)['Link'],
                                        {
                                          color: {
                                            minWidth: Breakpoints.BigScreen,
                                            value: theme.colors['Primary'],
                                          },
                                          fontFamily: [
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 'Oswald_400Regular',
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 'Oswald_400Regular',
                                            },
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 'Oswald_400Regular',
                                            },
                                          ],
                                          fontSize: [
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 16,
                                            },
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 16,
                                            },
                                          ],
                                          textTransform: 'uppercase',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    title={'My Account'}
                                  />
                                )}
                              </>
                            </View>
                          </View>
                        </View>
                      )}
                    </>
                  </BlurView>
                )}
              </>
              {/* Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Secondary'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: theme.colors['Secondary'],
                      },
                    ],
                    flexDirection: 'row',
                    height: 65,
                    justifyContent: 'center',
                    paddingLeft: 16,
                    paddingRight: 16,
                  },
                  dimensions.width
                )}
              >
                {/* Back Click */}
                <>
                  {dimensions.width >= Breakpoints.Laptop ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          height: 48,
                          justifyContent: 'center',
                          width: 48,
                        },
                        dimensions.width
                      )}
                    >
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.goBack();
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <Icon
                          color={theme.colors['Primary']}
                          name={'AntDesign/leftcircle'}
                          size={32}
                        />
                      </Touchable>
                    </View>
                  )}
                </>
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', width: '70%' },
                    dimensions.width
                  )}
                >
                  {/* Screen Heading */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Strong Inverse'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                        ],
                        fontFamily: 'Lato_900Black',
                        fontSize: 18,
                      },
                      dimensions.width
                    )}
                  >
                    {fetchData?.result_1?.offer_name}
                  </Text>
                </View>
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: [
                        {
                          minWidth: Breakpoints.Tablet,
                          value: theme.colors['Secondary'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Divider'],
                        },
                      ],
                      borderRadius: [
                        { minWidth: Breakpoints.Laptop, value: 12 },
                        { minWidth: Breakpoints.Mobile, value: 9 },
                      ],
                      flexDirection: {
                        minWidth: Breakpoints.Desktop,
                        value: 'column',
                      },
                      height: { minWidth: Breakpoints.Desktop, value: 320 },
                      justifyContent: [
                        { minWidth: Breakpoints.Desktop, value: 'center' },
                        { minWidth: Breakpoints.Mobile, value: 'center' },
                      ],
                      margin: 10,
                      marginTop: [
                        { minWidth: Breakpoints.BigScreen, value: 100 },
                        { minWidth: Breakpoints.Tablet, value: 20 },
                      ],
                      padding: 20,
                      paddingBottom: {
                        minWidth: Breakpoints.Laptop,
                        value: 30,
                      },
                      paddingTop: { minWidth: Breakpoints.Laptop, value: 30 },
                      width: { minWidth: Breakpoints.Laptop, value: '50%' },
                    },
                    dimensions.width
                  )}
                >
                  <Surface
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.SurfaceStyles(theme)['Surface'],
                        {
                          borderRadius: 9,
                          padding: 10,
                          paddingLeft: 15,
                          paddingRight: 15,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      source={{ uri: `${fetchData?.result_1?.Image?.url}` }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            borderRadius: 12,
                            height: [
                              { minWidth: Breakpoints.Desktop, value: 180 },
                              { minWidth: Breakpoints.Laptop, value: 150 },
                              { minWidth: Breakpoints.Mobile, value: 200 },
                              { minWidth: Breakpoints.BigScreen, value: 200 },
                            ],
                            marginBottom: 10,
                            marginTop: 10,
                            width: [
                              { minWidth: Breakpoints.Laptop, value: 150 },
                              { minWidth: Breakpoints.Mobile, value: 200 },
                              { minWidth: Breakpoints.Desktop, value: 180 },
                              { minWidth: Breakpoints.BigScreen, value: 200 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </Surface>
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['Medium'],
                      flexDirection: 'row',
                      justifyContent: 'center',
                      minHeight: 50,
                      width: { minWidth: Breakpoints.Laptop, value: '80%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* View 4 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Lato_700Bold',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Active Locations: 1'}
                    </Text>
                  </View>
                </View>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Mobile, value: 'center' },
                        { minWidth: Breakpoints.Tablet, value: 'center' },
                      ],
                      backgroundColor: theme.colors['Divider'],
                      justifyContent: 'center',
                      marginBottom: 20,
                      marginTop: 5,
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '100%' },
                        { minWidth: Breakpoints.Tablet, value: '100%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  <Surface
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.SurfaceStyles(theme)['Surface'],
                        {
                          alignItems: 'center',
                          backgroundColor: theme.colors['Primary'],
                          justifyContent: 'center',
                          width: '100%',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Price */}
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Lato_700Bold',
                            fontSize: 14,
                            marginTop: 10,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Price'}
                    </Text>
                    {/* Price 2 */}
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Lato_900Black',
                            fontSize: 26,
                            marginBottom: 15,
                            marginTop: 5,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'$'}
                      {fetchData?.result_1?.Price}
                      {'.00'}
                    </Text>
                  </Surface>
                  {/* Offer Amount */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Lato_700Bold',
                          fontSize: 18,
                          marginBottom: 10,
                          marginTop: 30,
                          textDecorationLine: 'line-through',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Original Price : $'}
                    {fetchData?.result_1?.Price}
                    {'.00'}
                  </Text>
                  {/* Offer Percent */}
                  <>
                    {!(fetchData?.result_1?.offer_amount_percent > 0) ? null : (
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Lato_700Bold',
                              fontSize: 18,
                              marginTop: 20,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {fetchData?.result_1?.offer_amount_percent}
                        {'% Discount'}
                      </Text>
                    )}
                  </>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Background'],
                        justifyContent: 'center',
                        marginBottom: 10,
                        marginTop: 10,
                        padding: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Offer Desc */}
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            fontFamily: 'Lato_400Regular',
                            paddingLeft: [
                              { minWidth: Breakpoints.Mobile, value: 30 },
                              { minWidth: Breakpoints.Tablet, value: 100 },
                            ],
                            paddingRight: [
                              { minWidth: Breakpoints.Mobile, value: 30 },
                              { minWidth: Breakpoints.Tablet, value: 100 },
                            ],
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {fetchData?.result_1?.offer_description}
                    </Text>
                  </View>
                  {/* Offer Location */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary'],
                          fontFamily: 'Lato_900Black',
                          fontSize: 16,
                          marginBottom: 5,
                          marginTop: 10,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Offer Location'}
                  </Text>

                  <SocialBevyApi.FetchGetLocationGET
                    vendor_id={fetchData?.result_1?.vendor_id}
                  >
                    {({ loading, error, data, refetchGetLocation }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <>
                          {/* Offer Location 2 */}
                          <Text
                            accessible={true}
                            allowFontScaling={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  fontFamily: 'Lato_700Bold',
                                  fontSize: 18,
                                  marginBottom: 10,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.location_name}
                          </Text>
                          {/* Offer Location 3 */}
                          <Text
                            accessible={true}
                            allowFontScaling={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                { fontFamily: 'Lato_400Regular', fontSize: 16 }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.Address_1}
                            {', '}
                            {fetchData?.suite_or_unit}
                          </Text>
                          {/* Offer Location 4 */}
                          <Text
                            accessible={true}
                            allowFontScaling={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                { fontFamily: 'Lato_400Regular', fontSize: 16 }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.city}
                            {', '}
                            {fetchData?.state} {fetchData?.zip_code}
                          </Text>
                        </>
                      );
                    }}
                  </SocialBevyApi.FetchGetLocationGET>
                  <SocialBevyApi.FetchGetVendorGET
                    vendors_id={fetchData?.result_1?.vendor_id}
                  >
                    {({ loading, error, data, refetchGetVendor }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <>
                          {/* View 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                marginBottom: 20,
                                marginTop: 30,
                                width: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: '80%',
                                  },
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: '50%',
                                  },
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: '40%',
                                  },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: '30%',
                                  },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            {/* Button 2 */}
                            <>
                              {fetchData?.result_1?.Active ? null : (
                                <Button
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        (
                                          await socialBevyPublishOfferPATCH.mutateAsync(
                                            {
                                              offer_id:
                                                props.route?.params?.offerID ??
                                                17,
                                              offers_id:
                                                props.route?.params?.offerID ??
                                                17,
                                              vendor_id: Constants['vendor_ID'],
                                            }
                                          )
                                        )?.json;
                                        if (
                                          fetchData?.Membership_plan ===
                                          'Soci-Data Free'
                                        ) {
                                          navigation.navigate('PaywallScreen');
                                        }
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ButtonStyles(theme)[
                                        'Button'
                                      ],
                                      {
                                        borderRadius: 9,
                                        fontFamily: 'Lato_700Bold',
                                        fontSize: 16,
                                        minHeight: 60,
                                        width: '100%',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                  title={'Purchase'}
                                />
                              )}
                            </>
                          </View>
                        </>
                      );
                    }}
                  </SocialBevyApi.FetchGetVendorGET>
                </View>
                {/* Actions */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: [
                        { minWidth: Breakpoints.Mobile, value: 'column' },
                        { minWidth: Breakpoints.Laptop, value: 'row' },
                      ],
                      justifyContent: [
                        { minWidth: Breakpoints.Mobile, value: 'center' },
                        { minWidth: Breakpoints.Laptop, value: 'space-evenly' },
                      ],
                      marginTop: 20,
                      paddingLeft: 20,
                      paddingRight: 20,
                      width: { minWidth: Breakpoints.Laptop, value: '80%' },
                    },
                    dimensions.width
                  )}
                ></View>
                {/* Actions 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: [
                        { minWidth: Breakpoints.Mobile, value: 'column' },
                        { minWidth: Breakpoints.Laptop, value: 'row' },
                      ],
                      justifyContent: [
                        { minWidth: Breakpoints.Mobile, value: 'center' },
                        { minWidth: Breakpoints.Laptop, value: 'space-evenly' },
                      ],
                      marginTop: 20,
                      paddingLeft: 20,
                      paddingRight: 20,
                      width: { minWidth: Breakpoints.Laptop, value: '80%' },
                    },
                    dimensions.width
                  )}
                ></View>

                <ScrollView
                  bounces={true}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  style={StyleSheet.applyWidth(
                    { width: { minWidth: Breakpoints.Laptop, value: '100%' } },
                    dimensions.width
                  )}
                >
                  {/* Actions 2 */}
                  <>
                    {dimensions.width >= Breakpoints.Laptop ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'stretch',
                              },
                              { minWidth: Breakpoints.Tablet, value: 'center' },
                            ],
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginTop: 20,
                            paddingLeft: 20,
                            paddingRight: 20,
                          },
                          dimensions.width
                        )}
                      >
                        <FlatList
                          data={['1']}
                          keyExtractor={listData =>
                            listData?.id ||
                            listData?.uuid ||
                            JSON.stringify(listData)
                          }
                          listKey={'LE2AqXzA'}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return null;
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                          style={StyleSheet.applyWidth(
                            {
                              width: {
                                minWidth: Breakpoints.Tablet,
                                value: '70%',
                              },
                            },
                            dimensions.width
                          )}
                          contentContainerStyle={StyleSheet.applyWidth(
                            {
                              alignItems: {
                                minWidth: Breakpoints.Tablet,
                                value: 'stretch',
                              },
                            },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </ScrollView>
              </View>
            </ScrollView>
          );
        }}
      </SocialBevyApi.FetchGetSpecificOfferGET>
    </ScreenContainer>
  );
};

export default withTheme(MarketplaceItemDetailsScreen);
