import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SocialBevyApi from '../apis/SocialBevyApi.js';
import UpdateMobileHeader128Block from '../components/UpdateMobileHeader128Block';
import UpdatedMobileMenuAdminSignedInBlock from '../components/UpdatedMobileMenuAdminSignedInBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  Divider,
  Icon,
  IconButton,
  NumberInput,
  Picker,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, ScrollView, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const AdminVendorDetailsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [company_logo, setCompany_logo] = React.useState('');
  const [deleteView, setDeleteView] = React.useState(false);
  const [manageView, setManageView] = React.useState(false);
  const [membership, setMembership] = React.useState(false);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState(undefined);
  const socialBevyEditVendorInfoPATCH = SocialBevyApi.useEditVendorInfoPATCH();
  const socialBevyDeleteVendorDELETE = SocialBevyApi.useDeleteVendorDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'adminView_ID',
        value: props.route?.params?.vendorID_Pass ?? 15,
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Custom Color'] },
        dimensions.width
      )}
    >
      {/* Desktop menu signed in 12-12 */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
                flexDirection: {
                  minWidth: Breakpoints.Laptop,
                  value: 'column',
                },
                height: { minWidth: Breakpoints.Laptop, value: 119.6 },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'center',
                },
                paddingBottom: { minWidth: Breakpoints.Laptop, value: 15 },
                paddingTop: { minWidth: Breakpoints.Laptop, value: 15 },
                width: {
                  minWidth: Breakpoints.Laptop,
                  value: dimensions.width,
                },
              },
              dimensions.width
            )}
          >
            {/* Header Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                  height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'space-between',
                  },
                  width: { minWidth: Breakpoints.Laptop, value: '74%' },
                },
                dimensions.width
              )}
            >
              {/* Logo */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    width: { minWidth: Breakpoints.Laptop, value: '16.93%' },
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'contain'}
                  source={Images.BevyLogo}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '120%' },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                />
              </View>
              {/* Menu */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    height: { minWidth: Breakpoints.Laptop, value: '80%' },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'space-evenly',
                    },
                    width: { minWidth: Breakpoints.Laptop, value: '69.43%' },
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: {
                        minWidth: Breakpoints.Laptop,
                        value: 3,
                      },
                      borderColor: {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Strong Inverse'],
                      },
                      height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Primary'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Oswald_400Regular',
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            textTransform: {
                              minWidth: Breakpoints.Laptop,
                              value: 'uppercase',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Home'}
                    </Text>
                  </View>
                </Touchable>
                {/* Touchable 2 */}
                <Touchable
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: {
                        minWidth: Breakpoints.Laptop,
                        value: 3,
                      },
                      borderColor: {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Strong Inverse'],
                      },
                      height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Primary'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Oswald_400Regular',
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            textTransform: {
                              minWidth: Breakpoints.Laptop,
                              value: 'uppercase',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Podcasts'}
                    </Text>
                  </View>
                </Touchable>
                {/* Touchable 4 */}
                <Touchable
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: {
                        minWidth: Breakpoints.Laptop,
                        value: 3,
                      },
                      borderColor: {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Strong Inverse'],
                      },
                      height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Primary'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Oswald_400Regular',
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            textTransform: {
                              minWidth: Breakpoints.Laptop,
                              value: 'uppercase',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Marketplace'}
                    </Text>
                  </View>
                </Touchable>
                {/* Touchable 3 */}
                <Touchable
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: {
                        minWidth: Breakpoints.Laptop,
                        value: 3,
                      },
                      borderColor: {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Strong Inverse'],
                      },
                      height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Primary'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Oswald_400Regular',
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            textTransform: {
                              minWidth: Breakpoints.Laptop,
                              value: 'uppercase',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'contact Us'}
                    </Text>
                  </View>
                </Touchable>
                {/* Touchable 5 */}
                <Touchable
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: {
                        minWidth: Breakpoints.Laptop,
                        value: 3,
                      },
                      borderColor: {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Secondary'],
                      },
                      height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Primary'],
                            },
                            fontFamily: {
                              minWidth: Breakpoints.Laptop,
                              value: 'Oswald_400Regular',
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            textTransform: {
                              minWidth: Breakpoints.Laptop,
                              value: 'uppercase',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'My Account'}
                    </Text>
                  </View>
                </Touchable>
              </View>
              {/* Buttons or Icons */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-end',
                    },
                    minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                    width: { minWidth: Breakpoints.Laptop, value: '13.53%' },
                  },
                  dimensions.width
                )}
              >
                <Button
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'],
                      {
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 9,
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Logout'}
                />
              </View>
            </View>
          </View>
        )}
      </>
      <UpdateMobileHeader128Block />
      <SocialBevyApi.FetchGetVendorGET vendors_id={Constants['adminView_ID']}>
        {({ loading, error, data, refetchGetVendor }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <ScrollView bounces={true} showsVerticalScrollIndicator={false}>
              <>
                {!Constants['menuView'] ? null : (
                  <UpdatedMobileMenuAdminSignedInBlock />
                )}
              </>
              {/* Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Secondary'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: theme.colors['Secondary'],
                      },
                    ],
                    flexDirection: 'row',
                    height: 48,
                    justifyContent: 'center',
                    paddingLeft: 16,
                    paddingRight: 16,
                  },
                  dimensions.width
                )}
              >
                {/* Back Click */}
                <>
                  {dimensions.width >= Breakpoints.Laptop ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          height: 48,
                          justifyContent: 'center',
                          left: 16,
                          position: 'absolute',
                          top: 0,
                          width: 48,
                        },
                        dimensions.width
                      )}
                    >
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.goBack();
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <Icon
                          color={theme.colors['Primary']}
                          name={'AntDesign/leftcircle'}
                          size={32}
                        />
                      </Touchable>
                    </View>
                  )}
                </>
                {/* Screen Heading */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Strong Inverse'],
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Strong Inverse'],
                        },
                      ],
                      fontFamily: 'Lato_900Black',
                      fontSize: 18,
                    },
                    dimensions.width
                  )}
                >
                  {'Manage Vendor'}
                </Text>
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: {
                        minWidth: Breakpoints.Tablet,
                        value: theme.colors['Secondary'],
                      },
                      borderRadius: { minWidth: Breakpoints.Laptop, value: 12 },
                      flexDirection: {
                        minWidth: Breakpoints.Desktop,
                        value: 'column',
                      },
                      height: { minWidth: Breakpoints.Desktop, value: 320 },
                      justifyContent: {
                        minWidth: Breakpoints.Desktop,
                        value: 'center',
                      },
                      marginTop: [
                        { minWidth: Breakpoints.Mobile, value: 20 },
                        { minWidth: Breakpoints.BigScreen, value: 100 },
                        { minWidth: Breakpoints.Tablet, value: 20 },
                      ],
                      padding: 20,
                      paddingBottom: {
                        minWidth: Breakpoints.Laptop,
                        value: 30,
                      },
                      paddingTop: { minWidth: Breakpoints.Laptop, value: 30 },
                      width: { minWidth: Breakpoints.Laptop, value: '50%' },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Tablet,
                            value: theme.colors['Strong Inverse'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Secondary'],
                          },
                        ],
                        fontFamily: 'Lato_700Bold',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 20 },
                          { minWidth: Breakpoints.Desktop, value: 22 },
                          { minWidth: Breakpoints.Tablet, value: 24 },
                        ],
                        opacity: 1,
                      },
                      dimensions.width
                    )}
                  >
                    {fetchData?.business_name}
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Background'],
                        borderRadius: 12,
                        marginTop: { minWidth: Breakpoints.Tablet, value: 10 },
                      },
                      dimensions.width
                    )}
                  >
                    <Surface
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          { borderRadius: 9, marginTop: 10, padding: 10 }
                        ),
                        dimensions.width
                      )}
                    >
                      <Image
                        resizeMode={'contain'}
                        source={{ uri: `${fetchData?.Logo?.url}` }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'],
                            {
                              borderRadius: 12,
                              height: [
                                { minWidth: Breakpoints.Desktop, value: 180 },
                                { minWidth: Breakpoints.Laptop, value: 150 },
                                { minWidth: Breakpoints.Mobile, value: 200 },
                                { minWidth: Breakpoints.BigScreen, value: 200 },
                              ],
                              marginBottom: 10,
                              marginTop: 10,
                              width: [
                                { minWidth: Breakpoints.Laptop, value: 150 },
                                { minWidth: Breakpoints.Mobile, value: 200 },
                                { minWidth: Breakpoints.Desktop, value: 180 },
                                { minWidth: Breakpoints.BigScreen, value: 200 },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      />
                    </Surface>
                  </View>
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', marginTop: 10 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Secondary'],
                          fontFamily: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: 'Lato_400Regular',
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: 'Lato_700Bold',
                            },
                          ],
                          fontSize: [
                            { minWidth: Breakpoints.Desktop, value: 16 },
                            { minWidth: Breakpoints.Mobile, value: 18 },
                          ],
                          marginTop: 6,
                          opacity: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Laptop, value: 1 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {'Member Plan: '}
                      {fetchData?.Membership_plan}
                    </Text>
                    {/* Text 2 */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Secondary'],
                          fontFamily: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: 'Lato_300Light',
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: 'Lato_700Bold',
                            },
                          ],
                          fontSize: [
                            { minWidth: Breakpoints.Desktop, value: 14 },
                            { minWidth: Breakpoints.Mobile, value: 16 },
                          ],
                          marginTop: 6,
                          opacity: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            { minWidth: Breakpoints.Laptop, value: 1 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {'Member Since: '}
                      {fetchData?.created_at}
                    </Text>
                  </View>
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['Secondary'],
                      flexDirection: 'row',
                      justifyContent: 'center',
                      minHeight: 50,
                      width: { minWidth: Breakpoints.Laptop, value: '80%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* View 4 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '10%',
                      },
                      dimensions.width
                    )}
                  >
                    <SocialBevyApi.FetchLocationCountGET
                      vendor_id={props.route?.params?.vendorID_Pass ?? 15}
                    >
                      {({ loading, error, data, refetchLocationCount }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <Text
                            accessible={true}
                            allowFontScaling={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  color: theme.colors['Strong Inverse'],
                                  fontFamily: 'Lato_400Regular',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Active Locations: ('}
                            {fetchData?.count}
                            {')'}
                          </Text>
                        );
                      }}
                    </SocialBevyApi.FetchLocationCountGET>
                  </View>
                  <Divider
                    color={theme.colors['Primary']}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.DividerStyles(theme)['Divider'],
                        { minHeight: 40, width: 1 }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* View 3 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: '10%',
                      },
                      dimensions.width
                    )}
                  >
                    <SocialBevyApi.FetchActiveOfferCountGET
                      vendor_id={props.route?.params?.vendorID_Pass ?? 15}
                    >
                      {({ loading, error, data, refetchActiveOfferCount }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            {/* Text 2 */}
                            <Text
                              accessible={true}
                              allowFontScaling={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Strong Inverse'],
                                    fontFamily: 'Lato_400Regular',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Active Offers: ('}
                              {fetchData?.active_offers}
                              {')'}
                            </Text>
                          </>
                        );
                      }}
                    </SocialBevyApi.FetchActiveOfferCountGET>
                  </View>
                </View>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Mobile, value: 'center' },
                        { minWidth: Breakpoints.Tablet, value: 'center' },
                      ],
                      backgroundColor: theme.colors['Divider'],
                      justifyContent: 'center',
                      marginTop: 5,
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '100%' },
                        { minWidth: Breakpoints.Tablet, value: '100%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        marginBottom: 20,
                        marginTop: 20,
                        width: [
                          { minWidth: Breakpoints.Mobile, value: '80%' },
                          { minWidth: Breakpoints.Tablet, value: '50%' },
                          { minWidth: Breakpoints.Desktop, value: '40%' },
                          { minWidth: Breakpoints.BigScreen, value: '30%' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {/* Button 4 */}
                    <>
                      {manageView ? null : (
                        <Button
                          onPress={() => {
                            try {
                              navigation.navigate('AdminOfferManagementScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              { borderRadius: 12 }
                            ),
                            dimensions.width
                          )}
                          title={'Manage Vendor Offers'}
                        />
                      )}
                    </>
                    {/* Button 2 */}
                    <>
                      {deleteView ? null : (
                        <Button
                          onPress={() => {
                            try {
                              setDeleteView(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              { borderRadius: 12, marginTop: 10 }
                            ),
                            dimensions.width
                          )}
                          title={'Manage Vendor Information'}
                        />
                      )}
                    </>
                    {/* Button 3 */}
                    <>
                      {!manageView ? null : (
                        <Button
                          onPress={() => {
                            try {
                              setManageView(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              { borderRadius: 12 }
                            ),
                            dimensions.width
                          )}
                          title={'Close'}
                        />
                      )}
                    </>
                  </View>
                </View>
                {/* View 3 */}
                <>
                  {manageView ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: [
                            { minWidth: Breakpoints.Mobile, value: 'center' },
                            { minWidth: Breakpoints.Tablet, value: 'center' },
                          ],
                          backgroundColor: theme.colors['Divider'],
                          justifyContent: 'center',
                          marginBottom: 20,
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '100%' },
                            { minWidth: Breakpoints.Tablet, value: '100%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {/* vendor Signup Form */}
                      <>
                        {!deleteView ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                padding: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '5%',
                                },
                                width: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: '90%',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: '40%',
                                  },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            {/* Image */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  paddingBottom: 20,
                                  paddingTop: 20,
                                },
                                dimensions.width
                              )}
                            >
                              <Touchable
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      const result = await openImagePickerUtil({
                                        mediaTypes: 'All',
                                        allowsEditing: true,
                                        quality: 0.2,
                                      });
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                              >
                                <View>
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { alignItems: 'center' },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      allowFontScaling={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            color: theme.colors['Primary'],
                                            fontFamily: 'Oswald_400Regular',
                                            fontSize: 18,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Update Company Logo'}
                                    </Text>
                                  </View>

                                  <Circle
                                    bgColor={theme.colors.light}
                                    size={140}
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          theme.colors['Divider'],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {company_logo ? null : (
                                        <Image
                                          resizeMode={'cover'}
                                          source={Images.BevyBee}
                                          style={StyleSheet.applyWidth(
                                            { height: 140, width: 140 },
                                            dimensions.width
                                          )}
                                        />
                                      )}
                                    </>
                                    <>
                                      {!company_logo ? null : (
                                        <Image
                                          resizeMode={'cover'}
                                          source={{ uri: `${company_logo}` }}
                                          style={StyleSheet.applyWidth(
                                            { height: 140, width: 140 },
                                            dimensions.width
                                          )}
                                        />
                                      )}
                                    </>
                                  </Circle>
                                  <>
                                    {!company_logo ? null : (
                                      <IconButton
                                        color={theme.colors['Primary']}
                                        icon={'AntDesign/closecircle'}
                                        onPress={() => {
                                          try {
                                            setCompany_logo('');
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        size={42}
                                        style={StyleSheet.applyWidth(
                                          {
                                            bottom: 0,
                                            position: 'absolute',
                                            right: 0,
                                            zIndex: 5,
                                          },
                                          dimensions.width
                                        )}
                                      />
                                    )}
                                  </>
                                  <>
                                    {company_logo ? null : (
                                      <Image
                                        resizeMode={'cover'}
                                        source={Images.Camera}
                                        style={StyleSheet.applyWidth(
                                          {
                                            bottom: 0,
                                            height: 48,
                                            position: 'absolute',
                                            right: 0,
                                            width: 48,
                                            zIndex: 2,
                                          },
                                          dimensions.width
                                        )}
                                      />
                                    )}
                                  </>
                                </View>
                              </Touchable>
                            </View>
                            {/* First Name */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    theme.colors['Custom Color_3'],
                                  height: 48,
                                  marginLeft: 20,
                                  marginRight: 20,
                                  marginTop: 15,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                },
                                dimensions.width
                              )}
                            >
                              {/* First Name */}
                              <TextInput
                                editable={true}
                                placeholder={'First Name'}
                                placeholderTextColor={theme.colors['Medium']}
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomWidth: 0.5,
                                    color: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: theme.colors['Secondary'],
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: theme.colors['Secondary'],
                                      },
                                    ],
                                    fontFamily: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 'Lato_400Regular',
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 'Lato_400Regular',
                                      },
                                    ],
                                    fontSize: 15,
                                    height: 48,
                                    paddingBottom: 8,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                              />
                              <View
                                style={StyleSheet.applyWidth(
                                  { position: 'absolute', top: -10 },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: theme.colors['Primary'],
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: theme.colors['Primary'],
                                        },
                                      ],
                                      fontFamily: 'Oswald_400Regular',
                                      fontSize: 16,
                                      marginLeft: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'First Name'}
                                </Text>
                              </View>
                            </View>
                            {/* Last Name */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    theme.colors['Custom Color_3'],
                                  height: 48,
                                  marginLeft: 20,
                                  marginRight: 20,
                                  marginTop: 15,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Last Name */}
                              <TextInput
                                editable={true}
                                onChangeText={newLastNameValue => {
                                  try {
                                    setGlobalVariableValue({
                                      key: 'Name',
                                      value: newLastNameValue,
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                placeholder={'Last Name'}
                                placeholderTextColor={theme.colors['Medium']}
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomWidth: 0.5,
                                    color: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: theme.colors['Secondary'],
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: theme.colors['Secondary'],
                                      },
                                    ],
                                    fontFamily: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 'Lato_400Regular',
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 'Lato_400Regular',
                                      },
                                    ],
                                    fontSize: 15,
                                    height: 48,
                                    paddingBottom: 8,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                              />
                              <View
                                style={StyleSheet.applyWidth(
                                  { position: 'absolute', top: -10 },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: [
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: theme.colors['Primary'],
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: theme.colors['Primary'],
                                        },
                                      ],
                                      fontFamily: 'Oswald_400Regular',
                                      fontSize: 16,
                                      marginLeft: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Last Name'}
                                </Text>
                              </View>
                            </View>
                            {/* Business Name */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    theme.colors['Custom Color_3'],
                                  height: 48,
                                  marginLeft: 20,
                                  marginRight: 20,
                                  marginTop: 15,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Business Name */}
                              <TextInput
                                editable={true}
                                onChangeText={newBusinessNameValue => {
                                  try {
                                    setGlobalVariableValue({
                                      key: 'Name',
                                      value: newBusinessNameValue,
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                placeholder={'ex. Company LLC'}
                                placeholderTextColor={theme.colors['Medium']}
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomWidth: 0.5,
                                    color: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: theme.colors['Secondary'],
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: theme.colors['Secondary'],
                                      },
                                    ],
                                    fontFamily: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 'Lato_400Regular',
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 'Lato_400Regular',
                                      },
                                    ],
                                    fontSize: 15,
                                    height: 48,
                                    paddingBottom: 8,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                              />
                              <View
                                style={StyleSheet.applyWidth(
                                  { position: 'absolute', top: -10 },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: [
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: theme.colors['Primary'],
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: theme.colors['Primary'],
                                        },
                                      ],
                                      fontFamily: 'Oswald_400Regular',
                                      fontSize: 16,
                                      marginLeft: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Business Name'}
                                </Text>
                              </View>
                            </View>
                            {/* Email Address */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    theme.colors['Custom Color_3'],
                                  height: 48,
                                  marginLeft: 20,
                                  marginRight: 20,
                                  marginTop: 15,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Email Address */}
                              <TextInput
                                editable={true}
                                onChangeText={newEmailAddressValue => {
                                  try {
                                    setGlobalVariableValue({
                                      key: 'Name',
                                      value: newEmailAddressValue,
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                placeholder={'ex.abc@gmail.com'}
                                placeholderTextColor={theme.colors['Medium']}
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomWidth: 0.5,
                                    color: theme.colors['Secondary'],
                                    fontFamily: 'Lato_400Regular',
                                    fontSize: 15,
                                    height: 48,
                                    paddingBottom: 8,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                              />
                              <View
                                style={StyleSheet.applyWidth(
                                  { position: 'absolute', top: -10 },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: theme.colors['Primary'],
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: theme.colors['Primary'],
                                        },
                                      ],
                                      fontFamily: 'Oswald_400Regular',
                                      fontSize: 16,
                                      marginLeft: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Email Address'}
                                </Text>
                              </View>
                            </View>
                            {/* Phone Number */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    theme.colors['Custom Color_3'],
                                  height: 48,
                                  marginLeft: 20,
                                  marginRight: 20,
                                  marginTop: 15,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                },
                                dimensions.width
                              )}
                            >
                              <NumberInput
                                allowFontScaling={true}
                                autoComplete={'tel'}
                                changeTextDelay={500}
                                maxLength={10}
                                placeholder={'Enter Your Phone Number'}
                                placeholderTextColor={theme.colors['Medium']}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.NumberInputStyles(theme)[
                                      'Number Input'
                                    ],
                                    {
                                      borderBottomWidth: 0.5,
                                      color: theme.colors['Secondary'],
                                      fontFamily: 'Lato_400Regular',
                                      fontSize: 15,
                                      height: 48,
                                      paddingLeft: 20,
                                      paddingRight: 20,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              />
                              <View
                                style={StyleSheet.applyWidth(
                                  { position: 'absolute', top: -10 },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: [
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: theme.colors['Primary'],
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: theme.colors['Primary'],
                                        },
                                      ],
                                      fontFamily: 'Oswald_400Regular',
                                      fontSize: 16,
                                      marginLeft: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Phone Number'}
                                </Text>
                              </View>
                            </View>
                            {/* Membership */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    theme.colors['Custom Color_3'],
                                  height: 48,
                                  marginBottom: 15,
                                  marginLeft: 20,
                                  marginRight: 20,
                                  marginTop: 15,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                },
                                dimensions.width
                              )}
                            >
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background
                                }
                                dropDownBorderColor={theme.colors.divider}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.strong}
                                iconSize={24}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerValue => {
                                  const pickerValue = newPickerValue;
                                  try {
                                    setPickerValue(pickerValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                options={[
                                  'Soci-Data Free',
                                  'Soci-Data Basic',
                                  'Soci-Data Advanced',
                                ]}
                                placeholder={'Select an option'}
                                selectedIconColor={theme.colors.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                type={'solid'}
                                value={pickerValue}
                              />
                              <View
                                style={StyleSheet.applyWidth(
                                  { position: 'absolute', top: -10 },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: [
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: theme.colors['Primary'],
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: theme.colors['Primary'],
                                        },
                                      ],
                                      fontFamily: 'Oswald_400Regular',
                                      fontSize: 16,
                                      marginLeft: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Membership Plan'}
                                </Text>
                              </View>
                            </View>

                            <Text
                              accessible={true}
                              allowFontScaling={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Error'],
                                    fontFamily: 'Lato_400Regular',
                                    marginTop: 10,
                                    paddingLeft: 30,
                                    paddingRight: 30,
                                    textAlign: 'left',
                                    textDecorationLine: 'underline',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {null}
                            </Text>
                            {/* Continue */}
                            <Button
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    (
                                      await socialBevyEditVendorInfoPATCH.mutateAsync(
                                        {
                                          Fname: 'jay',
                                          Lname: 'Elliott',
                                          Membership_plan: 'Soci-Data Free',
                                          business_name: 'Sky Design Tech',
                                          email: 'jay@skydesigntech.com',
                                          email_verified: true,
                                          phone_number: 3128593284,
                                          vendors_id:
                                            props.route?.params
                                              ?.vendorID_Pass ?? 15,
                                        }
                                      )
                                    )?.json;
                                    /* 'Extract Key' action requires configuration: select Input */
                                    /* 'Extract Key' action requires configuration: select Input */
                                    if (errorResult) {
                                      return;
                                    }
                                    navigation.navigate('AdminDashboardScreen');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: theme.colors['Primary'],
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: theme.colors['Primary'],
                                    },
                                  ],
                                  borderRadius: 12,
                                  fontFamily: 'Oswald_400Regular',
                                  fontSize: 17,
                                  height: 48,
                                  marginBottom: 10,
                                  marginLeft: 20,
                                  marginRight: 20,
                                  marginTop: [
                                    { minWidth: Breakpoints.Laptop, value: 20 },
                                    { minWidth: Breakpoints.Mobile, value: 10 },
                                  ],
                                  textAlign: 'center',
                                },
                                dimensions.width
                              )}
                              title={'Update Vendor Details'}
                            />
                          </View>
                        )}
                      </>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            marginBottom: 20,
                            marginTop: 20,
                            width: [
                              { minWidth: Breakpoints.Mobile, value: '80%' },
                              { minWidth: Breakpoints.Tablet, value: '50%' },
                              { minWidth: Breakpoints.Desktop, value: '40%' },
                              { minWidth: Breakpoints.BigScreen, value: '30%' },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {/* Button 4 */}
                        <>
                          {!deleteView ? null : (
                            <Button
                              onPress={() => {
                                try {
                                  setDeleteView(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)['Button'],
                                  {
                                    backgroundColor: theme.colors['Secondary'],
                                    borderRadius: 12,
                                    width: 150,
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Close'}
                            />
                          )}
                        </>
                      </View>
                      {/* View 3 */}
                      <>
                        {!deleteView ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                marginBottom: 20,
                                marginTop: 20,
                                width: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: '80%',
                                  },
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: '50%',
                                  },
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: '40%',
                                  },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: '30%',
                                  },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              allowFontScaling={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  { marginBottom: 25, textAlign: 'center' }
                                ),
                                dimensions.width
                              )}
                            >
                              {
                                'Are you sure you would like to permanently delete '
                              }
                              {null}
                              {' as a vendor?'}
                            </Text>
                            {/* Button 4 */}
                            <Button
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    (
                                      await socialBevyDeleteVendorDELETE.mutateAsync(
                                        {
                                          vendors_id:
                                            props.route?.params
                                              ?.vendorID_Pass ?? 15,
                                        }
                                      )
                                    )?.json;
                                    setDeleteView(false);
                                    navigation.navigate('AdminDashboardScreen');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)['Button'],
                                  {
                                    backgroundColor: theme.colors['Primary'],
                                    borderRadius: 12,
                                    minHeight: 50,
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Permanently Delete This Vendor'}
                            />
                          </View>
                        )}
                      </>
                    </View>
                  )}
                </>
              </View>
            </ScrollView>
          );
        }}
      </SocialBevyApi.FetchGetVendorGET>
    </ScreenContainer>
  );
};

export default withTheme(AdminVendorDetailsScreen);
