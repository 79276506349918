import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SocialBevyApi from '../apis/SocialBevyApi.js';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Surface, Touchable, withTheme } from '@draftbit/ui';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { ActivityIndicator, FlatList, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const AllVendorsList1Block = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const [allVendor_view, setAllVendor_view] = React.useState(false);

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: 10,
          minHeight: 50,
          width: [
            { minWidth: Breakpoints.Laptop, value: '80%' },
            { minWidth: Breakpoints.Mobile, value: '100%' },
          ],
        },
        dimensions.width
      )}
    >
      {/* Tablet and Above */}
      <>
        {!(dimensions.width >= Breakpoints.Tablet) ? null : (
          <SocialBevyApi.FetchGetAllVendorsGET>
            {({ loading, error, data, refetchGetAllVendors }) => {
              const tabletAndAboveData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <FlatList
                  data={tabletAndAboveData?.vendors}
                  keyExtractor={listData =>
                    listData?.id || listData?.uuid || JSON.stringify(listData)
                  }
                  listKey={'56czgtM4'}
                  numColumns={2}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <Touchable
                        style={StyleSheet.applyWidth(
                          {
                            width: [
                              { minWidth: Breakpoints.Mobile, value: '100%' },
                              { minWidth: Breakpoints.Tablet, value: '50%' },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Surface 2 */}
                          <Surface
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SurfaceStyles(theme)['Surface'],
                                {
                                  borderRadius: 9,
                                  marginBottom: 5,
                                  width: '95%',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {/* View 4 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  height: 140,
                                  justifyContent: 'center',
                                  marginBottom: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 5,
                                  },
                                  marginTop: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 5,
                                  },
                                  width: '90%',
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'center',
                                    },
                                    marginRight: 20,
                                    width: {
                                      minWidth: Breakpoints.Tablet,
                                      value: '30%',
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                <Image
                                  resizeMode={'contain'}
                                  source={{ uri: `${listData?.Logo?.url}` }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ImageStyles(theme)['Image'],
                                      { height: 110, width: 110 }
                                    ),
                                    dimensions.width
                                  )}
                                />
                              </View>
                              {/* View 2 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    justifyContent: 'center',
                                    width: {
                                      minWidth: Breakpoints.Tablet,
                                      value: '50%',
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  allowFontScaling={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        fontFamily: 'Oswald_400Regular',
                                        fontSize: 16,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.Fname} {listData?.Lname}{' '}
                                </Text>
                                {/* Text 3 */}
                                <Text
                                  accessible={true}
                                  allowFontScaling={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        color: theme.colors['Primary'],
                                        fontFamily: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 'Lato_400Regular',
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 'Lato_700Bold',
                                          },
                                        ],
                                        marginTop: 10,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.business_name}
                                </Text>
                                {/* Text 4 */}
                                <Text
                                  accessible={true}
                                  allowFontScaling={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        fontFamily: 'Lato_400Regular',
                                        marginTop: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 10,
                                        },
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Plan Type: '}
                                  {listData?.Membership_plan}
                                </Text>
                                {/* Text 6 */}
                                <Text
                                  accessible={true}
                                  allowFontScaling={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      { fontFamily: 'Lato_400Regular' }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Status: Active'}
                                </Text>
                              </View>
                            </View>
                          </Surface>
                        </View>
                      </Touchable>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  style={StyleSheet.applyWidth(
                    {
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '100%' },
                        { minWidth: Breakpoints.Tablet, value: '100%' },
                      ],
                    },
                    dimensions.width
                  )}
                />
              );
            }}
          </SocialBevyApi.FetchGetAllVendorsGET>
        )}
      </>
      {/* Mobile */}
      <>
        {dimensions.width >= Breakpoints.Tablet ? null : (
          <SocialBevyApi.FetchGetAllVendorsGET>
            {({ loading, error, data, refetchGetAllVendors }) => {
              const mobileData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <FlatList
                  data={mobileData?.vendors}
                  keyExtractor={listData =>
                    listData?.id || listData?.uuid || JSON.stringify(listData)
                  }
                  listKey={'jjn9fUId'}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <Touchable
                        style={StyleSheet.applyWidth(
                          {
                            width: [
                              { minWidth: Breakpoints.Mobile, value: '100%' },
                              { minWidth: Breakpoints.Tablet, value: '50%' },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Surface 2 */}
                          <Surface
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SurfaceStyles(theme)['Surface'],
                                {
                                  borderColor: theme.colors['Divider'],
                                  borderRadius: 9,
                                  borderWidth: 0.5,
                                  marginBottom: 5,
                                  width: '100%',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {/* View 4 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  height: 140,
                                  justifyContent: 'center',
                                  marginBottom: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 5,
                                  },
                                  marginTop: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 5,
                                  },
                                  width: '90%',
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'center',
                                    },
                                    marginRight: 20,
                                    width: {
                                      minWidth: Breakpoints.Tablet,
                                      value: '30%',
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                <Image
                                  resizeMode={'contain'}
                                  source={{ uri: `${listData?.Logo?.url}` }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ImageStyles(theme)['Image'],
                                      {
                                        borderColor: theme.colors['Divider'],
                                        borderRadius: 9,
                                        borderWidth: 0.5,
                                        height: 80,
                                        width: 80,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                />
                              </View>
                              {/* View 2 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    justifyContent: 'center',
                                    width: [
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: '50%',
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: '50%',
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  allowFontScaling={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        fontFamily: 'Oswald_400Regular',
                                        fontSize: 16,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.Fname} {listData?.Lname}{' '}
                                </Text>
                                {/* Text 3 */}
                                <Text
                                  accessible={true}
                                  allowFontScaling={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        color: theme.colors['Primary'],
                                        fontFamily: [
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 'Lato_700Bold',
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 'Lato_700Bold',
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.business_name}
                                </Text>
                              </View>
                            </View>
                          </Surface>
                        </View>
                      </Touchable>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  style={StyleSheet.applyWidth(
                    {
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '100%' },
                        { minWidth: Breakpoints.Tablet, value: '100%' },
                      ],
                    },
                    dimensions.width
                  )}
                />
              );
            }}
          </SocialBevyApi.FetchGetAllVendorsGET>
        )}
      </>
    </View>
  );
};

export default withTheme(AllVendorsList1Block);
