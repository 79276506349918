import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

export const PinInputStyles = theme =>
  StyleSheet.create({
    'Pin Input': {
      alignItems: 'center',
      borderColor: theme.colors.medium,
      borderRadius: 5,
      borderWidth: 1,
      color: theme.colors.strong,
      flex: 1,
      fontSize: 25,
      justifyContent: 'center',
      marginLeft: 5,
      marginRight: 5,
      maxHeight: 70,
      maxWidth: 70,
      padding: 5,
    },
  });

export const FetchStyles = theme =>
  StyleSheet.create({ Fetch: { minHeight: 40 } });

export const LinkStyles = theme => StyleSheet.create({});

export const ButtonStyles = theme =>
  StyleSheet.create({
    'Action button': {
      backgroundColor: theme.colors.primary,
      borderRadius: 12,
      fontFamily: 'Inter_500Medium',
      fontSize: 16,
      height: 48,
      marginTop: 25,
      textAlign: 'center',
    },
    Button: { fontFamily: 'System', fontWeight: '700', textAlign: 'center' },
    'action btn tablet': {
      backgroundColor: theme.colors.primary,
      borderRadius: 12,
      fontFamily: 'Inter_500Medium',
      fontSize: [
        { minWidth: Breakpoints.Mobile, value: 16 },
        { minWidth: Breakpoints.BigScreen, value: 24 },
        { minWidth: Breakpoints.Desktop, value: 20 },
        { minWidth: Breakpoints.Laptop, value: 20 },
        { minWidth: Breakpoints.Tablet, value: 20 },
      ],
      height: [
        { minWidth: Breakpoints.Mobile, value: 48 },
        { minWidth: Breakpoints.BigScreen, value: 80 },
        { minWidth: Breakpoints.Desktop, value: 60 },
        { minWidth: Breakpoints.Laptop, value: 60 },
        { minWidth: Breakpoints.Tablet, value: 60 },
      ],
      marginTop: 10,
      textAlign: 'center',
    },
  });

export const BlurViewStyles = theme =>
  StyleSheet.create({
    'Blur View': { flexBasis: 0, flexGrow: 1, flexShrink: 1 },
  });

export const DividerStyles = theme =>
  StyleSheet.create({ Divider: { height: 1 } });

export const H1Styles = theme =>
  StyleSheet.create({
    H1: { color: theme.colors.strong, fontSize: 32, fontWeight: 'bold' },
  });

export const H4Styles = theme =>
  StyleSheet.create({
    H4: { color: theme.colors.strong, fontSize: 16, fontWeight: 'bold' },
  });

export const ImageStyles = theme =>
  StyleSheet.create({
    Image: { height: 100, width: 100 },
    'Image 2': { height: 100, width: 100 },
  });

export const ImageBackgroundStyles = theme => StyleSheet.create({});

export const SurfaceStyles = theme =>
  StyleSheet.create({ Surface: { minHeight: 40 } });

export const TextStyles = theme =>
  StyleSheet.create({
    Text: { color: theme.colors.strong },
    'Text 2': { color: theme.colors.strong },
  });

export const TextInputStyles = theme =>
  StyleSheet.create({
    'Text Area': {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    'Text Input': {
      backgroundColor: theme.colors['Background'],
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderRadius: 9,
      borderWidth: 1,
      minHeight: 45,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    'Text Input 2': {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
  });

export const ViewStyles = theme =>
  StyleSheet.create({
    'header action': {
      alignItems: 'center',
      height: 48,
      justifyContent: 'center',
      width: 48,
    },
    'screen header view': {
      alignItems: 'center',
      flexDirection: 'row',
      height: 50,
      justifyContent: 'space-between',
      paddingLeft: 16,
      paddingRight: 16,
    },
  });

export const CheckboxRowStyles = theme =>
  StyleSheet.create({ 'Checkbox Row': { minHeight: 50 } });

export const NumberInputStyles = theme =>
  StyleSheet.create({
    'Number Input': {
      borderBottomWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
  });

export const VideoPlayerStyles = theme =>
  StyleSheet.create({ Video: { height: 215 } });

export const CircleStyles = theme =>
  StyleSheet.create({
    Circle: {
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
      justifyContent: 'center',
    },
  });

export const YoutubePlayerStyles = theme =>
  StyleSheet.create({
    'Youtube Player': {
      height: 250,
      width: [
        { minWidth: Breakpoints.Mobile, value: '100%' },
        { minWidth: Breakpoints.Tablet, value: 250 },
      ],
    },
  });

export const WebViewStyles = theme =>
  StyleSheet.create({ 'Web View': { flex: 1 } });
