import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SocialBevyApi from '../apis/SocialBevyApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  DatePicker,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { BlurView } from 'expo-blur';
import * as Linking from 'expo-linking';
import { Image, ImageBackground, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const VendorLoginScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [address2Value, setAddress2Value] = React.useState('');
  const [addressValue, setAddressValue] = React.useState('');
  const [cityValue, setCityValue] = React.useState('');
  const [emailPass, setEmailPass] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [locationNameValue, setLocationNameValue] = React.useState('');
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const [passwordValue, setPasswordValue] = React.useState('');
  const [postalCodeValue, setPostalCodeValue] = React.useState('');
  const [stateValue, setStateValue] = React.useState('');
  const [vendorEmailValue, setVendorEmailValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
    >
      {/* Desktop Menu Special 2 */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
                flexDirection: {
                  minWidth: Breakpoints.Laptop,
                  value: 'column',
                },
                height: { minWidth: Breakpoints.Laptop, value: 119.6 },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'center',
                },
                paddingBottom: { minWidth: Breakpoints.Laptop, value: 15 },
                paddingTop: { minWidth: Breakpoints.Laptop, value: 15 },
                width: {
                  minWidth: Breakpoints.Laptop,
                  value: dimensions.width,
                },
              },
              dimensions.width
            )}
          >
            {/* Header Container */}
            <>
              {Constants['user_ID'] || Constants['vendor_ID'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '55.9%' },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Secondary'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '27.06%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorSignupFormScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserSignupFormScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Sign Up'}
                    />
                    {/* Button 3 */}
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorLoginScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserLoginScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            backgroundColor: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Secondary'],
                            },
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            marginLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 10,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Login'}
                    />
                  </View>
                </View>
              )}
            </>
            {/* Header Container Signed In */}
            <>
              {!(Constants['user_ID'] || Constants['vendor_ID']) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '69.43%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    {/* /. ,m. */}
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Secondary'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 2 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('MarketplaceScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Marketplace'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 5 */}
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorDashboardScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          if (Constants['Admin']) {
                            navigation.navigate('AdminDashboardScreen');
                          }
                          if (Constants['Admin']) {
                            return;
                          }
                          navigation.navigate('UserDashboardScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'My Account'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '13.53%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc'] || Constants['Admin']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          navigation.navigate('UserHomeScreen');
                          setGlobalVariableValue({
                            key: 'vendor_ID',
                            value: '',
                          });
                          setGlobalVariableValue({
                            key: 'user_ID',
                            value: '',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Logout'}
                    />
                  </View>
                </View>
              )}
            </>
          </View>
        )}
      </>
      {/* Mobile Menu Opener */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Background'],
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-between',
                marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
                paddingLeft: 16,
                paddingRight: 16,
              },
              dimensions.width
            )}
          >
            <View>
              <Image
                resizeMode={'contain'}
                source={Images.BevyLogo}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ImageStyles(theme)['Image'],
                  dimensions.width
                )}
              />
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', zIndex: 1 },
                dimensions.width
              )}
            >
              {/* Icon Button 2 */}
              <>
                {mobileMenu ? null : (
                  <IconButton
                    icon={'Feather/menu'}
                    onPress={() => {
                      try {
                        setMobileMenu(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={38}
                    style={StyleSheet.applyWidth(
                      { marginRight: 5 },
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* Icon Button 3 */}
              <>
                {!mobileMenu ? null : (
                  <IconButton
                    icon={'AntDesign/closecircleo'}
                    onPress={() => {
                      try {
                        setMobileMenu(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={38}
                    style={StyleSheet.applyWidth(
                      { marginRight: 5 },
                      dimensions.width
                    )}
                  />
                )}
              </>
            </View>
          </View>
        )}
      </>
      <KeyboardAwareScrollView
        contentContainerStyle={StyleSheet.applyWidth(
          {
            backgroundColor: [
              {
                minWidth: Breakpoints.Laptop,
                value: theme.colors['Secondary'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: theme.colors['Secondary'],
              },
            ],
            flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
          },
          dimensions.width
        )}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
      >
        <ImageBackground
          resizeMode={'cover'}
          source={Images.AdobeStock438035062}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
              {
                flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                height: { minWidth: Breakpoints.Laptop, value: '100%' },
                padding: { minWidth: Breakpoints.Laptop, value: '5%' },
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              }
            ),
            dimensions.width
          )}
        >
          {/* Mobile Menu Special 3 */}
          <>
            {!mobileMenu ? null : (
              <BlurView
                intensity={80}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.BlurViewStyles(theme)['Blur View'],
                    { height: '100%', width: '100%', zIndex: 1 }
                  ),
                  dimensions.width
                )}
                tint={'light'}
              >
                <>
                  {dimensions.width >= Breakpoints.Laptop ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Surface'],
                          borderBottomLeftRadius: 12,
                          borderBottomRightRadius: 12,
                          height: 500,
                          opacity: 1,
                          paddingTop: 30,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Divider'],
                            flexDirection: 'row',
                            height: 110,
                            justifyContent: 'space-evenly',
                            paddingBottom: [
                              { minWidth: Breakpoints.Tablet, value: 15 },
                              { minWidth: Breakpoints.Mobile, value: 20 },
                            ],
                            paddingLeft: 40,
                            paddingRight: 40,
                            paddingTop: [
                              { minWidth: Breakpoints.Tablet, value: 15 },
                              { minWidth: Breakpoints.Mobile, value: 20 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { justifyContent: 'center', width: '70%' },
                            dimensions.width
                          )}
                        >
                          {/* View 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              allowFontScaling={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    fontFamily: 'Lato_400Regular',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 20,
                                      },
                                    ],
                                    marginBottom: 5,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Welcome,'}
                            </Text>
                            {/* Text 2 */}
                            <Text
                              accessible={true}
                              allowFontScaling={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    fontFamily: 'Lato_400Regular',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 20,
                                      },
                                    ],
                                    marginBottom: 5,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {Constants['fName']}
                            </Text>
                          </View>
                          {/* Special Buttons */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'row',
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'row',
                                  },
                                ],
                                justifyContent: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'flex-end',
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'space-evenly',
                                  },
                                ],
                                marginTop: 5,
                                minHeight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 45,
                                },
                                width: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '27.06%',
                                },
                              },
                              dimensions.width
                            )}
                          >
                            <>
                              {!(
                                Constants['user_ID'] || Constants['vendor_ID']
                              ) ? null : (
                                <Button
                                  onPress={() => {
                                    try {
                                      if (Constants['VendorAcc']) {
                                        if (Constants['vendor_ID']) {
                                          navigation.navigate(
                                            'VendorDashboardScreen'
                                          );
                                        } else {
                                          navigation.navigate(
                                            'VendorSignupFormScreen'
                                          );
                                        }
                                      } else {
                                        if (Constants['user_ID']) {
                                          navigation.navigate(
                                            'UserDashboardScreen'
                                          );
                                        } else {
                                          navigation.navigate(
                                            'UserSignupFormScreen'
                                          );
                                        }
                                      }
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ButtonStyles(theme)[
                                        'Button'
                                      ],
                                      {
                                        borderRadius: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 9,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 9,
                                          },
                                        ],
                                        width: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '45%',
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '45%',
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                  title={'Sign Out'}
                                />
                              )}
                            </>
                            {/* Button 2 */}
                            <>
                              {Constants['user_ID'] ||
                              Constants['vendor_ID'] ? null : (
                                <Button
                                  onPress={() => {
                                    try {
                                      if (Constants['VendorAcc']) {
                                        if (Constants['vendor_ID']) {
                                          navigation.navigate(
                                            'VendorDashboardScreen'
                                          );
                                        } else {
                                          navigation.navigate(
                                            'VendorSignupFormScreen'
                                          );
                                        }
                                      } else {
                                        if (Constants['user_ID']) {
                                          navigation.navigate(
                                            'UserDashboardScreen'
                                          );
                                        } else {
                                          navigation.navigate(
                                            'UserSignupFormScreen'
                                          );
                                        }
                                      }
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ButtonStyles(theme)[
                                        'Button'
                                      ],
                                      {
                                        borderRadius: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 9,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 9,
                                          },
                                        ],
                                        width: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '45%',
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '45%',
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                  title={'Sign Up'}
                                />
                              )}
                            </>
                            {/* Button 3 */}
                            <>
                              {Constants['user_ID'] || undefined ? null : (
                                <Button
                                  onPress={() => {
                                    try {
                                      if (Constants['VendorAcc']) {
                                        if (Constants['vendor_ID']) {
                                          navigation.navigate(
                                            'VendorDashboardScreen'
                                          );
                                        } else {
                                          navigation.navigate(
                                            'VendorLoginScreen'
                                          );
                                        }
                                      } else {
                                        if (Constants['user_ID']) {
                                          navigation.navigate(
                                            'UserDashboardScreen'
                                          );
                                        } else {
                                          navigation.navigate(
                                            'UserLoginScreen'
                                          );
                                        }
                                      }
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ButtonStyles(theme)[
                                        'Button'
                                      ],
                                      {
                                        backgroundColor: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: theme.colors['Secondary'],
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: theme.colors['Secondary'],
                                          },
                                        ],
                                        borderRadius: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 9,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 9,
                                          },
                                        ],
                                        marginLeft: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 10,
                                        },
                                        width: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '45%',
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '45%',
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                  title={'Login'}
                                />
                              )}
                            </>
                          </View>
                        </View>
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          <Image
                            resizeMode={'contain'}
                            source={Images.BevyBee}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ImageStyles(theme)['Image'],
                                { borderRadius: 100 }
                              ),
                              dimensions.width
                            )}
                          />
                        </View>
                      </View>
                      {/* Link Buttons 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: [
                              {
                                minWidth: Breakpoints.BigScreen,
                                value: 'center',
                              },
                              { minWidth: Breakpoints.Laptop, value: 'center' },
                            ],
                            flexDirection: [
                              { minWidth: Breakpoints.BigScreen, value: 'row' },
                              { minWidth: Breakpoints.Laptop, value: 'row' },
                            ],
                            height: [
                              { minWidth: Breakpoints.BigScreen, value: 100 },
                              { minWidth: Breakpoints.Mobile, value: 300 },
                            ],
                            justifyContent: [
                              {
                                minWidth: Breakpoints.BigScreen,
                                value: 'space-evenly',
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'space-evenly',
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'space-evenly',
                              },
                            ],
                            marginTop: 30,
                            paddingLeft: 40,
                            paddingRight: 40,
                            width: [
                              { minWidth: Breakpoints.BigScreen, value: '70%' },
                              { minWidth: Breakpoints.Laptop, value: '70%' },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              borderBottomWidth: 0.5,
                              borderColor: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: theme.colors['Primary'],
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: theme.colors['Light'],
                                },
                              ],
                              height: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: '100%',
                                },
                                { minWidth: Breakpoints.Laptop, value: 80 },
                                { minWidth: Breakpoints.Mobile, value: 35 },
                              ],
                              justifyContent: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              width: [
                                { minWidth: Breakpoints.BigScreen, value: 100 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          {/* Link 2 */}
                          <Link
                            accessible={true}
                            allowFontScaling={true}
                            onPress={() => {
                              try {
                                if (Constants['VendorAcc']) {
                                  navigation.navigate('VendorHomeScreen');
                                }
                                if (Constants['VendorAcc']) {
                                  return;
                                }
                                navigation.navigate('UserHomeScreen');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)['Link'],
                                {
                                  color: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: theme.colors['Primary'],
                                  },
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'Oswald_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'Oswald_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'Oswald_400Regular',
                                    },
                                  ],
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 16,
                                    },
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                  ],
                                  textTransform: 'uppercase',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Home'}
                          />
                        </View>
                        {/* View 3 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              borderBottomWidth: 0.5,
                              borderColor: theme.colors['Light'],
                              height: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: '100%',
                                },
                                { minWidth: Breakpoints.Laptop, value: 80 },
                                { minWidth: Breakpoints.Mobile, value: 35 },
                              ],
                              justifyContent: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              width: [
                                { minWidth: Breakpoints.BigScreen, value: 100 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          {/* Link 3 */}
                          <Link
                            accessible={true}
                            allowFontScaling={true}
                            onPress={() => {
                              try {
                                navigation.navigate('PodcastScreen');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)['Link'],
                                {
                                  color: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: theme.colors['Primary'],
                                  },
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'Oswald_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'Oswald_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'Oswald_400Regular',
                                    },
                                  ],
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 16,
                                    },
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                  ],
                                  textTransform: 'uppercase',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'podcast'}
                          />
                        </View>
                        {/* View 6 */}
                        <>
                          {!(
                            Constants['user_ID'] || Constants['vendor_ID']
                          ) ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'center',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                  ],
                                  borderBottomWidth: 0.5,
                                  borderColor: theme.colors['Light'],
                                  height: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: '100%',
                                    },
                                    { minWidth: Breakpoints.Laptop, value: 80 },
                                    { minWidth: Breakpoints.Mobile, value: 35 },
                                  ],
                                  justifyContent: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'center',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                  ],
                                  width: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 100,
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 100,
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {/* Link 3 */}
                              <Link
                                accessible={true}
                                allowFontScaling={true}
                                onPress={() => {
                                  try {
                                    navigation.navigate('MarketplaceScreen');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)['Link'],
                                    {
                                      color: {
                                        minWidth: Breakpoints.BigScreen,
                                        value: theme.colors['Primary'],
                                      },
                                      fontFamily: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 'Oswald_400Regular',
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'Oswald_400Regular',
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 'Oswald_400Regular',
                                        },
                                      ],
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 16,
                                        },
                                      ],
                                      textTransform: 'uppercase',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'Marketplace'}
                              />
                            </View>
                          )}
                        </>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              borderBottomWidth: 0.5,
                              borderColor: theme.colors['Light'],
                              height: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: '100%',
                                },
                                { minWidth: Breakpoints.Laptop, value: 80 },
                                { minWidth: Breakpoints.Mobile, value: 35 },
                              ],
                              justifyContent: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              width: [
                                { minWidth: Breakpoints.BigScreen, value: 100 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          {/* Link 4 */}
                          <Link
                            accessible={true}
                            allowFontScaling={true}
                            onPress={() => {
                              try {
                                navigation.navigate('ContactUsScreen');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)['Link'],
                                {
                                  color: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: theme.colors['Primary'],
                                  },
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'Oswald_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'Oswald_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'Oswald_400Regular',
                                    },
                                  ],
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 16,
                                    },
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                  ],
                                  textTransform: 'uppercase',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'contact us'}
                          />
                        </View>
                        {/* View 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              borderBottomWidth: 0.5,
                              borderColor: theme.colors['Light'],
                              height: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: '100%',
                                },
                                { minWidth: Breakpoints.Laptop, value: 80 },
                                { minWidth: Breakpoints.Mobile, value: 35 },
                              ],
                              justifyContent: [
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              width: [
                                { minWidth: Breakpoints.BigScreen, value: 100 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          {/* Link 4 */}
                          <>
                            {!(
                              Constants['user_ID'] || Constants['vendor_ID']
                            ) ? null : (
                              <Link
                                accessible={true}
                                allowFontScaling={true}
                                onPress={() => {
                                  try {
                                    if (Constants['VendorAcc']) {
                                      navigation.navigate(
                                        'VendorDashboardScreen'
                                      );
                                    }
                                    if (Constants['VendorAcc']) {
                                      return;
                                    }
                                    if (Constants['Admin']) {
                                      navigation.navigate(
                                        'AdminDashboardScreen'
                                      );
                                    }
                                    if (Constants['Admin']) {
                                      return;
                                    }
                                    navigation.navigate('UserDashboardScreen');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)['Link'],
                                    {
                                      color: {
                                        minWidth: Breakpoints.BigScreen,
                                        value: theme.colors['Primary'],
                                      },
                                      fontFamily: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 'Oswald_400Regular',
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'Oswald_400Regular',
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 'Oswald_400Regular',
                                        },
                                      ],
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 16,
                                        },
                                      ],
                                      textTransform: 'uppercase',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'My Account'}
                              />
                            )}
                          </>
                        </View>
                      </View>
                    </View>
                  )}
                </>
              </BlurView>
            )}
          </>
          {/* Mobile Header 2 */}
          <>
            {dimensions.width >= Breakpoints.Laptop ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Secondary'],
                    flexDirection: 'row',
                    height: 100,
                    justifyContent: 'space-between',
                    marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
                    paddingLeft: 16,
                    paddingRight: 16,
                  },
                  dimensions.width
                )}
              >
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', zIndex: 1 },
                    dimensions.width
                  )}
                >
                  {/* Icon Button 3 */}
                  <IconButton
                    icon={'AntDesign/leftcircle'}
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={32}
                    style={StyleSheet.applyWidth(
                      { marginRight: 5 },
                      dimensions.width
                    )}
                  />
                </View>

                <View>
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Lato_900Black',
                          fontSize: 18,
                          marginRight: 20,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Vendor Login'}
                  </Text>
                </View>
              </View>
            )}
          </>
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <Surface
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'],
                    {
                      borderBottomLeftRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: 12,
                      },
                      borderTopLeftRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: 12,
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'column',
                      },
                      marginTop: { minWidth: Breakpoints.Laptop, value: -50 },
                      padding: { minWidth: Breakpoints.BigScreen, value: 20 },
                      width: { minWidth: Breakpoints.Laptop, value: '50%' },
                    }
                  ),
                  dimensions.width
                )}
              >
                <ImageBackground
                  resizeMode={'cover'}
                  source={Images.AdobeStock300144034}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageBackgroundStyles(theme)[
                        'Image Background'
                      ],
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        padding: { minWidth: Breakpoints.Laptop, value: '5%' },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: 'rgba(0, 0, 0, 0.58)',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      },
                      dimensions.width
                    )}
                  >
                    <View>
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Strong Inverse'],
                              },
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'Oswald_400Regular',
                              },
                              fontSize: {
                                minWidth: Breakpoints.Laptop,
                                value: 24,
                              },
                              textAlign: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Social Bee Vendor Login'}
                      </Text>
                    </View>
                  </View>
                </ImageBackground>
              </Surface>
            )}
          </>
          {/* Surface 2 */}
          <Surface
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.SurfaceStyles(theme)['Surface'], {
                alignItems: 'stretch',
                borderBottomRightRadius: {
                  minWidth: Breakpoints.Laptop,
                  value: 12,
                },
                borderTopRightRadius: {
                  minWidth: Breakpoints.Laptop,
                  value: 12,
                },
                marginTop: { minWidth: Breakpoints.Laptop, value: -50 },
                padding: { minWidth: Breakpoints.BigScreen, value: 20 },
                width: [
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                  { minWidth: Breakpoints.Laptop, value: '50%' },
                ],
              }),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: {
                    minWidth: Breakpoints.Laptop,
                    value: 'stretch',
                  },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                  padding: { minWidth: Breakpoints.Laptop, value: '5%' },
                  paddingLeft: [
                    { minWidth: Breakpoints.Tablet, value: '20%' },
                    { minWidth: Breakpoints.Mobile, value: '10%' },
                    { minWidth: Breakpoints.Desktop, value: '20%' },
                    { minWidth: Breakpoints.Laptop, value: '20%' },
                  ],
                  paddingRight: [
                    { minWidth: Breakpoints.Tablet, value: '20%' },
                    { minWidth: Breakpoints.Mobile, value: '10%' },
                    { minWidth: Breakpoints.Desktop, value: '20%' },
                    { minWidth: Breakpoints.Laptop, value: '20%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Name 8 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: [
                      { minWidth: Breakpoints.Mobile, value: 60 },
                      { minWidth: Breakpoints.Laptop, value: 20 },
                    ],
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  source={Images.BevyLogo}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      { height: 200, width: 200 }
                    ),
                    dimensions.width
                  )}
                />
              </View>
              {/* Name */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: 48,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* VendorEmail */}
                <TextInput
                  editable={true}
                  onChangeText={newVendorEmailValue => {
                    try {
                      setVendorEmailValue(newVendorEmailValue?.toLowerCase());
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'abc@email.com'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 0.5,
                      color: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Secondary'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Secondary'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'Lato_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'Lato_400Regular',
                        },
                      ],
                      fontSize: 15,
                      height: 48,
                      paddingBottom: 8,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={vendorEmailValue}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', top: -10 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Email'}
                  </Text>
                </View>
              </View>
              {/* Name 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: 48,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* Password */}
                <TextInput
                  autoComplete={'address-line1'}
                  editable={true}
                  onChangeText={newPasswordValue => {
                    try {
                      setPasswordValue(newPasswordValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'******'}
                  placeholderTextColor={theme.colors['Medium']}
                  secureTextEntry={true}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 0.5,
                      color: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Secondary'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Secondary'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'Lato_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'Lato_400Regular',
                        },
                      ],
                      fontSize: 15,
                      height: 48,
                      paddingBottom: 8,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={passwordValue}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', top: -10 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Password'}
                  </Text>
                </View>
              </View>
              {/* Name 7 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                <View>
                  <Link
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.LinkStyles(theme)['Link'],
                        {
                          fontFamily: 'Oswald_400Regular',
                          fontSize: 16,
                          textDecorationLine: 'underline',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Forgot Password'}
                  />
                </View>
              </View>

              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Error'],
                    fontFamily: 'Lato_700Bold',
                    marginTop: 10,
                    paddingLeft: 30,
                    paddingRight: 30,
                    textAlign: 'center',
                    textDecorationLine: 'underline',
                  }),
                  dimensions.width
                )}
              >
                {errorMsg}
              </Text>
              {/* Continue */}
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      const LoginResult = (
                        await SocialBevyApi.vendorLoginPOST(Constants, {
                          email: vendorEmailValue,
                          password: passwordValue,
                        })
                      )?.json;
                      const errorResult = LoginResult?.message;
                      const accStatus = LoginResult?.Account_type;
                      const vendorID = LoginResult?.result_1.id;
                      setGlobalVariableValue({
                        key: 'vendor_ID',
                        value: vendorID,
                      });
                      setErrorMsg(errorResult);
                      if (errorResult) {
                        return;
                      }
                      if (accStatus === 'Standard') {
                        navigation.navigate('VendorDashboardScreen', {
                          id: vendorID,
                        });
                      }
                      if (accStatus === 'Admin') {
                        navigation.navigate('AdminDashboardScreen');
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: theme.colors['Primary'],
                      },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Primary'],
                      },
                    ],
                    borderRadius: 12,
                    fontFamily: 'Oswald_400Regular',
                    fontSize: 17,
                    height: [
                      { minWidth: Breakpoints.Mobile, value: 48 },
                      { minWidth: Breakpoints.Laptop, value: 60 },
                    ],
                    marginBottom: 10,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: [
                      { minWidth: Breakpoints.Laptop, value: 20 },
                      { minWidth: Breakpoints.Mobile, value: 10 },
                    ],
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Login'}
              />
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 25,
                  },
                  dimensions.width
                )}
              >
                {/* Name 9 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['Secondary'],
                      borderRadius: 9,
                      height: 40,
                      justifyContent: 'center',
                      marginLeft: 20,
                      marginRight: 20,
                      marginTop: 20,
                      paddingLeft: 20,
                      paddingRight: 20,
                      width: '70%',
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center' },
                      dimensions.width
                    )}
                  >
                    <Link
                      accessible={true}
                      allowFontScaling={true}
                      onPress={() => {
                        try {
                          navigation.navigate('VendorSignupFormScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.LinkStyles(theme)['Link'],
                          {
                            color: theme.colors['Strong Inverse'],
                            fontFamily: 'Lato_400Regular',
                            fontSize: 14,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                      title={"Sign Up - I don't have an account."}
                    />
                  </View>
                </View>
              </View>
            </View>
          </Surface>
        </ImageBackground>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(VendorLoginScreen);
