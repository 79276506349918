import { systemWeights } from 'react-native-typography';
export default {
  disabledOpacity: 0.5,
  roundness: 6,
  colors: {
    'App Green': 'rgb(0, 204, 154)',
    'BG Gray': 'rgba(225, 218, 218, 0.15)',
    Background: 'rgba(251, 252, 253, 1)',
    'Custom #002b4b': 'rgb(0, 43, 75)',
    'Custom #00374b': 'rgb(0, 55, 75)',
    'Custom #06303f': 'rgb(6, 48, 63)',
    'Custom #14495c': 'rgb(20, 73, 92)',
    'Custom #329fb3': 'rgb(50, 159, 179)',
    'Custom #5f5a53': 'rgb(95, 90, 83)',
    'Custom #8a847d': 'rgb(138, 132, 125)',
    'Custom #aaa7a3': 'rgb(170, 167, 163)',
    'Custom #acacac': 'rgb(172, 172, 172)',
    'Custom #d3536d': 'rgb(211, 83, 109)',
    'Custom #d8d8d8': 'rgb(216, 216, 216)',
    'Custom #dbdbdb': 'rgb(219, 219, 219)',
    'Custom #f06454': 'rgb(240, 100, 84)',
    'Custom #ffffff': 'rgb(255, 255, 255)',
    'Custom Color': 'rgb(255, 255, 255)',
    'Custom Color_10': 'rgb(239, 239, 239)',
    'Custom Color_11': 'rgb(0, 128, 0)',
    'Custom Color_12': 'rgb(43, 230, 174)',
    'Custom Color_13': 'rgb(242, 164, 93)',
    'Custom Color_14': 'rgb(108, 108, 108)',
    'Custom Color_15': 'rgb(253, 253, 253)',
    'Custom Color_16': 'rgb(156, 164, 171)',
    'Custom Color_17': 'rgb(189, 189, 189)',
    'Custom Color_18': 'rgb(0, 0, 0)',
    'Custom Color_19': 'rgb(253, 247, 247)',
    'Custom Color_2': 'rgb(151, 151, 151)',
    'Custom Color_20': 'rgb(62, 193, 99)',
    'Custom Color_21': 'rgb(247, 253, 249)',
    'Custom Color_22': 'rgb(0, 0, 0)',
    'Custom Color_3': 'rgb(237, 237, 237)',
    'Custom Color_4': 'rgb(255, 255, 255)',
    'Custom Color_5': 'rgb(235, 67, 53)',
    'Custom Color_6': 'rgb(117, 117, 117)',
    'Custom Color_7': 'rgb(233, 51, 74)',
    'Custom Color_8': 'rgb(112, 112, 112)',
    'Custom Color_9': 'rgb(152, 152, 152)',
    Divider: 'rgba(234, 237, 242, 1)',
    Error: 'rgba(255, 69, 100, 1)',
    'GetFit Orange': 'rgb(254, 151, 15)',
    'Green BG': 'rgba(0, 204, 154, 0.21)',
    Icon_Opacity: 'rgba(0, 0, 0, 0.25)',
    Light: 'rgba(165, 173, 183, 1)',
    'Light Inverse': 'rgba(255, 255, 255, 0.68)',
    Light_Inverse: 'rgba(255, 255, 255, 0.68)',
    Medium: 'rgb(133, 133, 133)',
    'Medium Inverse': 'rgba(255, 255, 255, 0.87)',
    Medium_Inverse: 'rgba(255, 255, 255, 0.87)',
    Opacity_Blue_for_Button: 'rgba(85, 108, 246, 0.05)',
    Option_Selected_Color: 'rgb(97, 214, 204)',
    People_Bit_Light_Brown: 'rgb(138, 132, 125)',
    Peoplebit_Dark_Blue: 'rgb(0, 43, 75)',
    Peoplebit_Dark_Emerald_Green: 'rgb(20, 73, 92)',
    Peoplebit_Earthy_Brown: 'rgb(95, 90, 83)',
    Peoplebit_Light_Brown: 'rgb(170, 167, 163)',
    Peoplebit_Light_Gray: 'rgb(219, 219, 219)',
    Peoplebit_Light_Stone_Gray: 'rgb(216, 216, 216)',
    Peoplebit_Ocean_Blue: 'rgb(50, 159, 179)',
    Peoplebit_Orange: 'rgb(240, 100, 84)',
    Peoplebit_Salmon_Red: 'rgb(211, 83, 109)',
    Peoplebit_Sapphire: 'rgb(0, 55, 75)',
    Peoplebit_Stone_Gray: 'rgb(172, 172, 172)',
    Peoplebit_Turquoise: 'rgb(6, 48, 63)',
    Peoplebit_White: 'rgb(255, 255, 255)',
    Primary: 'rgb(226, 30, 34)',
    Secondary: 'rgb(0, 0, 0)',
    Settings_Icon_BG_Color: 'rgb(175, 185, 249)',
    ShopAppBlue: 'rgb(14, 134, 212)',
    Strong: 'rgba(18, 20, 44, 1)',
    'Strong Inverse': 'rgba(255, 255, 255, 1)',
    Strong_Inverse: 'rgba(255, 255, 255, 1)',
    Studily_Blue_Night: 'rgb(80, 118, 198)',
    Studily_Bright_Blue: 'rgb(86, 184, 244)',
    Studily_Dark_Primary: 'rgb(17, 17, 17)',
    Studily_Dark_UI: 'rgb(17, 17, 17)',
    Studily_Emerald: 'rgb(126, 209, 172)',
    Studily_Forrest_Shade: 'rgb(126, 209, 172)',
    Studily_Gray_Machine: 'rgb(133, 141, 152)',
    Studily_Lavender_Purple: 'rgb(208, 215, 250)',
    Studily_Light_Blue: 'rgb(55, 66, 83)',
    Studily_Light_Navy_Blue: 'rgb(56, 67, 84)',
    Studily_Light_Navy_Secondary: 'rgb(80, 118, 198)',
    Studily_Milk_White: 'rgb(236, 240, 246)',
    Studily_Mint_Green: 'rgb(97, 214, 204)',
    Studily_Opacity_25: 'rgba(0, 0, 0, 0.25)',
    Studily_Primary: 'rgb(85, 108, 246)',
    Studily_Primary_UI_Dark: 'rgb(55, 66, 83)',
    Studily_Purple_Light: 'rgb(208, 215, 250)',
    Studily_Secondary_UI: 'rgb(133, 141, 152)',
    Studily_Silver_White: 'rgb(236, 240, 246)',
    Studily_Sky_Blue_Primary: 'rgb(86, 184, 244)',
    Studily_Slate_Blue_Dark: 'rgb(56, 67, 84)',
    Studily_Snow_White: 'rgb(230, 235, 244)',
    Studily_Washed_Lavender_White: 'rgb(227, 231, 249)',
    Studily_White: 'rgb(255, 255, 255)',
    Studily_White_BG: 'rgb(243, 245, 249)',
    Studily_White_Shade_1: 'rgb(227, 231, 249)',
    Studily_White_Shade_2: 'rgb(230, 235, 244)',
    Studily_White_Shade_3: 'rgb(231, 235, 241)',
    Studily_White_Shade_5: 'rgb(243, 245, 249)',
    Surface: 'rgba(255, 255, 255, 1)',
    Switch_Color_for_Settings_Page: 'rgb(96, 212, 204)',
    Tab_Divider: 'rgb(231, 235, 241)',
    TextPlaceholder: 'rgb(136, 144, 153)',
    ViewBG: 'rgba(152, 153, 156, 0.25)',
    White: 'rgb(255, 255, 255)',
    appGreen: 'rgb(0, 204, 154)',
    bGGray: 'rgba(225, 218, 218, 0.15)',
    background: 'rgba(251, 252, 253, 1)',
    custom_rgb0_0_0: 'rgb(0, 0, 0)',
    custom_rgb0_128_0: 'rgb(0, 128, 0)',
    custom_rgb0_43_75: 'rgb(0, 43, 75)',
    custom_rgb0_55_75: 'rgb(0, 55, 75)',
    custom_rgb108_108_108: 'rgb(108, 108, 108)',
    custom_rgb112_112_112: 'rgb(112, 112, 112)',
    custom_rgb117_117_117: 'rgb(117, 117, 117)',
    custom_rgb138_132_125: 'rgb(138, 132, 125)',
    custom_rgb151_151_151: 'rgb(151, 151, 151)',
    custom_rgb152_152_152: 'rgb(152, 152, 152)',
    custom_rgb156_164_171: 'rgb(156, 164, 171)',
    custom_rgb170_167_163: 'rgb(170, 167, 163)',
    custom_rgb172_172_172: 'rgb(172, 172, 172)',
    custom_rgb189_189_189: 'rgb(189, 189, 189)',
    custom_rgb20_73_92: 'rgb(20, 73, 92)',
    custom_rgb211_83_109: 'rgb(211, 83, 109)',
    custom_rgb216_216_216: 'rgb(216, 216, 216)',
    custom_rgb219_219_219: 'rgb(219, 219, 219)',
    custom_rgb233_51_74: 'rgb(233, 51, 74)',
    custom_rgb235_67_53: 'rgb(235, 67, 53)',
    custom_rgb237_237_237: 'rgb(237, 237, 237)',
    custom_rgb239_239_239: 'rgb(239, 239, 239)',
    custom_rgb240_100_84: 'rgb(240, 100, 84)',
    custom_rgb242_164_93: 'rgb(242, 164, 93)',
    custom_rgb247_253_249: 'rgb(247, 253, 249)',
    custom_rgb253_247_247: 'rgb(253, 247, 247)',
    custom_rgb253_253_253: 'rgb(253, 253, 253)',
    custom_rgb255_255_255: 'rgb(255, 255, 255)',
    custom_rgb43_230_174: 'rgb(43, 230, 174)',
    custom_rgb50_159_179: 'rgb(50, 159, 179)',
    custom_rgb62_193_99: 'rgb(62, 193, 99)',
    custom_rgb6_48_63: 'rgb(6, 48, 63)',
    custom_rgb95_90_83: 'rgb(95, 90, 83)',
    divider: 'rgba(234, 237, 242, 1)',
    error: 'rgba(255, 69, 100, 1)',
    getFitOrange: 'rgb(254, 151, 15)',
    greenBG: 'rgba(0, 204, 154, 0.21)',
    iconOpacity: 'rgba(0, 0, 0, 0.25)',
    light: 'rgba(165, 173, 183, 1)',
    lightInverse: 'rgba(255, 255, 255, 0.68)',
    medium: 'rgb(133, 133, 133)',
    mediumInverse: 'rgba(255, 255, 255, 0.87)',
    opacityBlueForButton: 'rgba(85, 108, 246, 0.05)',
    optionSelectedColor: 'rgb(97, 214, 204)',
    peopleBitLightBrown: 'rgb(138, 132, 125)',
    peoplebitDarkBlue: 'rgb(0, 43, 75)',
    peoplebitDarkEmeraldGreen: 'rgb(20, 73, 92)',
    peoplebitEarthyBrown: 'rgb(95, 90, 83)',
    peoplebitLightBrown: 'rgb(170, 167, 163)',
    peoplebitLightGray: 'rgb(219, 219, 219)',
    peoplebitLightStoneGray: 'rgb(216, 216, 216)',
    peoplebitOceanBlue: 'rgb(50, 159, 179)',
    peoplebitOrange: 'rgb(240, 100, 84)',
    peoplebitSalmonRed: 'rgb(211, 83, 109)',
    peoplebitSapphire: 'rgb(0, 55, 75)',
    peoplebitStoneGray: 'rgb(172, 172, 172)',
    peoplebitTurquoise: 'rgb(6, 48, 63)',
    peoplebitWhite: 'rgb(255, 255, 255)',
    primary: 'rgb(226, 30, 34)',
    secondary: 'rgb(0, 0, 0)',
    settingsIconBGColor: 'rgb(175, 185, 249)',
    shopAppBlue: 'rgb(14, 134, 212)',
    strong: 'rgba(18, 20, 44, 1)',
    strongInverse: 'rgba(255, 255, 255, 1)',
    studilyBlueNight: 'rgb(80, 118, 198)',
    studilyBrightBlue: 'rgb(86, 184, 244)',
    studilyDarkPrimary: 'rgb(17, 17, 17)',
    studilyDarkUI: 'rgb(17, 17, 17)',
    studilyEmerald: 'rgb(126, 209, 172)',
    studilyForrestShade: 'rgb(126, 209, 172)',
    studilyGrayMachine: 'rgb(133, 141, 152)',
    studilyLavenderPurple: 'rgb(208, 215, 250)',
    studilyLightBlue: 'rgb(55, 66, 83)',
    studilyLightNavyBlue: 'rgb(56, 67, 84)',
    studilyLightNavySecondary: 'rgb(80, 118, 198)',
    studilyMilkWhite: 'rgb(236, 240, 246)',
    studilyMintGreen: 'rgb(97, 214, 204)',
    studilyOpacity25: 'rgba(0, 0, 0, 0.25)',
    studilyPrimary: 'rgb(85, 108, 246)',
    studilyPrimaryUIDark: 'rgb(55, 66, 83)',
    studilyPurpleLight: 'rgb(208, 215, 250)',
    studilySecondaryUI: 'rgb(133, 141, 152)',
    studilySilverWhite: 'rgb(236, 240, 246)',
    studilySkyBluePrimary: 'rgb(86, 184, 244)',
    studilySlateBlueDark: 'rgb(56, 67, 84)',
    studilySnowWhite: 'rgb(230, 235, 244)',
    studilyWashedLavenderWhite: 'rgb(227, 231, 249)',
    studilyWhite: 'rgb(255, 255, 255)',
    studilyWhiteBG: 'rgb(243, 245, 249)',
    studilyWhiteShade1: 'rgb(227, 231, 249)',
    studilyWhiteShade2: 'rgb(230, 235, 244)',
    studilyWhiteShade3: 'rgb(231, 235, 241)',
    studilyWhiteShade5: 'rgb(243, 245, 249)',
    surface: 'rgba(255, 255, 255, 1)',
    switchColorForSettingsPage: 'rgb(96, 212, 204)',
    tabDivider: 'rgb(231, 235, 241)',
    'text placeholder': 'rgb(136, 144, 153)',
    textPlaceholder: 'rgb(136, 144, 153)',
    viewBG: 'rgba(152, 153, 156, 0.25)',
    white: 'rgb(255, 255, 255)',
  },
  typography: {
    body1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    body2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
    button: {
      ...systemWeights.bold,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 16,
    },
    caption: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: 16,
    },
    headline1: {
      ...systemWeights.bold,
      fontSize: 60,
      letterSpacing: 0,
      lineHeight: 71,
    },
    headline2: {
      ...systemWeights.bold,
      fontSize: 48,
      letterSpacing: 0,
      lineHeight: 58,
    },
    headline3: {
      ...systemWeights.bold,
      fontSize: 34,
      letterSpacing: 0,
      lineHeight: 40,
    },
    headline4: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 0,
      lineHeight: 34,
    },
    headline5: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 0,
      lineHeight: 26,
    },
    headline6: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 24,
    },
    overline: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 2,
      lineHeight: 16,
    },
    subtitle1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    subtitle2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
  },
};
