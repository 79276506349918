import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/Draftbit.js';
import LinkingConfiguration from './LinkingConfiguration.js';
import React from 'react';
import * as GlobalVariables from './config/GlobalVariableContext';
import Breakpoints from './utils/Breakpoints';
import useWindowDimensions from './utils/useWindowDimensions';

import AdminDashboardScreen from './screens/AdminDashboardScreen';
import AdminInfluencerDetailsScreen from './screens/AdminInfluencerDetailsScreen';
import AdminInfluencerSignupFormScreen from './screens/AdminInfluencerSignupFormScreen';
import AdminOfferManagementScreen from './screens/AdminOfferManagementScreen';
import AdminUserDetailsScreen from './screens/AdminUserDetailsScreen';
import AdminUserSignupFormScreen from './screens/AdminUserSignupFormScreen';
import AdminVendorDetailsScreen from './screens/AdminVendorDetailsScreen';
import AdminVendorSignupScreen from './screens/AdminVendorSignupScreen';
import ContactUsScreen from './screens/ContactUsScreen';
import CreateAnOfferScreen from './screens/CreateAnOfferScreen';
import CreateBusinessLocationScreen from './screens/CreateBusinessLocationScreen';
import EmailVerificationInfluencerScreen from './screens/EmailVerificationInfluencerScreen';
import EmailVerificationUserScreen from './screens/EmailVerificationUserScreen';
import EmailVerificationVendorScreen from './screens/EmailVerificationVendorScreen';
import InfluencerDashboardScreen from './screens/InfluencerDashboardScreen';
import InfluencerLoginScreen from './screens/InfluencerLoginScreen';
import InfluencerSignupFormScreen from './screens/InfluencerSignupFormScreen';
import ManageAccountAdminScreen from './screens/ManageAccountAdminScreen';
import ManageAccountInfluencerScreen from './screens/ManageAccountInfluencerScreen';
import ManageAccountUserScreen from './screens/ManageAccountUserScreen';
import ManageAccountVendorScreen from './screens/ManageAccountVendorScreen';
import ManageBusinessLocationScreen from './screens/ManageBusinessLocationScreen';
import MarketplaceItemDetailsScreen from './screens/MarketplaceItemDetailsScreen';
import MarketplaceScreen from './screens/MarketplaceScreen';
import PaywallScreen from './screens/PaywallScreen';
import PodcastScreen from './screens/PodcastScreen';
import UserDashboardScreen from './screens/UserDashboardScreen';
import UserHomeScreen from './screens/UserHomeScreen';
import UserLoginScreen from './screens/UserLoginScreen';
import UserSignupFormScreen from './screens/UserSignupFormScreen';
import VendorDashboardScreen from './screens/VendorDashboardScreen';
import VendorHomeScreen from './screens/VendorHomeScreen';
import VendorLoginScreen from './screens/VendorLoginScreen';
import VendorOfferDetailsScreen from './screens/VendorOfferDetailsScreen';
import VendorOffersScreen from './screens/VendorOffersScreen';
import VendorSignupFormScreen from './screens/VendorSignupFormScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

export default function RootAppNavigator() {
  const Constants = GlobalVariables.useValues();
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <Stack.Navigator
        initialRouteName="UserHomeScreen"
        presentation="modal"
        screenOptions={({ navigation }) => ({ headerShown: false })}
      >
        <Stack.Screen
          name="VendorDashboardScreen"
          component={VendorDashboardScreen}
          options={({ navigation }) => ({
            title: 'Vendor Dashboard',
          })}
        />
        <Stack.Screen
          name="UserSignupFormScreen"
          component={UserSignupFormScreen}
          options={({ navigation }) => ({
            title: 'User Signup Form',
          })}
        />
        <Stack.Screen
          name="VendorHomeScreen"
          component={VendorHomeScreen}
          options={({ navigation }) => ({
            title: 'Vendor Home',
          })}
        />
        <Stack.Screen
          name="VendorSignupFormScreen"
          component={VendorSignupFormScreen}
          options={({ navigation }) => ({
            title: 'Vendor Signup Form',
          })}
        />
        <Stack.Screen
          name="CreateAnOfferScreen"
          component={CreateAnOfferScreen}
          options={({ navigation }) => ({
            title: 'Create an Offer',
          })}
        />
        <Stack.Screen
          name="PaywallScreen"
          component={PaywallScreen}
          options={({ navigation }) => ({
            title: 'Paywall',
          })}
        />
        <Stack.Screen
          name="ManageAccountVendorScreen"
          component={ManageAccountVendorScreen}
          options={({ navigation }) => ({
            title: 'Manage Account Vendor',
          })}
        />
        <Stack.Screen
          name="VendorOffersScreen"
          component={VendorOffersScreen}
          options={({ navigation }) => ({
            title: 'Vendor Offers',
          })}
        />
        <Stack.Screen
          name="CreateBusinessLocationScreen"
          component={CreateBusinessLocationScreen}
          options={({ navigation }) => ({
            title: 'Create Business Location',
          })}
        />
        <Stack.Screen
          name="VendorOfferDetailsScreen"
          component={VendorOfferDetailsScreen}
          options={({ navigation }) => ({
            title: 'Vendor Offer Details ',
          })}
        />
        <Stack.Screen
          name="ManageBusinessLocationScreen"
          component={ManageBusinessLocationScreen}
          options={({ navigation }) => ({
            title: 'Manage Business Location',
          })}
        />
        <Stack.Screen
          name="AdminDashboardScreen"
          component={AdminDashboardScreen}
          options={({ navigation }) => ({
            title: 'Admin Dashboard',
          })}
        />
        <Stack.Screen
          name="AdminVendorDetailsScreen"
          component={AdminVendorDetailsScreen}
          options={({ navigation }) => ({
            title: 'Admin Vendor Details',
          })}
        />
        <Stack.Screen
          name="UserDashboardScreen"
          component={UserDashboardScreen}
          options={({ navigation }) => ({
            title: 'User Dashboard',
          })}
        />
        <Stack.Screen
          name="EmailVerificationVendorScreen"
          component={EmailVerificationVendorScreen}
          options={({ navigation }) => ({
            title: 'Email Verification Vendor',
          })}
        />
        <Stack.Screen
          name="VendorLoginScreen"
          component={VendorLoginScreen}
          options={({ navigation }) => ({
            title: 'Vendor Login',
          })}
        />
        <Stack.Screen
          name="MarketplaceScreen"
          component={MarketplaceScreen}
          options={({ navigation }) => ({
            title: 'Marketplace',
          })}
        />
        <Stack.Screen
          name="ManageAccountUserScreen"
          component={ManageAccountUserScreen}
          options={({ navigation }) => ({
            title: 'Manage Account User',
          })}
        />
        <Stack.Screen
          name="ManageAccountAdminScreen"
          component={ManageAccountAdminScreen}
          options={({ navigation }) => ({
            title: 'Manage Account Admin',
          })}
        />
        <Stack.Screen
          name="UserHomeScreen"
          component={UserHomeScreen}
          options={({ navigation }) => ({
            title: 'User Home',
          })}
        />
        <Stack.Screen
          name="UserLoginScreen"
          component={UserLoginScreen}
          options={({ navigation }) => ({
            title: 'User Login ',
          })}
        />
        <Stack.Screen
          name="PodcastScreen"
          component={PodcastScreen}
          options={({ navigation }) => ({
            title: 'Podcast',
          })}
        />
        <Stack.Screen
          name="ContactUsScreen"
          component={ContactUsScreen}
          options={({ navigation }) => ({
            title: 'Contact Us',
          })}
        />
        <Stack.Screen
          name="EmailVerificationUserScreen"
          component={EmailVerificationUserScreen}
          options={({ navigation }) => ({
            title: 'Email Verification User',
          })}
        />
        <Stack.Screen
          name="AdminVendorSignupScreen"
          component={AdminVendorSignupScreen}
          options={({ navigation }) => ({
            title: 'Admin Vendor Signup',
          })}
        />
        <Stack.Screen
          name="AdminOfferManagementScreen"
          component={AdminOfferManagementScreen}
          options={({ navigation }) => ({
            title: 'Admin Offer Management',
          })}
        />
        <Stack.Screen
          name="AdminUserSignupFormScreen"
          component={AdminUserSignupFormScreen}
          options={({ navigation }) => ({
            title: 'Admin User Signup Form',
          })}
        />
        <Stack.Screen
          name="InfluencerSignupFormScreen"
          component={InfluencerSignupFormScreen}
          options={({ navigation }) => ({
            title: 'Influencer Signup Form',
          })}
        />
        <Stack.Screen
          name="AdminInfluencerSignupFormScreen"
          component={AdminInfluencerSignupFormScreen}
          options={({ navigation }) => ({
            title: 'Admin Influencer Signup Form',
          })}
        />
        <Stack.Screen
          name="InfluencerDashboardScreen"
          component={InfluencerDashboardScreen}
          options={({ navigation }) => ({
            title: 'Influencer Dashboard',
          })}
        />
        <Stack.Screen
          name="MarketplaceItemDetailsScreen"
          component={MarketplaceItemDetailsScreen}
          options={({ navigation }) => ({
            title: 'Marketplace Item Details',
          })}
        />
        <Stack.Screen
          name="AdminInfluencerDetailsScreen"
          component={AdminInfluencerDetailsScreen}
          options={({ navigation }) => ({
            title: 'Admin Influencer Details',
          })}
        />
        <Stack.Screen
          name="ManageAccountInfluencerScreen"
          component={ManageAccountInfluencerScreen}
          options={({ navigation }) => ({
            title: 'Manage Account Influencer',
          })}
        />
        <Stack.Screen
          name="AdminUserDetailsScreen"
          component={AdminUserDetailsScreen}
          options={({ navigation }) => ({
            title: 'Admin User Details',
          })}
        />
        <Stack.Screen
          name="EmailVerificationInfluencerScreen"
          component={EmailVerificationInfluencerScreen}
          options={({ navigation }) => ({
            title: 'Email Verification Influencer',
          })}
        />
        <Stack.Screen
          name="InfluencerLoginScreen"
          component={InfluencerLoginScreen}
          options={({ navigation }) => ({
            title: 'Influencer Login',
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
