export default {
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  JustAnotherHand_400Regular: require('../assets/fonts/JustAnotherHand_400Regular.ttf'),
  JustAnotherHand_400Regular: require('../assets/fonts/JustAnotherHand_400Regular.ttf'),
  JustAnotherHand_400Regular: require('../assets/fonts/JustAnotherHand_400Regular.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_300Light: require('../assets/fonts/Lato_300Light.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_300Light: require('../assets/fonts/Lato_300Light.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_300Light: require('../assets/fonts/Lato_300Light.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_300Light: require('../assets/fonts/Lato_300Light.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_300Light: require('../assets/fonts/Lato_300Light.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_300Light: require('../assets/fonts/Lato_300Light.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_300Light: require('../assets/fonts/Lato_300Light.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Lato_900Black: require('../assets/fonts/Lato_900Black.ttf'),
  Lato_400Regular: require('../assets/fonts/Lato_400Regular.ttf'),
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_300Light: require('../assets/fonts/Oswald_300Light.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_700Bold: require('../assets/fonts/Oswald_700Bold.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_300Light: require('../assets/fonts/Oswald_300Light.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_300Light: require('../assets/fonts/Oswald_300Light.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_300Light: require('../assets/fonts/Oswald_300Light.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_300Light: require('../assets/fonts/Oswald_300Light.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_600SemiBold: require('../assets/fonts/Oswald_600SemiBold.ttf'),
  Oswald_300Light: require('../assets/fonts/Oswald_300Light.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_600SemiBold: require('../assets/fonts/Oswald_600SemiBold.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_700Bold: require('../assets/fonts/Oswald_700Bold.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_300Light: require('../assets/fonts/Oswald_300Light.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_300Light: require('../assets/fonts/Oswald_300Light.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_300Light: require('../assets/fonts/Oswald_300Light.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_300Light: require('../assets/fonts/Oswald_300Light.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_300Light: require('../assets/fonts/Oswald_300Light.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_600SemiBold: require('../assets/fonts/Oswald_600SemiBold.ttf'),
  Oswald_300Light: require('../assets/fonts/Oswald_300Light.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_500Medium: require('../assets/fonts/Oswald_500Medium.ttf'),
  Oswald_600SemiBold: require('../assets/fonts/Oswald_600SemiBold.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Poppins_400Regular: require('../assets/fonts/Poppins_400Regular.ttf'),
  Poppins_400Regular: require('../assets/fonts/Poppins_400Regular.ttf'),
  Poppins_400Regular: require('../assets/fonts/Poppins_400Regular.ttf'),
  Poppins_400Regular: require('../assets/fonts/Poppins_400Regular.ttf'),
  Poppins_400Regular: require('../assets/fonts/Poppins_400Regular.ttf'),
  Poppins_400Regular: require('../assets/fonts/Poppins_400Regular.ttf'),
  Poppins_400Regular: require('../assets/fonts/Poppins_400Regular.ttf'),
  Poppins_400Regular: require('../assets/fonts/Poppins_400Regular.ttf'),
  Poppins_400Regular: require('../assets/fonts/Poppins_400Regular.ttf'),
  Poppins_400Regular: require('../assets/fonts/Poppins_400Regular.ttf'),
  Poppins_400Regular: require('../assets/fonts/Poppins_400Regular.ttf'),
  Poppins_400Regular: require('../assets/fonts/Poppins_400Regular.ttf'),
  Poppins_500Medium: require('../assets/fonts/Poppins_500Medium.ttf'),
  Poppins_600SemiBold: require('../assets/fonts/Poppins_600SemiBold.ttf'),
};
