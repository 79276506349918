import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as StripeApi from '../apis/StripeApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  CheckboxRow,
  Icon,
  IconButton,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import * as Linking from 'expo-linking';
import { Image, Text, View } from 'react-native';

const PaywallScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [checkboxRowBasicValue, setCheckboxRowBasicValue] = React.useState('');
  const [checkboxRowValue, setCheckboxRowValue] = React.useState('');
  const [checkboxRowValue2, setCheckboxRowValue2] = React.useState('');
  const [checkboxRowValue3, setCheckboxRowValue3] = React.useState('');
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState('month');
  const [stripe_plan, setStripe_plan] = React.useState('');
  const [unCheckboxRowBasicValue, setUnCheckboxRowBasicValue] =
    React.useState('');
  const stripeAdvPackaageMonthlyPOST = StripeApi.useAdvPackaageMonthlyPOST();
  const stripe5MonthAdvPOST = StripeApi.use5MonthAdvPOST();

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
    >
      {/* Desktop Menu Special 2 */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
                flexDirection: {
                  minWidth: Breakpoints.Laptop,
                  value: 'column',
                },
                height: { minWidth: Breakpoints.Laptop, value: 119.6 },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'center',
                },
                paddingBottom: { minWidth: Breakpoints.Laptop, value: 15 },
                paddingTop: { minWidth: Breakpoints.Laptop, value: 15 },
                width: {
                  minWidth: Breakpoints.Laptop,
                  value: dimensions.width,
                },
              },
              dimensions.width
            )}
          >
            {/* Header Container */}
            <>
              {Constants['user_ID'] || Constants['vendor_ID'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '55.9%' },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '27.06%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorSignupFormScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserSignupFormScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Sign Up'}
                    />
                    {/* Button 3 */}
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorLoginScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserLoginScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            backgroundColor: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Secondary'],
                            },
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            marginLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 10,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Login'}
                    />
                  </View>
                </View>
              )}
            </>
            {/* Header Container Signed In */}
            <>
              {!(Constants['user_ID'] || Constants['vendor_ID']) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '69.43%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 2 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('MarketplaceScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Marketplace'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 5 */}
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorDashboardScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          if (Constants['Admin']) {
                            navigation.navigate('AdminDashboardScreen');
                          }
                          if (Constants['Admin']) {
                            return;
                          }
                          navigation.navigate('UserDashboardScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Secondary'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'My Account'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '13.53%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc'] || Constants['Admin']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          navigation.navigate('UserHomeScreen');
                          setGlobalVariableValue({
                            key: 'vendor_ID',
                            value: '',
                          });
                          setGlobalVariableValue({
                            key: 'user_ID',
                            value: '',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Logout'}
                    />
                  </View>
                </View>
              )}
            </>
          </View>
        )}
      </>
      {/* Mobile Menu Opener */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Background'],
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-between',
                marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
                paddingLeft: 16,
                paddingRight: 16,
              },
              dimensions.width
            )}
          >
            <View>
              <Image
                resizeMode={'contain'}
                source={Images.BevyLogo}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ImageStyles(theme)['Image'],
                  dimensions.width
                )}
              />
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', zIndex: 1 },
                dimensions.width
              )}
            ></View>
          </View>
        )}
      </>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
              },
              dimensions.width
            )}
          >
            {/* Back */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: 48,
                  justifyContent: 'center',
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Icon name={'AntDesign/arrowleft'} size={24} />
              </Touchable>
            </View>
            {/* Screen Heading */}
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 15,
                },
                dimensions.width
              )}
            >
              {null}
            </Text>
            {/* Blank */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: 48,
                  justifyContent: 'center',
                  width: 48,
                },
                dimensions.width
              )}
            />
          </View>
        )}
      </>
      {/* View 2 */}
      <View
        style={StyleSheet.applyWidth(
          {
            paddingLeft: { minWidth: Breakpoints.Laptop, value: '20%' },
            paddingRight: { minWidth: Breakpoints.Laptop, value: '20%' },
          },
          dimensions.width
        )}
      >
        {/* Top View */}
        <View
          style={StyleSheet.applyWidth(
            {
              paddingBottom: 5,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 16,
            },
            dimensions.width
          )}
        >
          {/* Logo */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderRadius: 10,
                justifyContent: 'center',
                marginBottom: 20,
                opacity: 1,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center' },
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                source={Images.BevyLogo}
                style={StyleSheet.applyWidth(
                  { height: 30, width: 30 },
                  dimensions.width
                )}
              />
              {/* Dating Pro */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_7'],
                    fontFamily: 'Poppins_600SemiBold',
                    fontSize: 17,
                    marginLeft: 8,
                    marginTop: 6,
                  },
                  dimensions.width
                )}
              >
                {'Soci-DataMembership'}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={StyleSheet.applyWidth(
            { backgroundColor: theme.colors['Secondary'], padding: 15 },
            dimensions.width
          )}
        >
          {/* Description */}
          <Text
            style={StyleSheet.applyWidth(
              {
                color: theme.colors['Strong Inverse'],
                fontFamily: 'Lato_700Bold',
                fontSize: 16,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {
              'To publish an offer you much have a paid soci-data membership. Join the hive today!'
            }
          </Text>
        </View>
        {/* Plans */}
        <View
          style={StyleSheet.applyWidth(
            {
              flex: 1,
              marginBottom: 20,
              paddingLeft: [
                { minWidth: Breakpoints.Laptop, value: 40 },
                { minWidth: Breakpoints.Desktop, value: '10%' },
              ],
              paddingRight: [
                { minWidth: Breakpoints.Laptop, value: 40 },
                { minWidth: Breakpoints.Desktop, value: '10%' },
              ],
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth({ padding: 20 }, dimensions.width)}
          >
            {/* Description 3 */}
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Custom Color_6'],
                  fontFamily: 'Lato_400Regular',
                  fontSize: 14,
                },
                dimensions.width
              )}
            >
              {
                '•     Access to Deal & Coupon Create\n•     Access to User Data and Analytics\n•     Marketing Exposure in the Social Bevy Marketplace\n•     Only Pay 99 Cents Per Customer Acquired '
              }
            </Text>
          </View>
          {/* Yearly 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Custom Color_3'],
                borderRadius: 10,
                height: 58,
                justifyContent: 'center',
                marginLeft: 20,
                marginRight: 20,
                marginTop: 25,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  borderRadius: 10,
                  flex: 1,
                  justifyContent: 'center',
                  paddingBottom: 2,
                  paddingTop: 2,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* unCheckbox Row Basic */}
              <CheckboxRow
                checkedIcon={'AntDesign/checksquare'}
                color={theme.colors['Primary']}
                direction={'row-reverse'}
                label={'$100.00/Yearly'}
                onCheck={() => {
                  try {
                    setCheckboxRowValue2(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                onPress={newUnCheckboxRowBasicValue => {
                  try {
                    setUnCheckboxRowBasicValue(newUnCheckboxRowBasicValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                status={unCheckboxRowBasicValue}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_6'],
                    fontFamily: 'Lato_700Bold',
                    fontSize: 14,
                    minHeight: 50,
                  },
                  dimensions.width
                )}
                uncheckedColor={theme.colors['Medium']}
                uncheckedIcon={'MaterialIcons/check-box-outline-blank'}
              />
              <View
                style={StyleSheet.applyWidth(
                  { justifyContent: 'center', left: 60, position: 'absolute' },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'Oswald_600SemiBold',
                      fontSize: 15,
                    },
                    dimensions.width
                  )}
                >
                  {'Soci-Data Basic'}
                </Text>
              </View>
            </View>
          </View>
          {/* Monthly */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Custom Color_3'],
                borderRadius: 10,
                height: 70,
                justifyContent: 'center',
                marginLeft: 20,
                marginRight: 20,
                marginTop: 25,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  borderRadius: 10,
                  flex: 1,
                  justifyContent: 'center',
                  paddingBottom: 2,
                  paddingTop: 2,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', height: 1 },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['Primary'],
                      borderRadius: 5,
                      height: 16,
                      paddingBottom: 2,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 2,
                      top: -6,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {'POPULAR'}
                  </Text>
                </View>
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { justifyContent: 'center' },
                  dimensions.width
                )}
              >
                <CheckboxRow
                  checkedIcon={'AntDesign/checksquare'}
                  color={theme.colors['Primary']}
                  direction={'row-reverse'}
                  label={'$500.00/Yearly'}
                  onCheck={() => {
                    try {
                      setUnCheckboxRowBasicValue(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onPress={newCheckboxRowValue => {
                    try {
                      setCheckboxRowValue2(newCheckboxRowValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  status={checkboxRowValue2}
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Custom Color_6'],
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 12,
                      minHeight: 50,
                    },
                    dimensions.width
                  )}
                  uncheckedColor={theme.colors['Medium']}
                  uncheckedIcon={
                    'MaterialCommunityIcons/checkbox-blank-outline'
                  }
                />
                <View
                  style={StyleSheet.applyWidth(
                    {
                      justifyContent: 'center',
                      left: 60,
                      position: 'absolute',
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 14,
                      },
                      dimensions.width
                    )}
                  >
                    {'Soci-Data Advanced'}
                  </Text>
                </View>
              </View>

              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Primary'],
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    height: 22,
                    justifyContent: 'center',
                  },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 10,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'User Data Analytics Included'}
                </Text>
              </View>
            </View>
            {/* unselected */}
            <>
              {!(selectedPlan !== 'month') ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setSelectedPlan('month');
                      setStripe_plan('');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    { height: '100%', width: '100%' },
                    dimensions.width
                  )}
                />
              )}
            </>
          </View>
        </View>
        {/* Footer */}
        <View
          style={StyleSheet.applyWidth(
            {
              flex: 1,
              justifyContent: 'flex-start',
              marginTop: 80,
              paddingBottom: 15,
              paddingLeft: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Desktop, value: '10%' },
              ],
              paddingRight: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Desktop, value: '10%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Continue */}
          <Button
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Primary'],
                borderRadius: 12,
                fontFamily: 'Oswald_500Medium',
                fontSize: 17,
                height: 48,
                textAlign: 'center',
              },
              dimensions.width
            )}
            title={'Confirm Purchase'}
          />
          {/* disclaimer */}
          <Text
            style={StyleSheet.applyWidth(
              {
                color: theme.colors['Custom Color_6'],
                fontFamily: 'Lato_400Regular',
                fontSize: 14,
                marginBottom: 20,
                marginTop: 20,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {
              'Subscription automatically renews unless auto-renew is turned off at least 24 hours before the current period ends. Payment is charged to your stripe account. Manage subscriptions and turn off auto-renewal in the manage account settings.'
            }
          </Text>
          {/* Clicks */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', justifyContent: 'space-between' },
              dimensions.width
            )}
          >
            {/* Restore */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'center',
                  paddingBottom: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Touchable>
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Custom Color_6'],
                      fontFamily: 'Lato_400Regular',
                      fontSize: 10,
                      textDecorationLine: 'underline',
                    },
                    dimensions.width
                  )}
                >
                  {'Restore'}
                </Text>
              </Touchable>
            </View>
            {/* Terms and Conditions */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'center',
                  paddingBottom: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Touchable>
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Custom Color_6'],
                      fontFamily: 'Lato_400Regular',
                      fontSize: 10,
                      textDecorationLine: 'underline',
                    },
                    dimensions.width
                  )}
                >
                  {'Terms and Conditions'}
                </Text>
              </Touchable>
            </View>
            {/* Privacy Policy */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'center',
                  paddingBottom: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Touchable>
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Custom Color_6'],
                      fontFamily: 'Lato_400Regular',
                      fontSize: 10,
                      textDecorationLine: 'underline',
                    },
                    dimensions.width
                  )}
                >
                  {'Privacy Policy'}
                </Text>
              </Touchable>
            </View>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(PaywallScreen);
