import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SocialBevyApi from '../apis/SocialBevyApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  CheckboxRow,
  CircularProgress,
  IconButton,
  NumberInput,
  Picker,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const CreateAnOfferScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [BflatRateOfferValue, setBflatRateOfferValue] = React.useState(false);
  const [BpercentageOfferValue, setBpercentageOfferValue] =
    React.useState(false);
  const [Offer_Image, setOffer_Image] = React.useState('');
  const [discountAmountValue, setDiscountAmountValue] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [locationID, setLocationID] = React.useState('');
  const [offerDescriptionValue, setOfferDescriptionValue] = React.useState('');
  const [offerNameValue, setOfferNameValue] = React.useState('');
  const [percentageDiscountValue, setPercentageDiscountValue] =
    React.useState('');
  const [priceValue, setPriceValue] = React.useState('');
  const [processLoader, setProcessLoader] = React.useState(false);
  const [usageCycleOptionsValue, setUsageCycleOptionsValue] =
    React.useState('');
  const [usagePerCycleValue, setUsagePerCycleValue] = React.useState('');
  const socialBevyCreateOfferPOST = SocialBevyApi.useCreateOfferPOST();

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
    >
      {/* Desktop Menu Special 2 */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
                flexDirection: {
                  minWidth: Breakpoints.Laptop,
                  value: 'column',
                },
                height: { minWidth: Breakpoints.Laptop, value: 119.6 },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'center',
                },
                paddingBottom: { minWidth: Breakpoints.Laptop, value: 15 },
                paddingTop: { minWidth: Breakpoints.Laptop, value: 15 },
                width: {
                  minWidth: Breakpoints.Laptop,
                  value: dimensions.width,
                },
              },
              dimensions.width
            )}
          >
            {/* Header Container */}
            <>
              {Constants['user_ID'] || Constants['vendor_ID'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '55.9%' },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '27.06%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorSignupFormScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserSignupFormScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Sign Up'}
                    />
                    {/* Button 3 */}
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorLoginScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserLoginScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            backgroundColor: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Secondary'],
                            },
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            marginLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 10,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Login'}
                    />
                  </View>
                </View>
              )}
            </>
            {/* Header Container Signed In */}
            <>
              {!(Constants['user_ID'] || Constants['vendor_ID']) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '69.43%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 2 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('MarketplaceScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Marketplace'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 5 */}
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorDashboardScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          if (Constants['Admin']) {
                            navigation.navigate('AdminDashboardScreen');
                          }
                          if (Constants['Admin']) {
                            return;
                          }
                          navigation.navigate('UserDashboardScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Secondary'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'My Account'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '13.53%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc'] || Constants['Admin']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          navigation.navigate('UserHomeScreen');
                          setGlobalVariableValue({
                            key: 'vendor_ID',
                            value: '',
                          });
                          setGlobalVariableValue({
                            key: 'user_ID',
                            value: '',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Logout'}
                    />
                  </View>
                </View>
              )}
            </>
          </View>
        )}
      </>
      {/* Mobile Menu Opener */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Background'],
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-between',
                marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
                paddingLeft: 16,
                paddingRight: 16,
              },
              dimensions.width
            )}
          >
            <View>
              <Image
                resizeMode={'contain'}
                source={Images.BevyLogo}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ImageStyles(theme)['Image'],
                  dimensions.width
                )}
              />
            </View>
          </View>
        )}
      </>
      {/* Mobile Header 2 */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: theme.colors['Secondary'],
            flexDirection: 'row',
            height: 100,
            justifyContent: [
              { minWidth: Breakpoints.Mobile, value: 'space-between' },
              { minWidth: Breakpoints.Laptop, value: 'flex-start' },
            ],
            marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
            paddingLeft: [
              { minWidth: Breakpoints.Mobile, value: 16 },
              { minWidth: Breakpoints.Laptop, value: '25%' },
            ],
            paddingRight: [
              { minWidth: Breakpoints.Mobile, value: 16 },
              { minWidth: Breakpoints.Laptop, value: '25%' },
            ],
          },
          dimensions.width
        )}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', zIndex: 1 },
            dimensions.width
          )}
        >
          {/* Icon Button 3 */}
          <IconButton
            icon={'AntDesign/leftcircle'}
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
            size={32}
            style={StyleSheet.applyWidth({ marginRight: 5 }, dimensions.width)}
          />
        </View>

        <View>
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Strong Inverse'],
                fontFamily: 'Lato_900Black',
                fontSize: 18,
                marginRight: 20,
              }),
              dimensions.width
            )}
          >
            {'Create An Offer'}
          </Text>
        </View>
      </View>

      <KeyboardAwareScrollView
        contentContainerStyle={StyleSheet.applyWidth(
          {
            alignItems: { minWidth: Breakpoints.BigScreen, value: 'center' },
            backgroundColor: [
              {
                minWidth: Breakpoints.Laptop,
                value: theme.colors['Secondary'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: theme.colors['Secondary'],
              },
            ],
            flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
          },
          dimensions.width
        )}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
      >
        <ImageBackground
          resizeMode={'cover'}
          source={Images.AdobeStock438035062}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
              {
                alignItems: [
                  { minWidth: Breakpoints.BigScreen, value: 'center' },
                  { minWidth: Breakpoints.Laptop, value: 'center' },
                  { minWidth: Breakpoints.Desktop, value: 'center' },
                ],
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors['Secondary'],
                },
                flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                height: { minWidth: Breakpoints.Laptop, value: '100%' },
                justifyContent: {
                  minWidth: Breakpoints.Desktop,
                  value: 'center',
                },
                padding: { minWidth: Breakpoints.Laptop, value: '12%' },
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              }
            ),
            dimensions.width
          )}
        >
          {/* Surface 2 */}
          <Surface
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.SurfaceStyles(theme)['Surface'], {
                borderBottomLeftRadius: {
                  minWidth: Breakpoints.Laptop,
                  value: 12,
                },
                borderBottomRightRadius: {
                  minWidth: Breakpoints.Laptop,
                  value: 12,
                },
                borderTopLeftRadius: {
                  minWidth: Breakpoints.Laptop,
                  value: 12,
                },
                borderTopRightRadius: {
                  minWidth: Breakpoints.Laptop,
                  value: 12,
                },
                marginTop: [
                  { minWidth: Breakpoints.Laptop, value: -100 },
                  { minWidth: Breakpoints.Desktop, value: -150 },
                  { minWidth: Breakpoints.BigScreen, value: -200 },
                ],
                padding: { minWidth: Breakpoints.BigScreen, value: 20 },
                width: [
                  { minWidth: Breakpoints.Laptop, value: '50%' },
                  { minWidth: Breakpoints.Desktop, value: '35%' },
                ],
              }),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { padding: { minWidth: Breakpoints.Laptop, value: '3%' } },
                dimensions.width
              )}
            >
              {/* Image */}
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        paddingBottom: 20,
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Surface
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Primary'],
                            borderRadius: 9,
                            justifyContent: 'center',
                            padding: 25,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* Image upload setup */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Background'],
                            borderRadius: 140,
                            padding: 25,
                            paddingBottom: 20,
                            paddingTop: 20,
                            width: [
                              { minWidth: Breakpoints.Mobile, value: '85%' },
                              { minWidth: Breakpoints.Tablet, value: '40%' },
                              { minWidth: Breakpoints.Laptop, value: '100%' },
                              { minWidth: Breakpoints.Desktop, value: '70%' },
                              { minWidth: Breakpoints.BigScreen, value: '50%' },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            const handler = async () => {
                              try {
                                const result = await openImagePickerUtil({
                                  mediaTypes: 'All',
                                  allowsEditing: true,
                                  quality: 0.2,
                                });

                                setOffer_Image(result);
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                        >
                          <View>
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center' },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                allowFontScaling={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Primary'],
                                      fontFamily: 'Oswald_400Regular',
                                      fontSize: 18,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Upload Offer Picture'}
                              </Text>
                            </View>
                            <>
                              {Offer_Image ? null : (
                                <Image
                                  resizeMode={'cover'}
                                  source={Images.BevyBee}
                                  style={StyleSheet.applyWidth(
                                    { height: 200, width: 200 },
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                            <>
                              {!Offer_Image ? null : (
                                <Image
                                  resizeMode={'cover'}
                                  source={{ uri: `${Offer_Image}` }}
                                  style={StyleSheet.applyWidth(
                                    { height: 200, width: 200 },
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                            <>
                              {!Offer_Image ? null : (
                                <IconButton
                                  color={theme.colors['Primary']}
                                  icon={'AntDesign/closecircle'}
                                  onPress={() => {
                                    try {
                                      setOffer_Image('');
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  size={42}
                                  style={StyleSheet.applyWidth(
                                    {
                                      bottom: 0,
                                      position: 'absolute',
                                      right: 0,
                                      zIndex: 5,
                                    },
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                            <>
                              {Offer_Image ? null : (
                                <Image
                                  resizeMode={'cover'}
                                  source={Images.Camera}
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderColor: theme.colors['Secondary'],
                                      borderRadius: 120,
                                      borderWidth: 0.5,
                                      bottom: 0,
                                      height: 48,
                                      position: 'absolute',
                                      right: 0,
                                      width: 48,
                                      zIndex: 2,
                                    },
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                          </View>
                        </Touchable>
                      </View>
                    </Surface>
                  </View>
                )}
              </>
              {/* Name */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: [
                      { minWidth: Breakpoints.Mobile, value: 60 },
                      { minWidth: Breakpoints.Laptop, value: 40 },
                    ],
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* Offer Name */}
                <TextInput
                  editable={true}
                  onChangeText={newOfferNameValue => {
                    try {
                      setGlobalVariableValue({
                        key: 'Name',
                        value: newOfferNameValue,
                      });
                      setOfferNameValue(newOfferNameValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'ex. Coffee Discount '}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 0.5,
                      color: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Secondary'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Secondary'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'Lato_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'Lato_400Regular',
                        },
                      ],
                      fontSize: 15,
                      height: 48,
                      paddingBottom: 8,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={offerNameValue}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { left: 0, position: 'absolute', top: -15 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Offer Name'}
                  </Text>
                </View>
              </View>
              {/* Name 3 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: [
                      { minWidth: Breakpoints.Mobile, value: 60 },
                      { minWidth: Breakpoints.Laptop, value: 40 },
                    ],
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 25,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* Offer Description */}
                <TextInput
                  editable={true}
                  onChangeText={newOfferDescriptionValue => {
                    try {
                      setGlobalVariableValue({
                        key: 'Name',
                        value: newOfferDescriptionValue,
                      });
                      setOfferDescriptionValue(newOfferDescriptionValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'ex. try out amazing coffee at a discount..'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 0.5,
                      color: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Secondary'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Secondary'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'Lato_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'Lato_400Regular',
                        },
                      ],
                      fontSize: 15,
                      height: 48,
                      paddingBottom: 8,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={offerDescriptionValue}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { left: 0, position: 'absolute', top: -15 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Offer Description'}
                  </Text>
                </View>
              </View>
              {/* Name 4 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: [
                      { minWidth: Breakpoints.Laptop, value: 70 },
                      { minWidth: Breakpoints.Mobile, value: 80 },
                    ],
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                      },
                      dimensions.width
                    )}
                  >
                    {'Offer Location'}
                  </Text>
                </View>

                <SocialBevyApi.FetchGetLocationGET
                  handlers={{
                    onData: fetchData => {
                      try {
                        setLocationID(fetchData?.id);
                      } catch (err) {
                        console.error(err);
                      }
                    },
                  }}
                  vendor_id={props.route?.params?.CurrentID ?? 15}
                >
                  {({ loading, error, data, refetchGetLocation }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Lato_400Regular',
                              fontSize: 16,
                              marginTop: 20,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {fetchData?.location_name}
                      </Text>
                    );
                  }}
                </SocialBevyApi.FetchGetLocationGET>
              </View>
              {/* Name 5 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: 100,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginBottom: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {'Discount Type'}
                  </Text>
                </View>

                <View
                  style={StyleSheet.applyWidth(
                    { backgroundColor: theme.colors['Background'] },
                    dimensions.width
                  )}
                >
                  {/* Flat Rate Offer */}
                  <CheckboxRow
                    label={'Flat Rate Offer'}
                    onPress={newFlatRateOfferValue => {
                      try {
                        setBflatRateOfferValue(true);
                        setBpercentageOfferValue(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    status={BflatRateOfferValue}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.CheckboxRowStyles(theme)['Checkbox Row'],
                      dimensions.width
                    )}
                  />
                </View>
              </View>
              {/* Name 7 */}
              <>
                {!BpercentageOfferValue ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Custom Color_3'],
                        height: 60,
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 25,
                        paddingLeft: 20,
                        paddingRight: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Percentage Discount */}
                      <NumberInput
                        allowFontScaling={true}
                        changeTextDelay={500}
                        maxLength={3}
                        onChangeText={newPercentageDiscountValue => {
                          try {
                            setPercentageDiscountValue(
                              newPercentageDiscountValue
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Enter a Number'}
                        placeholderTextColor={theme.colors['Medium']}
                        returnKeyType={'next'}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.NumberInputStyles(theme)[
                              'Number Input'
                            ],
                            {
                              borderBottomWidth: 0.5,
                              borderColor: theme.colors['Secondary'],
                              borderLeftWidth: 0,
                              borderRadius: 0,
                              borderRightWidth: 0,
                              borderTopWidth: 0,
                              fontFamily: 'Lato_400Regular',
                              fontSize: 15,
                              height: 48,
                              paddingLeft: 20,
                              paddingRight: 20,
                            }
                          ),
                          dimensions.width
                        )}
                        value={percentageDiscountValue}
                      />
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { fontFamily: 'Lato_700Bold', fontSize: 18 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'%'}
                      </Text>
                    </View>

                    <View
                      style={StyleSheet.applyWidth(
                        { position: 'absolute', top: -15 },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Primary'],
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Primary'],
                              },
                            ],
                            fontFamily: 'Oswald_400Regular',
                            fontSize: 16,
                            marginLeft: 20,
                          },
                          dimensions.width
                        )}
                      >
                        {'Percentage Discount'}
                      </Text>
                    </View>
                  </View>
                )}
              </>
              {/* Name 8 */}
              <>
                {!BflatRateOfferValue ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Custom Color_3'],
                        height: 60,
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 25,
                        paddingLeft: 20,
                        paddingRight: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { fontFamily: 'Lato_700Bold', fontSize: 18 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'$'}
                      </Text>
                      {/* Discount Amount */}
                      <NumberInput
                        allowFontScaling={true}
                        changeTextDelay={500}
                        onChangeText={newDiscountAmountValue => {
                          try {
                            setDiscountAmountValue(newDiscountAmountValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Enter a dicount amount. Ex. 5.00'}
                        placeholderTextColor={theme.colors['Medium']}
                        returnKeyType={'next'}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.NumberInputStyles(theme)[
                              'Number Input'
                            ],
                            {
                              borderBottomWidth: 0.5,
                              borderColor: theme.colors['Secondary'],
                              borderLeftWidth: 0,
                              borderRadius: 0,
                              borderRightWidth: 0,
                              borderTopWidth: 0,
                              fontFamily: 'Lato_400Regular',
                              fontSize: 15,
                              height: 48,
                              paddingLeft: 20,
                              paddingRight: 20,
                              width: '80%',
                            }
                          ),
                          dimensions.width
                        )}
                        value={discountAmountValue}
                      />
                    </View>

                    <View
                      style={StyleSheet.applyWidth(
                        { position: 'absolute', top: -15 },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Primary'],
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['Primary'],
                              },
                            ],
                            fontFamily: 'Oswald_400Regular',
                            fontSize: 16,
                            marginLeft: 20,
                          },
                          dimensions.width
                        )}
                      >
                        {'Discount Amount'}
                      </Text>
                    </View>
                  </View>
                )}
              </>
              {/* Name 9 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: 100,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                <View>
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginBottom: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {'Select A Usage Cycle'}
                  </Text>
                </View>
                {/* Usage Cycle Options */}
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background}
                  dropDownBorderColor={theme.colors.divider}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newUsageCycleOptionsValue => {
                    try {
                      setUsageCycleOptionsValue(newUsageCycleOptionsValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  options={['Daily', 'Weekly', 'Monthly', 'Yearly']}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  value={usageCycleOptionsValue}
                />
              </View>
              {/* Name 10 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: [
                      { minWidth: Breakpoints.Mobile, value: 60 },
                      { minWidth: Breakpoints.Laptop, value: 40 },
                    ],
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 25,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* Usage Per Cycle */}
                <NumberInput
                  allowFontScaling={true}
                  changeTextDelay={500}
                  onChangeText={newUsagePerCycleValue => {
                    try {
                      setUsagePerCycleValue(newUsagePerCycleValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter a Number'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.NumberInputStyles(theme)['Number Input'],
                      {
                        borderBottomWidth: 0.5,
                        borderColor: theme.colors['Secondary'],
                        borderLeftWidth: 0,
                        borderRadius: 0,
                        borderRightWidth: 0,
                        borderTopWidth: 0,
                        fontFamily: 'Lato_400Regular',
                        fontSize: 15,
                        height: 48,
                        paddingLeft: 20,
                        paddingRight: 20,
                      }
                    ),
                    dimensions.width
                  )}
                  value={usagePerCycleValue}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', top: -15 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Max Usage Per User Within Cycle'}
                  </Text>
                </View>
              </View>
              {/* Name 11 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: [
                      { minWidth: Breakpoints.Laptop, value: 40 },
                      { minWidth: Breakpoints.Mobile, value: 60 },
                    ],
                    marginBottom: { minWidth: Breakpoints.Laptop, value: 25 },
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 25,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* Price */}
                <NumberInput
                  allowFontScaling={true}
                  changeTextDelay={500}
                  onChangeText={newPriceValue => {
                    try {
                      setPriceValue(newPriceValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter a price. ex 30.00'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.NumberInputStyles(theme)['Number Input'],
                      {
                        borderBottomWidth: 0.5,
                        borderColor: theme.colors['Secondary'],
                        borderLeftWidth: 0,
                        borderRadius: 0,
                        borderRightWidth: 0,
                        borderTopWidth: 0,
                        fontFamily: 'Lato_400Regular',
                        fontSize: 15,
                        height: 48,
                        paddingLeft: 20,
                        paddingRight: 20,
                      }
                    ),
                    dimensions.width
                  )}
                  value={priceValue}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', top: -15 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Price'}
                  </Text>
                </View>
              </View>
              {/* View 2 */}
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Error'],
                            fontFamily: 'Lato_400Regular',
                            marginTop: 10,
                            paddingLeft: 30,
                            paddingRight: 30,
                            textDecorationLine: 'underline',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {errorMsg}
                    </Text>
                    {/* Continue */}
                    <Button
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const offerResult = (
                              await socialBevyCreateOfferPOST.mutateAsync({
                                Offer_Image: Offer_Image,
                                Price: priceValue,
                                Usage_cycles: usageCycleOptionsValue,
                                offer_amount_percent: percentageDiscountValue,
                                offer_amount_rate: discountAmountValue,
                                offer_description: offerDescriptionValue,
                                offer_name: offerNameValue,
                                usage_limit_per_cycle: usagePerCycleValue,
                                vendor_id: props.route?.params?.CurrentID ?? 15,
                                vendor_locations_id: locationID,
                              })
                            )?.json;
                            const errorResult = offerResult?.message;
                            setErrorMsg(errorResult);
                            if (errorResult) {
                              return;
                            }
                            setProcessLoader(true);
                            navigation.navigate('VendorOffersScreen');
                            setProcessLoader(false);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: theme.colors['Primary'],
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Primary'],
                            },
                          ],
                          borderRadius: 12,
                          fontFamily: 'Oswald_400Regular',
                          fontSize: 17,
                          height: 48,
                          marginBottom: 10,
                          marginLeft: 20,
                          marginRight: 20,
                          marginTop: [
                            { minWidth: Breakpoints.Laptop, value: 20 },
                            { minWidth: Breakpoints.Mobile, value: 10 },
                          ],
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={'Publish Offer'}
                    />
                    <>
                      {!processLoader ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              height: 40,
                              justifyContent: 'center',
                              paddingLeft: 30,
                              paddingRight: 30,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            allowFontScaling={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  fontFamily: 'Lato_700Bold',
                                  fontSize: 16,
                                  marginRight: 20,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Processing Request'}
                          </Text>
                          <CircularProgress
                            animationDuration={900}
                            color={theme.colors.primary}
                            indeterminate={true}
                            isAnimated={true}
                            lineCap={'round'}
                            maximumValue={100}
                            showTrack={true}
                            startPosition={'top'}
                            style={StyleSheet.applyWidth(
                              { height: 30, width: 30 },
                              dimensions.width
                            )}
                            thickness={3}
                            trackColor={theme.colors.divider}
                            trackLineCap={'round'}
                          />
                        </View>
                      )}
                    </>
                    {/* Continue 2 */}
                    <Button
                      onPress={() => {
                        try {
                          navigation.goBack();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Secondary'],
                          borderRadius: 12,
                          fontFamily: 'Oswald_400Regular',
                          fontSize: 17,
                          height: 48,
                          marginBottom: 10,
                          marginLeft: 20,
                          marginRight: 20,
                          marginTop: [
                            { minWidth: Breakpoints.Laptop, value: 20 },
                            { minWidth: Breakpoints.Mobile, value: 20 },
                          ],
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={'Cancel & Return To Dashboard'}
                    />
                  </View>
                )}
              </>
            </View>
          </Surface>
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <Surface
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'],
                    {
                      borderBottomRightRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: 12,
                      },
                      borderTopRightRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: 12,
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'column',
                      },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'flex-start',
                      },
                      marginTop: [
                        { minWidth: Breakpoints.Desktop, value: -150 },
                        { minWidth: Breakpoints.BigScreen, value: -200 },
                        { minWidth: Breakpoints.Laptop, value: -100 },
                      ],
                      padding: [
                        { minWidth: Breakpoints.BigScreen, value: 20 },
                        { minWidth: Breakpoints.Laptop, value: '2%' },
                      ],
                      width: [
                        { minWidth: Breakpoints.Laptop, value: '50%' },
                        { minWidth: Breakpoints.Desktop, value: '45%' },
                        { minWidth: Breakpoints.BigScreen, value: '35%' },
                      ],
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { width: { minWidth: Breakpoints.Laptop, value: '100%' } },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Lato_700Bold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 32 },
                          padding: { minWidth: Breakpoints.Laptop, value: 20 },
                          textAlign: {
                            minWidth: Breakpoints.Laptop,
                            value: 'center',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Set Up Offers For Your Products'}
                  </Text>
                  {/* Image 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        paddingBottom: 20,
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Surface
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'],
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Primary'],
                            borderRadius: 9,
                            justifyContent: 'center',
                            padding: 25,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* Image upload setup */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Background'],
                            borderRadius: 140,
                            padding: 25,
                            paddingBottom: 20,
                            paddingTop: 20,
                            width: [
                              { minWidth: Breakpoints.Mobile, value: '85%' },
                              { minWidth: Breakpoints.Tablet, value: '40%' },
                              { minWidth: Breakpoints.Laptop, value: '100%' },
                              { minWidth: Breakpoints.Desktop, value: '70%' },
                              { minWidth: Breakpoints.BigScreen, value: '70%' },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            const handler = async () => {
                              try {
                                const result = await openImagePickerUtil({
                                  mediaTypes: 'All',
                                  allowsEditing: true,
                                  quality: 0.2,
                                });

                                setOffer_Image(result);
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                        >
                          <View>
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center' },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                allowFontScaling={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: theme.colors['Primary'],
                                      fontFamily: 'Oswald_400Regular',
                                      fontSize: 18,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Upload Offer Picture'}
                              </Text>
                            </View>
                            <>
                              {Offer_Image ? null : (
                                <Image
                                  resizeMode={'cover'}
                                  source={Images.BevyBee}
                                  style={StyleSheet.applyWidth(
                                    { height: 200, width: 200 },
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                            <>
                              {!Offer_Image ? null : (
                                <Image
                                  resizeMode={'cover'}
                                  source={{ uri: `${Offer_Image}` }}
                                  style={StyleSheet.applyWidth(
                                    { height: 200, width: 200 },
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                            <>
                              {!Offer_Image ? null : (
                                <IconButton
                                  color={theme.colors['Primary']}
                                  icon={'AntDesign/closecircle'}
                                  onPress={() => {
                                    try {
                                      setOffer_Image('');
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  size={42}
                                  style={StyleSheet.applyWidth(
                                    {
                                      bottom: 0,
                                      position: 'absolute',
                                      right: 0,
                                      zIndex: 5,
                                    },
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                            <>
                              {Offer_Image ? null : (
                                <Image
                                  resizeMode={'cover'}
                                  source={Images.Camera}
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderColor: theme.colors['Secondary'],
                                      borderRadius: 120,
                                      borderWidth: 0.5,
                                      bottom: 0,
                                      height: 48,
                                      position: 'absolute',
                                      right: 0,
                                      width: 48,
                                      zIndex: 2,
                                    },
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                          </View>
                        </Touchable>
                      </View>
                    </Surface>
                  </View>
                  {/* View 3 */}
                  <>
                    {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: theme.colors['Error'],
                                fontFamily: 'Lato_400Regular',
                                marginTop: 10,
                                paddingLeft: 30,
                                paddingRight: 30,
                                textDecorationLine: 'underline',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {errorMsg}
                        </Text>
                        {/* Continue */}
                        <Button
                          onPress={() => {
                            const handler = async () => {
                              try {
                                const offerResult = (
                                  await socialBevyCreateOfferPOST.mutateAsync({
                                    Offer_Image: Offer_Image,
                                    Price: priceValue,
                                    Usage_cycles: usageCycleOptionsValue,
                                    offer_amount_percent:
                                      percentageDiscountValue,
                                    offer_amount_rate: discountAmountValue,
                                    offer_description: offerDescriptionValue,
                                    offer_name: offerNameValue,
                                    usage_limit_per_cycle: usagePerCycleValue,
                                    vendor_id: Constants['vendor_ID'],
                                    vendor_locations_id: locationID,
                                  })
                                )?.json;
                                const errorResult = offerResult?.message;
                                setErrorMsg(errorResult);
                                if (errorResult) {
                                  return;
                                }
                                setProcessLoader(true);
                                navigation.navigate('VendorOffersScreen');
                                setProcessLoader(false);
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: theme.colors['Primary'],
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                              ],
                              borderRadius: 12,
                              fontFamily: 'Oswald_400Regular',
                              fontSize: 17,
                              height: 48,
                              marginBottom: 10,
                              marginLeft: 20,
                              marginRight: 20,
                              marginTop: [
                                { minWidth: Breakpoints.Laptop, value: 20 },
                                { minWidth: Breakpoints.Mobile, value: 10 },
                              ],
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          title={'Publish Offer'}
                        />
                        <>
                          {!processLoader ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  height: 40,
                                  justifyContent: 'center',
                                  paddingLeft: 30,
                                  paddingRight: 30,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                allowFontScaling={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      fontFamily: 'Lato_700Bold',
                                      fontSize: 16,
                                      marginRight: 20,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Processing Request'}
                              </Text>
                              <CircularProgress
                                animationDuration={900}
                                color={theme.colors.primary}
                                indeterminate={true}
                                isAnimated={true}
                                lineCap={'round'}
                                maximumValue={100}
                                showTrack={true}
                                startPosition={'top'}
                                style={StyleSheet.applyWidth(
                                  { height: 30, width: 30 },
                                  dimensions.width
                                )}
                                thickness={3}
                                trackColor={theme.colors.divider}
                                trackLineCap={'round'}
                              />
                            </View>
                          )}
                        </>
                        {/* Continue 2 */}
                        <Button
                          onPress={() => {
                            try {
                              navigation.navigate('VendorDashboardScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Secondary'],
                              borderRadius: 12,
                              fontFamily: 'Oswald_400Regular',
                              fontSize: 17,
                              height: 48,
                              marginBottom: 10,
                              marginLeft: 20,
                              marginRight: 20,
                              marginTop: [
                                { minWidth: Breakpoints.Laptop, value: 20 },
                                { minWidth: Breakpoints.Mobile, value: 20 },
                              ],
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          title={'Cancel & Return To Dashboard'}
                        />
                      </View>
                    )}
                  </>
                </View>
              </Surface>
            )}
          </>
        </ImageBackground>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(CreateAnOfferScreen);
