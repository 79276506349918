import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SocialBevyApi from '../apis/SocialBevyApi.js';
import UpdateMobileHeader128Block from '../components/UpdateMobileHeader128Block';
import UpdatedMobileMenuAdminSignedInBlock from '../components/UpdatedMobileMenuAdminSignedInBlock';
import VendorActiveStatusBlock from '../components/VendorActiveStatusBlock';
import VendorInfoSurfaceBlock from '../components/VendorInfoSurfaceBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  Icon,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const AdminOfferManagementScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [membership, setMembership] = React.useState(false);
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const socialBevyPublishOfferPATCH = SocialBevyApi.usePublishOfferPATCH();
  const socialBevyUnpublishOfferPATCH = SocialBevyApi.useUnpublishOfferPATCH();

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Custom Color'] },
        dimensions.width
      )}
    >
      <UpdateMobileHeader128Block />
      {/* Desktop Menu Special 2 */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
                flexDirection: {
                  minWidth: Breakpoints.Laptop,
                  value: 'column',
                },
                height: { minWidth: Breakpoints.Laptop, value: 119.6 },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'center',
                },
                paddingBottom: { minWidth: Breakpoints.Laptop, value: 15 },
                paddingTop: { minWidth: Breakpoints.Laptop, value: 15 },
                width: {
                  minWidth: Breakpoints.Laptop,
                  value: dimensions.width,
                },
              },
              dimensions.width
            )}
          >
            {/* Header Container */}
            <>
              {Constants['user_ID'] || Constants['vendor_ID'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '55.9%' },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '27.06%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorSignupFormScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserSignupFormScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Sign Up'}
                    />
                    {/* Button 3 */}
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorLoginScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserLoginScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            backgroundColor: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Secondary'],
                            },
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            marginLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 10,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Login'}
                    />
                  </View>
                </View>
              )}
            </>
            {/* Header Container Signed In */}
            <>
              {!(Constants['user_ID'] || Constants['vendor_ID']) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '69.43%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 2 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('MarketplaceScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Marketplace'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 5 */}
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorDashboardScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          if (Constants['Admin']) {
                            navigation.navigate('AdminDashboardScreen');
                          }
                          if (Constants['Admin']) {
                            return;
                          }
                          navigation.navigate('UserDashboardScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Secondary'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'My Account'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '13.53%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc'] || Constants['Admin']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          navigation.navigate('UserHomeScreen');
                          setGlobalVariableValue({
                            key: 'vendor_ID',
                            value: '',
                          });
                          setGlobalVariableValue({
                            key: 'user_ID',
                            value: '',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Logout'}
                    />
                  </View>
                </View>
              )}
            </>
          </View>
        )}
      </>
      <ScrollView bounces={true} showsVerticalScrollIndicator={false}>
        <>
          {!Constants['menuView'] ? null : (
            <UpdatedMobileMenuAdminSignedInBlock />
          )}
        </>
        {/* Header */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: [
                {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors['Secondary'],
                },
                {
                  minWidth: Breakpoints.Mobile,
                  value: theme.colors['Secondary'],
                },
              ],
              flexDirection: 'row',
              height: 48,
              justifyContent: 'center',
              paddingLeft: [
                { minWidth: Breakpoints.Mobile, value: 16 },
                { minWidth: Breakpoints.Laptop, value: '25%' },
              ],
              paddingRight: [
                { minWidth: Breakpoints.Mobile, value: 16 },
                { minWidth: Breakpoints.Laptop, value: '25%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Back Click */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: 48,
                justifyContent: 'center',
                left: 16,
                position: 'absolute',
                top: 0,
                width: 48,
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Icon
                color={theme.colors['Primary']}
                name={'AntDesign/leftcircle'}
                size={32}
              />
            </Touchable>
          </View>
          {/* Screen Heading */}
          <Text
            style={StyleSheet.applyWidth(
              {
                color: [
                  {
                    minWidth: Breakpoints.Mobile,
                    value: theme.colors['Strong Inverse'],
                  },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors['Strong Inverse'],
                  },
                ],
                fontFamily: 'Inter_600SemiBold',
                fontSize: 18,
              },
              dimensions.width
            )}
          >
            {'Manage Vendor Offers'}
          </Text>
        </View>
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
            },
            dimensions.width
          )}
        >
          <SocialBevyApi.FetchGetVendorGET
            handlers={{
              onData: fetchData => {
                try {
                  setGlobalVariableValue({
                    key: 'membership_plan',
                    value: fetchData?.Membership_plan,
                  });
                } catch (err) {
                  console.error(err);
                }
              },
            }}
            vendors_id={Constants['adminView_ID']}
          >
            {({ loading, error, data, refetchGetVendor }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  <VendorInfoSurfaceBlock
                    date={fetchData?.created_at}
                    logo={fetchData?.Logo?.url}
                    status={fetchData?.Membership_plan}
                    vendorName={fetchData?.business_name}
                  />
                  <ScrollView
                    bounces={true}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                    style={StyleSheet.applyWidth(
                      {
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Actions 2 */}
                    <>
                      {dimensions.width >= Breakpoints.Laptop ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'stretch',
                                },
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                              ],
                              backgroundColor: theme.colors['Secondary'],
                              borderTopLeftRadius: 9,
                              borderTopRightRadius: 9,
                              flexDirection: 'column',
                              justifyContent: 'center',
                              marginTop: 20,
                              paddingLeft: 20,
                              paddingRight: 20,
                            },
                            dimensions.width
                          )}
                        >
                          <SocialBevyApi.FetchGetVendorOffersGET
                            vendor_id={Constants['adminView_ID']}
                          >
                            {({
                              loading,
                              error,
                              data,
                              refetchGetVendorOffers,
                            }) => {
                              const fetchData = data?.json;
                              if (loading) {
                                return <ActivityIndicator />;
                              }

                              if (
                                error ||
                                data?.status < 200 ||
                                data?.status >= 300
                              ) {
                                return <ActivityIndicator />;
                              }

                              return (
                                <>
                                  <>
                                    {fetchData && fetchData[0] ? null : (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { padding: 15 },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          allowFontScaling={true}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              {
                                                color:
                                                  theme.colors[
                                                    'Strong Inverse'
                                                  ],
                                                fontFamily: 'Lato_700Bold',
                                                fontSize: 16,
                                                textAlign: 'center',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'No Offers Created'}
                                        </Text>
                                        <Button
                                          onPress={() => {
                                            try {
                                              navigation.navigate(
                                                'CreateAnOfferScreen',
                                                { CurrentID: fetchData?.id }
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.ButtonStyles(theme)[
                                                'Button'
                                              ],
                                              {
                                                borderRadius: 12,
                                                marginTop: 15,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                          title={'Create New offer'}
                                        />
                                      </View>
                                    )}
                                  </>
                                  <FlatList
                                    data={fetchData}
                                    keyExtractor={listData =>
                                      listData?.id ||
                                      listData?.uuid ||
                                      JSON.stringify(listData)
                                    }
                                    listKey={'vbbf9KaH'}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const listData = item;
                                      return (
                                        <Surface
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.SurfaceStyles(theme)[
                                                'Surface'
                                              ],
                                              {
                                                alignItems: 'center',
                                                borderRadius: 9,
                                                justifyContent: 'center',
                                                marginBottom: 20,
                                                marginTop: 20,
                                                width: '100%',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Offer Name */}
                                          <Text
                                            accessible={true}
                                            allowFontScaling={true}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  fontFamily: 'Lato_700Bold',
                                                  fontSize: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 20,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 20,
                                                    },
                                                  ],
                                                  marginBottom: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 10,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 20,
                                                    },
                                                  ],
                                                  marginTop: 30,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.offer_name}
                                          </Text>
                                          <Image
                                            resizeMode={'cover'}
                                            source={{
                                              uri: `${listData?.Image?.url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  'Image'
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors['Divider'],
                                                  borderRadius: 9,
                                                  borderWidth: 0.5,
                                                  height: 200,
                                                  marginTop: 20,
                                                  width: '65%',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                          {/* Offer Amount */}
                                          <Text
                                            accessible={true}
                                            allowFontScaling={true}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  fontFamily: 'Lato_700Bold',
                                                  fontSize: 18,
                                                  marginBottom: 10,
                                                  marginTop: 20,
                                                  textDecorationLine:
                                                    'line-through',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {'Originial Price $'}
                                            {listData?.Price}
                                            {'.00'}
                                          </Text>

                                          <Surface
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.SurfaceStyles(
                                                  theme
                                                )['Surface'],
                                                {
                                                  alignItems: 'center',
                                                  backgroundColor:
                                                    theme.colors['Primary'],
                                                  width: '100%',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {/* Price */}
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    color:
                                                      theme.colors[
                                                        'Strong Inverse'
                                                      ],
                                                    fontFamily: 'Lato_700Bold',
                                                    fontSize: 14,
                                                    marginBottom: 5,
                                                    marginTop: 5,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'Price'}
                                            </Text>
                                            {/* Price 2 */}
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    color:
                                                      theme.colors[
                                                        'Strong Inverse'
                                                      ],
                                                    fontFamily: 'Lato_900Black',
                                                    fontSize: 26,
                                                    marginBottom: 15,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'$'}
                                              {listData?.Price}
                                              {'.00'}
                                            </Text>
                                          </Surface>

                                          <SocialBevyApi.FetchGetLocationGET
                                            vendor_id={15}
                                          >
                                            {({
                                              loading,
                                              error,
                                              data,
                                              refetchGetLocation,
                                            }) => {
                                              const fetchData = data?.json;
                                              if (loading) {
                                                return <ActivityIndicator />;
                                              }

                                              if (
                                                error ||
                                                data?.status < 200 ||
                                                data?.status >= 300
                                              ) {
                                                return <ActivityIndicator />;
                                              }

                                              return (
                                                <>
                                                  {/* Offer Location */}
                                                  <Text
                                                    accessible={true}
                                                    allowFontScaling={true}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'],
                                                        {
                                                          color:
                                                            theme.colors[
                                                              'Primary'
                                                            ],
                                                          fontFamily:
                                                            'Lato_700Bold',
                                                          fontSize: 20,
                                                          marginBottom: 10,
                                                          marginTop: 10,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {fetchData?.location_name}
                                                  </Text>
                                                </>
                                              );
                                            }}
                                          </SocialBevyApi.FetchGetLocationGET>
                                          {/* Offer Desc */}
                                          <Text
                                            accessible={true}
                                            allowFontScaling={true}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  marginBottom: 5,
                                                  paddingLeft: 30,
                                                  paddingRight: 30,
                                                  textAlign: 'center',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.offer_description}
                                          </Text>

                                          <View
                                            style={StyleSheet.applyWidth(
                                              { marginBottom: 5, marginTop: 5 },
                                              dimensions.width
                                            )}
                                          ></View>
                                          {/* View 2 */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flexDirection: 'row',
                                                justifyContent: 'space-evenly',
                                                marginBottom: 20,
                                                marginTop: 20,
                                                width: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: '80%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: '50%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: '40%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: '30%',
                                                  },
                                                ],
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Button
                                              onPress={() => {
                                                try {
                                                  navigation.navigate(
                                                    'VendorOfferDetailsScreen',
                                                    { offerID: listData?.id }
                                                  );
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              }}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.ButtonStyles(
                                                    theme
                                                  )['Button'],
                                                  {
                                                    backgroundColor:
                                                      theme.colors['Secondary'],
                                                    borderRadius: 9,
                                                    minHeight: 60,
                                                    width: '45%',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                              title={'Manage Details'}
                                            />
                                            {/* Button 2 */}
                                            <>
                                              {listData?.Active ? null : (
                                                <Button
                                                  onPress={() => {
                                                    const handler =
                                                      async () => {
                                                        try {
                                                          if (
                                                            Constants[
                                                              'membership_plan'
                                                            ] !==
                                                            'Soci-Data Free'
                                                          ) {
                                                            (
                                                              await socialBevyPublishOfferPATCH.mutateAsync(
                                                                {
                                                                  offer_id:
                                                                    listData?.id,
                                                                  offers_id:
                                                                    listData?.id,
                                                                  vendor_id:
                                                                    Constants[
                                                                      'vendor_ID'
                                                                    ],
                                                                }
                                                              )
                                                            )?.json;
                                                          }
                                                          if (
                                                            Constants[
                                                              'membership_plan'
                                                            ] ===
                                                            'Soci-Data Free'
                                                          ) {
                                                            navigation.navigate(
                                                              'PaywallScreen'
                                                            );
                                                          }
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      };
                                                    handler();
                                                  }}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.ButtonStyles(
                                                        theme
                                                      )['Button'],
                                                      {
                                                        borderRadius: 9,
                                                        minHeight: 60,
                                                        width: '45%',
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                  title={'Publish Offer'}
                                                />
                                              )}
                                            </>
                                            {/* Button 3 */}
                                            <>
                                              {!listData?.Active ? null : (
                                                <Button
                                                  onPress={() => {
                                                    const handler =
                                                      async () => {
                                                        try {
                                                          (
                                                            await socialBevyUnpublishOfferPATCH.mutateAsync(
                                                              {
                                                                offer_id:
                                                                  listData?.id,
                                                                offers_id:
                                                                  listData?.id,
                                                                vendor_id:
                                                                  Constants[
                                                                    'vendor_ID'
                                                                  ],
                                                              }
                                                            )
                                                          )?.json;
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      };
                                                    handler();
                                                  }}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.ButtonStyles(
                                                        theme
                                                      )['Button'],
                                                      {
                                                        borderRadius: 12,
                                                        width: '45%',
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                  title={'Unpublish Offer'}
                                                />
                                              )}
                                            </>
                                          </View>
                                        </Surface>
                                      );
                                    }}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        width: [
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: '70%',
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '100%',
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                    contentContainerStyle={StyleSheet.applyWidth(
                                      {
                                        alignItems: [
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 'stretch',
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 'stretch',
                                          },
                                        ],
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                      },
                                      dimensions.width
                                    )}
                                  />
                                </>
                              );
                            }}
                          </SocialBevyApi.FetchGetVendorOffersGET>
                        </View>
                      )}
                    </>
                    {/* Actions 3 */}
                    <>
                      {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'stretch',
                                },
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                              ],
                              flexDirection: 'column',
                              justifyContent: 'center',
                              marginTop: 20,
                              paddingLeft: 20,
                              paddingRight: 20,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Fetch 2 */}
                          <SocialBevyApi.FetchGetVendorOffersGET
                            vendor_id={Constants['adminView_ID']}
                          >
                            {({
                              loading,
                              error,
                              data,
                              refetchGetVendorOffers,
                            }) => {
                              const fetch2Data = data?.json;
                              if (loading) {
                                return <ActivityIndicator />;
                              }

                              if (
                                error ||
                                data?.status < 200 ||
                                data?.status >= 300
                              ) {
                                return <ActivityIndicator />;
                              }

                              return (
                                <FlatList
                                  data={fetch2Data}
                                  keyExtractor={listData =>
                                    listData?.id ||
                                    listData?.uuid ||
                                    JSON.stringify(listData)
                                  }
                                  listKey={'BxeM2qcR'}
                                  numColumns={2}
                                  onEndReachedThreshold={0.5}
                                  renderItem={({ item, index }) => {
                                    const listData = item;
                                    return (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 'center',
                                              },
                                              {
                                                minWidth: Breakpoints.Tablet,
                                                value: 'center',
                                              },
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 'center',
                                              },
                                            ],
                                            backgroundColor:
                                              theme.colors['Divider'],
                                            borderColor: {
                                              minWidth: Breakpoints.Laptop,
                                              value: theme.colors['Divider'],
                                            },
                                            borderRadius: 12,
                                            borderWidth: 0.5,
                                            justifyContent: 'center',
                                            margin: {
                                              minWidth: Breakpoints.Laptop,
                                              value: 5,
                                            },
                                            marginBottom: 20,
                                            marginTop: 5,
                                            width: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: '100%',
                                              },
                                              {
                                                minWidth: Breakpoints.Tablet,
                                                value: '100%',
                                              },
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: '49%',
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Offer Name */}
                                        <Text
                                          accessible={true}
                                          allowFontScaling={true}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              {
                                                fontFamily: 'Lato_700Bold',
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 20,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 26,
                                                  },
                                                ],
                                                marginBottom: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 10,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 20,
                                                  },
                                                ],
                                                marginTop: 30,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.offer_name}
                                        </Text>
                                        <Image
                                          resizeMode={'cover'}
                                          source={{
                                            uri: `${listData?.Image?.url}`,
                                          }}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.ImageStyles(theme)[
                                                'Image'
                                              ],
                                              { height: 250, width: '80%' }
                                            ),
                                            dimensions.width
                                          )}
                                        />
                                        {/* Offer Amount */}
                                        <>
                                          {!(
                                            listData?.offer_amount_rate > 0
                                          ) ? null : (
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: 'Lato_700Bold',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Laptop,
                                                        value: 'Lato_900Black',
                                                      },
                                                    ],
                                                    fontSize: 18,
                                                    marginBottom: 10,
                                                    marginTop: 20,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'$'}
                                              {listData?.offer_amount_rate}
                                              {'.00 Discount'}
                                            </Text>
                                          )}
                                        </>
                                        {/* Offer Percentage */}
                                        <>
                                          {!(
                                            listData?.offer_amount_percent > 0
                                          ) ? null : (
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    fontFamily: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: 'Lato_700Bold',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Laptop,
                                                        value: 'Lato_900Black',
                                                      },
                                                    ],
                                                    fontSize: 18,
                                                    marginBottom: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: 10,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Laptop,
                                                        value: 20,
                                                      },
                                                    ],
                                                    marginTop: 20,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.offer_amount_percent}
                                              {'% Discount'}
                                            </Text>
                                          )}
                                        </>
                                        <Surface
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.SurfaceStyles(theme)[
                                                'Surface'
                                              ],
                                              {
                                                alignItems: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'center',
                                                },
                                                backgroundColor: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value:
                                                    theme.colors['Primary'],
                                                },
                                                justifyContent: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'center',
                                                },
                                                width: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: '100%',
                                                },
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Price */}
                                          <Text
                                            accessible={true}
                                            allowFontScaling={true}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  color: {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value:
                                                      theme.colors[
                                                        'Strong Inverse'
                                                      ],
                                                  },
                                                  fontFamily: 'Lato_700Bold',
                                                  fontSize: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 18,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 14,
                                                    },
                                                  ],
                                                  marginBottom: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 10,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 5,
                                                    },
                                                  ],
                                                  marginTop: 20,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {'Price'}
                                          </Text>
                                          {/* Price num */}
                                          <Text
                                            accessible={true}
                                            allowFontScaling={true}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  color: {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value:
                                                      theme.colors[
                                                        'Strong Inverse'
                                                      ],
                                                  },
                                                  fontFamily: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 'Lato_700Bold',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 'Lato_900Black',
                                                    },
                                                  ],
                                                  fontSize: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 18,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 26,
                                                    },
                                                  ],
                                                  marginBottom: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 10,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 20,
                                                    },
                                                  ],
                                                  marginTop: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 20,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 5,
                                                    },
                                                  ],
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {'$'}
                                            {listData?.Price}
                                            {'.00'}
                                          </Text>
                                        </Surface>

                                        <SocialBevyApi.FetchGetLocationGET
                                          vendor_id={Constants['adminView_ID']}
                                        >
                                          {({
                                            loading,
                                            error,
                                            data,
                                            refetchGetLocation,
                                          }) => {
                                            const fetchData = data?.json;
                                            if (loading) {
                                              return <ActivityIndicator />;
                                            }

                                            if (
                                              error ||
                                              data?.status < 200 ||
                                              data?.status >= 300
                                            ) {
                                              return <ActivityIndicator />;
                                            }

                                            return (
                                              <>
                                                {/* Offer Location */}
                                                <Text
                                                  accessible={true}
                                                  allowFontScaling={true}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'],
                                                      {
                                                        color:
                                                          theme.colors[
                                                            'Primary'
                                                          ],
                                                        fontFamily:
                                                          'Lato_700Bold',
                                                        fontSize: 20,
                                                        marginBottom: 10,
                                                        marginTop: 10,
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {fetchData?.location_name}
                                                </Text>
                                              </>
                                            );
                                          }}
                                        </SocialBevyApi.FetchGetLocationGET>
                                        {/* Offer Desc */}
                                        <Text
                                          accessible={true}
                                          allowFontScaling={true}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              {
                                                fontFamily: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'Lato_400Regular',
                                                },
                                                marginBottom: 5,
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                                textAlign: 'center',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.offer_description}
                                        </Text>

                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: {
                                                minWidth: Breakpoints.Laptop,
                                                value: 'center',
                                              },
                                              justifyContent: {
                                                minWidth: Breakpoints.Laptop,
                                                value: 'center',
                                              },
                                              marginBottom: 5,
                                              marginTop: 5,
                                            },
                                            dimensions.width
                                          )}
                                        ></View>
                                        {/* View 2 */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              flexDirection: 'row',
                                              justifyContent: 'space-evenly',
                                              marginBottom: 20,
                                              marginTop: 20,
                                              width: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: '80%',
                                                },
                                                {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: '50%',
                                                },
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: '40%',
                                                },
                                                {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: '30%',
                                                },
                                              ],
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Button
                                            onPress={() => {
                                              try {
                                                navigation.navigate(
                                                  'VendorOfferDetailsScreen'
                                                );
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ButtonStyles(
                                                  theme
                                                )['Button'],
                                                {
                                                  backgroundColor:
                                                    theme.colors['Secondary'],
                                                  borderRadius: 12,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            title={'Manage Details'}
                                          />
                                          {/* Button 2 */}
                                          <>
                                            {listData?.Active ? null : (
                                              <Button
                                                onPress={() => {
                                                  const handler = async () => {
                                                    try {
                                                      if (
                                                        Constants[
                                                          'membership_plan'
                                                        ] !== 'Soci-Data Free'
                                                      ) {
                                                        (
                                                          await socialBevyPublishOfferPATCH.mutateAsync(
                                                            {
                                                              offer_id:
                                                                listData?.id,
                                                              offers_id:
                                                                listData?.id,
                                                              vendor_id:
                                                                Constants[
                                                                  'vendor_ID'
                                                                ],
                                                            }
                                                          )
                                                        )?.json;
                                                      }
                                                      if (
                                                        Constants[
                                                          'membership_plan'
                                                        ] === 'Soci-Data Free'
                                                      ) {
                                                        navigation.navigate(
                                                          'PaywallScreen'
                                                        );
                                                      }
                                                    } catch (err) {
                                                      console.error(err);
                                                    }
                                                  };
                                                  handler();
                                                }}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.ButtonStyles(
                                                      theme
                                                    )['Button'],
                                                    { borderRadius: 12 }
                                                  ),
                                                  dimensions.width
                                                )}
                                                title={'Publish Offer'}
                                              />
                                            )}
                                          </>
                                          {/* Button 3 */}
                                          <>
                                            {!listData?.Active ? null : (
                                              <Button
                                                onPress={() => {
                                                  const handler = async () => {
                                                    try {
                                                      (
                                                        await socialBevyUnpublishOfferPATCH.mutateAsync(
                                                          {
                                                            offer_id:
                                                              listData?.id,
                                                            offers_id:
                                                              listData?.id,
                                                            vendor_id:
                                                              Constants[
                                                                'vendor_ID'
                                                              ],
                                                          }
                                                        )
                                                      )?.json;
                                                    } catch (err) {
                                                      console.error(err);
                                                    }
                                                  };
                                                  handler();
                                                }}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.ButtonStyles(
                                                      theme
                                                    )['Button'],
                                                    { borderRadius: 12 }
                                                  ),
                                                  dimensions.width
                                                )}
                                                title={'Unpublish Offer'}
                                              />
                                            )}
                                          </>
                                        </View>
                                      </View>
                                    );
                                  }}
                                  showsHorizontalScrollIndicator={true}
                                  showsVerticalScrollIndicator={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      width: [
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: '70%',
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: '100%',
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: '80%',
                                        },
                                      ],
                                    },
                                    dimensions.width
                                  )}
                                  contentContainerStyle={StyleSheet.applyWidth(
                                    {
                                      alignItems: [
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: 'stretch',
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 'stretch',
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'stretch',
                                        },
                                      ],
                                    },
                                    dimensions.width
                                  )}
                                />
                              );
                            }}
                          </SocialBevyApi.FetchGetVendorOffersGET>
                        </View>
                      )}
                    </>
                  </ScrollView>
                </>
              );
            }}
          </SocialBevyApi.FetchGetVendorGET>
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

export default withTheme(AdminOfferManagementScreen);
