import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SocialBevyApi from '../apis/SocialBevyApi.js';
import UpdateMobileHeader128Block from '../components/UpdateMobileHeader128Block';
import UpdatedMobileMenuSignedOutVendorBlock from '../components/UpdatedMobileMenuSignedOutVendorBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  DatePicker,
  Icon,
  IconButton,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import * as Linking from 'expo-linking';
import { Image, ImageBackground, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const InfluencerSignupFormScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [Email, setEmail] = React.useState('');
  const [Lname, setLname] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [error_Message, setError_Message] = React.useState('');
  const [fname, setFname] = React.useState('');
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const socialBevyCreateInfluencerPOST =
    SocialBevyApi.useCreateInfluencerPOST();

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
    >
      {/* Desktop Menu Special 2 */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 0.5 },
                flexDirection: {
                  minWidth: Breakpoints.Laptop,
                  value: 'column',
                },
                height: { minWidth: Breakpoints.Laptop, value: 119.6 },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'center',
                },
                paddingBottom: { minWidth: Breakpoints.Laptop, value: 15 },
                paddingTop: { minWidth: Breakpoints.Laptop, value: 15 },
                width: {
                  minWidth: Breakpoints.Laptop,
                  value: dimensions.width,
                },
              },
              dimensions.width
            )}
          >
            {/* Header Container */}
            <>
              {Constants['user_ID'] || Constants['vendor_ID'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '55.9%' },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Secondary'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '27.06%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorSignupFormScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserSignupFormScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Sign Up'}
                    />
                    {/* Button 3 */}
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            if (Constants['vendor_ID']) {
                              navigation.navigate('VendorDashboardScreen');
                            } else {
                              navigation.navigate('VendorLoginScreen');
                            }
                          } else {
                            if (Constants['user_ID']) {
                              navigation.navigate('UserDashboardScreen');
                            } else {
                              navigation.navigate('UserLoginScreen');
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            backgroundColor: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors['Secondary'],
                            },
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            marginLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 10,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '45%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Login'}
                    />
                  </View>
                </View>
              )}
            </>
            {/* Header Container Signed In */}
            <>
              {!(Constants['user_ID'] || Constants['vendor_ID']) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      height: { minWidth: Breakpoints.Laptop, value: 68.48 },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'space-between',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '74%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Logo */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '16.93%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.BevyLogo}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '120%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Menu */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '80%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-evenly',
                        },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '69.43%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          navigation.navigate('UserHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Secondary'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Home'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 2 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('PodcastScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Podcasts'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 4 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('MarketplaceScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Marketplace'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 3 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ContactUsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'contact Us'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* Touchable 5 */}
                    <Touchable
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc']) {
                            navigation.navigate('VendorDashboardScreen');
                          }
                          if (Constants['VendorAcc']) {
                            return;
                          }
                          if (Constants['Admin']) {
                            navigation.navigate('AdminDashboardScreen');
                          }
                          if (Constants['Admin']) {
                            return;
                          }
                          navigation.navigate('UserDashboardScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          borderColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Strong Inverse'],
                          },
                          height: {
                            minWidth: Breakpoints.Laptop,
                            value: '100%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'],
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors['Primary'],
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Oswald_400Regular',
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                                textTransform: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'uppercase',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'My Account'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* Buttons or Icons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '13.53%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          if (Constants['VendorAcc'] || Constants['Admin']) {
                            navigation.navigate('VendorHomeScreen');
                          }
                          navigation.navigate('UserHomeScreen');
                          setGlobalVariableValue({
                            key: 'vendor_ID',
                            value: '',
                          });
                          setGlobalVariableValue({
                            key: 'user_ID',
                            value: '',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 9,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Logout'}
                    />
                  </View>
                </View>
              )}
            </>
          </View>
        )}
      </>
      <UpdateMobileHeader128Block />
      <>
        {!Constants['menuView'] ? null : (
          <UpdatedMobileMenuSignedOutVendorBlock />
        )}
      </>
      <KeyboardAwareScrollView
        contentContainerStyle={StyleSheet.applyWidth(
          {
            backgroundColor: [
              {
                minWidth: Breakpoints.Laptop,
                value: theme.colors['Secondary'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: theme.colors['Secondary'],
              },
            ],
            flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
          },
          dimensions.width
        )}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
      >
        <ImageBackground
          resizeMode={'cover'}
          source={Images.AdobeStock438035062}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
              {
                flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                height: { minWidth: Breakpoints.Laptop, value: '100%' },
                padding: { minWidth: Breakpoints.Laptop, value: '5%' },
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              }
            ),
            dimensions.width
          )}
        >
          {/* Mobile Header 2 */}
          <>
            {dimensions.width >= Breakpoints.Laptop ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Secondary'],
                    flexDirection: 'row',
                    height: 100,
                    justifyContent: 'space-between',
                    marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
                    paddingLeft: 16,
                    paddingRight: 16,
                  },
                  dimensions.width
                )}
              >
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', zIndex: 1 },
                    dimensions.width
                  )}
                >
                  {/* Icon Button 3 */}
                  <IconButton
                    icon={'AntDesign/leftcircle'}
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={38}
                    style={StyleSheet.applyWidth(
                      { marginRight: 5 },
                      dimensions.width
                    )}
                  />
                </View>

                <View>
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Strong Inverse'],
                          fontFamily: 'Lato_700Bold',
                          fontSize: 18,
                          marginRight: 20,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Influencer Signup Form'}
                  </Text>
                </View>
              </View>
            )}
          </>
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <Surface
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'],
                    {
                      borderBottomLeftRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: 12,
                      },
                      borderTopLeftRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: 12,
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'column',
                      },
                      marginTop: { minWidth: Breakpoints.Laptop, value: -50 },
                      padding: { minWidth: Breakpoints.BigScreen, value: 20 },
                      width: { minWidth: Breakpoints.Laptop, value: '50%' },
                    }
                  ),
                  dimensions.width
                )}
              >
                <ImageBackground
                  resizeMode={'cover'}
                  source={Images.AdobeStock626626201}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageBackgroundStyles(theme)[
                        'Image Background'
                      ],
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        padding: { minWidth: Breakpoints.Laptop, value: '5%' },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: 'rgba(0, 0, 0, 0.58)',
                        },
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      },
                      dimensions.width
                    )}
                  >
                    <View>
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Strong Inverse'],
                              },
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'Oswald_400Regular',
                              },
                              fontSize: {
                                minWidth: Breakpoints.Laptop,
                                value: 24,
                              },
                              textAlign: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Social Bee Sign Up Form'}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: {
                                minWidth: Breakpoints.Laptop,
                                value: theme.colors['Strong Inverse'],
                              },
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'Oswald_300Light',
                              },
                              fontSize: {
                                minWidth: Breakpoints.Laptop,
                                value: 24,
                              },
                              textAlign: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Joining The Hive Bee..gins Here!'}
                      </Text>
                    </View>
                  </View>
                </ImageBackground>
              </Surface>
            )}
          </>
          {/* Surface 2 */}
          <Surface
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.SurfaceStyles(theme)['Surface'], {
                borderBottomRightRadius: {
                  minWidth: Breakpoints.Laptop,
                  value: 12,
                },
                borderTopRightRadius: {
                  minWidth: Breakpoints.Laptop,
                  value: 12,
                },
                marginTop: { minWidth: Breakpoints.Laptop, value: -50 },
                padding: { minWidth: Breakpoints.BigScreen, value: 20 },
                width: { minWidth: Breakpoints.Laptop, value: '50%' },
              }),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: '20%' },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: '20%' },
                },
                dimensions.width
              )}
            >
              {/* Image */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', paddingBottom: 20, paddingTop: 20 },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const result = await openImagePickerUtil({
                          mediaTypes: 'All',
                          allowsEditing: false,
                          quality: 0.2,
                        });

                        setGlobalVariableValue({
                          key: 'UserPic',
                          value: result,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                >
                  <View>
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center' },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Primary'],
                              fontFamily: 'Oswald_400Regular',
                              fontSize: 18,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Upload  A Profile Pic'}
                      </Text>
                    </View>

                    <Circle
                      bgColor={theme.colors.light}
                      size={140}
                      style={StyleSheet.applyWidth(
                        { backgroundColor: theme.colors['Divider'] },
                        dimensions.width
                      )}
                    >
                      <Image
                        resizeMode={'cover'}
                        source={Images.BevyBee}
                        style={StyleSheet.applyWidth(
                          { height: 140, width: 140 },
                          dimensions.width
                        )}
                      />
                      <Image
                        resizeMode={'cover'}
                        source={{ uri: `${Constants['UserPic']}` }}
                        style={StyleSheet.applyWidth(
                          {
                            height: 140,
                            position: 'absolute',
                            top: 0,
                            width: 140,
                          },
                          dimensions.width
                        )}
                      />
                    </Circle>
                    <Image
                      resizeMode={'cover'}
                      source={Images.Camera}
                      style={StyleSheet.applyWidth(
                        {
                          bottom: 0,
                          height: 48,
                          position: 'absolute',
                          right: 0,
                          width: 48,
                          zIndex: 2,
                        },
                        dimensions.width
                      )}
                    />
                  </View>
                </Touchable>
              </View>
              {/* Name */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: 48,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  editable={true}
                  onChangeText={newTextInputValue => {
                    try {
                      setGlobalVariableValue({
                        key: 'Name',
                        value: newTextInputValue,
                      });
                      setFname(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'First Name'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 0.5,
                      color: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Secondary'],
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Secondary'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'Lato_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'Lato_400Regular',
                        },
                      ],
                      fontSize: 15,
                      height: 48,
                      paddingBottom: 8,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={fname}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', top: -10 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'First Name'}
                  </Text>
                </View>
              </View>
              {/* Name 3 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: 48,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  editable={true}
                  onChangeText={newTextInputValue => {
                    try {
                      setGlobalVariableValue({
                        key: 'Name',
                        value: newTextInputValue,
                      });
                      setLname(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Last Name'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 0.5,
                      color: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Secondary'],
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Secondary'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'Lato_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'Lato_400Regular',
                        },
                      ],
                      fontSize: 15,
                      height: 48,
                      paddingBottom: 8,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={Lname}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', top: -10 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Last Name'}
                  </Text>
                </View>
              </View>
              {/* Name 4 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: 48,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  editable={true}
                  onChangeText={newTextInputValue => {
                    try {
                      setGlobalVariableValue({
                        key: 'Name',
                        value: newTextInputValue,
                      });
                      setEmail(newTextInputValue?.toLowerCase());
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'ex.abc@gmail.com'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 0.5,
                      color: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Strong'],
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Strong'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'Lato_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'Lato_400Regular',
                        },
                      ],
                      fontSize: 15,
                      height: 48,
                      paddingBottom: 8,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={Email}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', top: -10 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Email Address'}
                  </Text>
                </View>
              </View>
              {/* Name 7 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: 48,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  editable={true}
                  onChangeText={newTextInputValue => {
                    try {
                      setPhoneNumber(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'123.456.7890'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 0.5,
                      color: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Secondary'],
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Secondary'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'Lato_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'Lato_400Regular',
                        },
                      ],
                      fontSize: 15,
                      height: 48,
                      paddingBottom: 8,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={phoneNumber}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', top: -10 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Phone Number'}
                  </Text>
                </View>
              </View>
              {/* Name 8 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: 48,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  editable={true}
                  onChangeText={newTextInputValue => {
                    try {
                      setPassword(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'*********'}
                  placeholderTextColor={theme.colors['Medium']}
                  secureTextEntry={true}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 0.5,
                      color: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Secondary'],
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Secondary'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'Lato_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'Lato_400Regular',
                        },
                      ],
                      fontSize: 15,
                      height: 48,
                      paddingBottom: 8,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={password}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', top: -10 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Password'}
                  </Text>
                </View>
              </View>
              {/* Name 9 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_3'],
                    borderRadius: 9,
                    height: 48,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  editable={true}
                  onChangeText={newTextInputValue => {
                    try {
                      setConfirmPassword(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'*********'}
                  placeholderTextColor={theme.colors['Medium']}
                  secureTextEntry={true}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 0.5,
                      color: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Secondary'],
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Secondary'],
                        },
                      ],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'Lato_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'Lato_400Regular',
                        },
                      ],
                      fontSize: 15,
                      height: 48,
                      paddingBottom: 8,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={confirmPassword}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { position: 'absolute', top: -10 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Primary'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Primary'],
                          },
                        ],
                        fontFamily: 'Lato_400Regular',
                        fontSize: 16,
                        marginLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {'Confirm Password'}
                  </Text>
                </View>
              </View>
              {/* error Text */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors['Error'],
                    },
                    fontFamily: {
                      minWidth: Breakpoints.Desktop,
                      value: 'Lato_700Bold',
                    },
                    marginTop: { minWidth: Breakpoints.Laptop, value: 20 },
                    paddingLeft: { minWidth: Breakpoints.Laptop, value: 20 },
                    paddingRight: { minWidth: Breakpoints.Laptop, value: 20 },
                  }),
                  dimensions.width
                )}
              >
                {error_Message}
              </Text>
              {/* Continue */}
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      const userInfluencerResult = (
                        await socialBevyCreateInfluencerPOST.mutateAsync({
                          Fname: fname,
                          Lname: Lname,
                          email: Email,
                          file: 'https://assets.draftbit.app/apps/iQRu6BZy/assets/jxs1TrdEI8-2xr2PoRxp9',
                          password: confirmPassword,
                          phone_number: phoneNumber,
                        })
                      )?.json;
                      const errMsg = userInfluencerResult?.message;
                      const InfluencerID = userInfluencerResult?.id;
                      const InfluencerFirstname = userInfluencerResult?.Fname;
                      setError_Message(errMsg);
                      if (errMsg) {
                        return;
                      }
                      setGlobalVariableValue({
                        key: 'fName',
                        value: InfluencerFirstname,
                      });
                      setGlobalVariableValue({
                        key: 'influencer_ID',
                        value: InfluencerID,
                      });
                      if (Constants['Admin']) {
                        navigation.navigate('InfluencerDashboardScreen');
                      }
                      if (Constants['Admin']) {
                        return;
                      }
                      navigation.navigate('EmailVerificationUserScreen', {
                        email: Email,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: theme.colors['Primary'],
                      },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors['Primary'],
                      },
                    ],
                    borderRadius: 12,
                    fontFamily: 'Oswald_400Regular',
                    fontSize: 17,
                    height: 48,
                    marginBottom: 10,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: [
                      { minWidth: Breakpoints.Laptop, value: 20 },
                      { minWidth: Breakpoints.Mobile, value: 20 },
                    ],
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Continue'}
              />
            </View>
          </Surface>
        </ImageBackground>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(InfluencerSignupFormScreen);
