import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SocialBevyApi from '../apis/SocialBevyApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Button, Link, withTheme } from '@draftbit/ui';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const UpdatedMobileMenuInfluencerSignedInBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  return (
    <View
      style={StyleSheet.applyWidth(
        { height: '100%', width: '100%' },
        dimensions.width
      )}
    >
      {/* Mobile Menu Special 3 */}
      <BlurView
        intensity={80}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.BlurViewStyles(theme)['Blur View'], {
            height: '100%',
            width: '100%',
            zIndex: 1,
          }),
          dimensions.width
        )}
        tint={'light'}
      >
        <>
          {dimensions.width >= Breakpoints.Laptop ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Surface'],
                  borderBottomLeftRadius: 12,
                  borderBottomRightRadius: 12,
                  height: 500,
                  opacity: 1,
                  paddingTop: 30,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Divider'],
                    flexDirection: 'row',
                    height: 110,
                    justifyContent: 'space-evenly',
                    paddingBottom: [
                      { minWidth: Breakpoints.Tablet, value: 15 },
                      { minWidth: Breakpoints.Mobile, value: 20 },
                    ],
                    paddingLeft: 40,
                    paddingRight: 40,
                    paddingTop: [
                      { minWidth: Breakpoints.Tablet, value: 15 },
                      { minWidth: Breakpoints.Mobile, value: 20 },
                    ],
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { justifyContent: 'center', width: '70%' },
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            fontFamily: 'Lato_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 16 },
                              { minWidth: Breakpoints.Tablet, value: 20 },
                            ],
                            marginBottom: 5,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Welcome '}
                    </Text>

                    <SocialBevyApi.FetchGetInfluencerGET
                      influencer_id={Constants['influencer_ID']}
                    >
                      {({ loading, error, data, refetchGetInfluencer }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            {/* Text 2 */}
                            <Text
                              accessible={true}
                              allowFontScaling={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    fontFamily: 'Lato_400Regular',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 20,
                                      },
                                    ],
                                    marginBottom: 5,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {fetchData?.Fname}
                              {','}
                            </Text>
                          </>
                        );
                      }}
                    </SocialBevyApi.FetchGetInfluencerGET>
                  </View>
                  {/* Special Buttons */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: [
                          { minWidth: Breakpoints.Laptop, value: 'row' },
                          { minWidth: Breakpoints.Mobile, value: 'row' },
                        ],
                        justifyContent: [
                          { minWidth: Breakpoints.Laptop, value: 'flex-end' },
                          { minWidth: Breakpoints.Mobile, value: 'flex-start' },
                        ],
                        marginTop: 5,
                        minHeight: { minWidth: Breakpoints.Laptop, value: 45 },
                        width: {
                          minWidth: Breakpoints.Laptop,
                          value: '27.06%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Button
                      onPress={() => {
                        try {
                          setGlobalVariableValue({
                            key: 'signedIn',
                            value: false,
                          });
                          navigation.navigate('VendorHomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: [
                              { minWidth: Breakpoints.Laptop, value: 9 },
                              { minWidth: Breakpoints.Mobile, value: 9 },
                            ],
                            width: [
                              { minWidth: Breakpoints.Laptop, value: '45%' },
                              { minWidth: Breakpoints.Mobile, value: '45%' },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Sign Out'}
                    />
                  </View>
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { justifyContent: 'center' },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'contain'}
                    source={Images.BevyBee}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        { borderRadius: 100 }
                      ),
                      dimensions.width
                    )}
                  />
                </View>
              </View>
              {/* Link Buttons 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: [
                      { minWidth: Breakpoints.BigScreen, value: 'center' },
                      { minWidth: Breakpoints.Laptop, value: 'center' },
                    ],
                    flexDirection: [
                      { minWidth: Breakpoints.BigScreen, value: 'row' },
                      { minWidth: Breakpoints.Laptop, value: 'row' },
                    ],
                    height: [
                      { minWidth: Breakpoints.BigScreen, value: 100 },
                      { minWidth: Breakpoints.Mobile, value: 300 },
                    ],
                    justifyContent: [
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'space-evenly',
                      },
                      { minWidth: Breakpoints.Laptop, value: 'space-evenly' },
                      { minWidth: Breakpoints.Mobile, value: 'space-evenly' },
                    ],
                    marginTop: 30,
                    paddingLeft: 40,
                    paddingRight: 40,
                    width: [
                      { minWidth: Breakpoints.BigScreen, value: '70%' },
                      { minWidth: Breakpoints.Laptop, value: '70%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.BigScreen, value: 'center' },
                        { minWidth: Breakpoints.Laptop, value: 'center' },
                      ],
                      borderBottomWidth: 0.5,
                      borderColor: [
                        {
                          minWidth: Breakpoints.BigScreen,
                          value: theme.colors['Primary'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['Light'],
                        },
                      ],
                      height: [
                        { minWidth: Breakpoints.BigScreen, value: '100%' },
                        { minWidth: Breakpoints.Laptop, value: 80 },
                        { minWidth: Breakpoints.Mobile, value: 35 },
                      ],
                      justifyContent: [
                        { minWidth: Breakpoints.BigScreen, value: 'center' },
                        { minWidth: Breakpoints.Laptop, value: 'center' },
                      ],
                      width: [
                        { minWidth: Breakpoints.BigScreen, value: 100 },
                        { minWidth: Breakpoints.Laptop, value: 100 },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* Link 2 */}
                  <Link
                    accessible={true}
                    allowFontScaling={true}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'menuView',
                          value: false,
                        });
                        navigation.navigate('InfluencerDashboardScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.LinkStyles(theme)['Link'],
                        {
                          color: {
                            minWidth: Breakpoints.BigScreen,
                            value: theme.colors['Primary'],
                          },
                          fontFamily: [
                            {
                              minWidth: Breakpoints.BigScreen,
                              value: 'Oswald_400Regular',
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: 'Oswald_400Regular',
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: 'Lato_400Regular',
                            },
                          ],
                          fontSize: [
                            { minWidth: Breakpoints.BigScreen, value: 16 },
                            { minWidth: Breakpoints.Mobile, value: 16 },
                          ],
                          textTransform: 'uppercase',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'My Dashboard'}
                  />
                </View>
                {/* View 3 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.BigScreen, value: 'center' },
                        { minWidth: Breakpoints.Laptop, value: 'center' },
                      ],
                      borderBottomWidth: 0.5,
                      borderColor: theme.colors['Light'],
                      height: [
                        { minWidth: Breakpoints.BigScreen, value: '100%' },
                        { minWidth: Breakpoints.Laptop, value: 80 },
                        { minWidth: Breakpoints.Mobile, value: 35 },
                      ],
                      justifyContent: [
                        { minWidth: Breakpoints.BigScreen, value: 'center' },
                        { minWidth: Breakpoints.Laptop, value: 'center' },
                      ],
                      width: [
                        { minWidth: Breakpoints.BigScreen, value: 100 },
                        { minWidth: Breakpoints.Laptop, value: 100 },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* Link 3 */}
                  <Link
                    accessible={true}
                    allowFontScaling={true}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'menuView',
                          value: false,
                        });
                        navigation.navigate('PodcastScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.LinkStyles(theme)['Link'],
                        {
                          color: {
                            minWidth: Breakpoints.BigScreen,
                            value: theme.colors['Primary'],
                          },
                          fontFamily: [
                            {
                              minWidth: Breakpoints.BigScreen,
                              value: 'Oswald_400Regular',
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: 'Oswald_400Regular',
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: 'Lato_400Regular',
                            },
                          ],
                          fontSize: [
                            { minWidth: Breakpoints.BigScreen, value: 16 },
                            { minWidth: Breakpoints.Mobile, value: 16 },
                          ],
                          textTransform: 'uppercase',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'podcast'}
                  />
                </View>
                {/* View 5 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.BigScreen, value: 'center' },
                        { minWidth: Breakpoints.Laptop, value: 'center' },
                      ],
                      borderBottomWidth: 0.5,
                      borderColor: theme.colors['Light'],
                      height: [
                        { minWidth: Breakpoints.BigScreen, value: '100%' },
                        { minWidth: Breakpoints.Laptop, value: 80 },
                        { minWidth: Breakpoints.Mobile, value: 35 },
                      ],
                      justifyContent: [
                        { minWidth: Breakpoints.BigScreen, value: 'center' },
                        { minWidth: Breakpoints.Laptop, value: 'center' },
                      ],
                      width: [
                        { minWidth: Breakpoints.BigScreen, value: 100 },
                        { minWidth: Breakpoints.Laptop, value: 100 },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* Link 4 */}
                  <Link
                    accessible={true}
                    allowFontScaling={true}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'menuView',
                          value: false,
                        });
                        navigation.navigate('ContactUsScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.LinkStyles(theme)['Link'],
                        {
                          color: {
                            minWidth: Breakpoints.BigScreen,
                            value: theme.colors['Primary'],
                          },
                          fontFamily: [
                            {
                              minWidth: Breakpoints.BigScreen,
                              value: 'Oswald_400Regular',
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: 'Oswald_400Regular',
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: 'Lato_400Regular',
                            },
                          ],
                          fontSize: [
                            { minWidth: Breakpoints.BigScreen, value: 16 },
                            { minWidth: Breakpoints.Mobile, value: 16 },
                          ],
                          textTransform: 'uppercase',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'contact us'}
                  />
                </View>
              </View>
            </View>
          )}
        </>
      </BlurView>
    </View>
  );
};

export default withTheme(UpdatedMobileMenuInfluencerSignedInBlock);
