import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const $MonthAdvPOST = (
  Constants,
  { cancel_url, price, quantity, success_url },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:UQuTJ3vx/sessions/5Month_Advanced_Package`,
    {
      body: JSON.stringify({
        success_url: success_url,
        cancel_url: cancel_url,
        line_items: [{ price: price, quantity: quantity }],
      }),
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const use5MonthAdvPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => $MonthAdvPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('plan', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('plan');
        queryClient.invalidateQueries('plans');
      },
    }
  );
};

export const Fetch5MonthAdvPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  cancel_url,
  price,
  quantity,
  success_url,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = use5MonthAdvPOST(
    { cancel_url, price, quantity, success_url },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetch5MonthAdv: refetch });
};

export const advPackaageMonthlyPOST = (
  Constants,
  { cancel_url, price, qty, success_url },
  handlers = {}
) =>
  fetch(
    `https://xwf7-6kvd-iy3s.n7.xano.io/api:UQuTJ3vx/sessions/Monthly_Advanced_Package`,
    {
      body: JSON.stringify({
        success_url: success_url,
        cancel_url: cancel_url,
        line_items: [{ price: price, quantity: qty }],
      }),
      headers: {
        Accept: 'application/json',
        Authorization: 'null',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useAdvPackaageMonthlyPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      advPackaageMonthlyPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('plan', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('plan');
        queryClient.invalidateQueries('plans');
      },
    }
  );
};

export const FetchAdvPackaageMonthlyPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  cancel_url,
  price,
  qty,
  success_url,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAdvPackaageMonthlyPOST(
    { cancel_url, price, qty, success_url },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAdvPackaageMonthly: refetch });
};
